import {
    Button,
    Input
} from "@fluentui/react-components";
import { ClearSVG, SearchSVG } from "../static/svgs";
import { useState } from "react";

function CustomSearchInput(props) {
    const [isFocused, setIsFocused] = useState(false); // State to track focus state
    return (
        <Input
            style={{
                width: '416px',
                padding: '0px 16px',
                gap: '10px',
                borderRadius: '12px',
                border: isFocused ? '1.5px solid #202020' : '1px solid #C1C1C1',
            }}
            onFocus={() => setIsFocused(true)} // Set focus state to true when input is focused
            onBlur={() => setIsFocused(false)} // Set focus state to false when input loses focus
            value={props.value}
            onChange={props.onChange}
            type="text"
            contentBefore={<SearchSVG />}
            placeholder="Search"
            contentAfter={
                props.value && (
                    <Button
                        style={{ border: '0' }}
                        icon={<ClearSVG />}
                        title="Clear"
                        onClick={props.onClick}
                        transparent
                    />
                )
            }
        />
    )
}

export default CustomSearchInput;
export function mergeDateTime(startDate, startTime) {
    let startDateObj;

    // Check if startDate is already a Date object
    if (startDate instanceof Date) {
        startDateObj = startDate;
    } else {
        // Parse startDate string
        startDateObj = new Date(startDate);
    }

    // Parse startTime string
    const timeParts = startTime.split(':');

    // Set the time components to startDate
    startDateObj.setHours(parseInt(timeParts[0]), parseInt(timeParts[1]), 0, 0);

    return startDateObj; // Convert to ISO string
}

export function mergeDateTime2(startDate, startTime) {
    // Parse startDate string
    const dateParts = startDate.toString().split(' ');
    const monthName = dateParts[1];
    const day = dateParts[2];
    const year = dateParts[3];
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames.indexOf(monthName);

    // Parse startTime string
    const timeParts = startTime.split(':');
    const hours = parseInt(timeParts[0]);
    const minutes = parseInt(timeParts[1]);

    // Construct the date object with UTC time
    const startDateObj = new Date(Date.UTC(year, month, day, hours, minutes)).toISOString();

    return startDateObj; // Return the merged Date object
}



export function getTimeFromDate(dateString) {
    const dateObj = new Date(dateString);
    const hours = dateObj.getHours().toString().padStart(2, '0'); // Ensure 2 digits
    const minutes = dateObj.getMinutes().toString().padStart(2, '0'); // Ensure 2 digits
    return `${hours}:${minutes}`;
}
export function getTimeFromDate2(dateString) {
    // Extract hours and minutes from the provided date string
    const hours = dateString.substring(11, 13);
    const minutes = dateString.substring(14, 16);
    return `${hours}:${minutes}`;
}

export function convertToMST1(userDateTimeString) {
    // Create a Date object using the user-provided date and time string
    var date = new Date(userDateTimeString);

    // Extract the timezone offset from the provided date string
    var offsetMinutes = date.getTimezoneOffset();

    // Convert the offset to milliseconds
    var offsetMilliseconds = offsetMinutes * 60 * 1000;

    // Adjust the time to MST (UTC-07:00)
    date.setTime(date.getTime() + offsetMilliseconds - (7 * 60 * 60 * 1000));

    // Format the date and time components
    var mstDate = date.toISOString().split('T')[0];

    // Extract MST hours and minutes
    var mstHours = date.getHours();
    var mstMinutes = date.getMinutes();

    // Determine AM/PM
    var ampm = mstHours >= 12 ? 'PM' : 'AM';

    // Convert MST hours to 12-hour format
    mstHours = mstHours % 12;
    mstHours = mstHours ? mstHours : 12; // Handle midnight (0 hours)

    // Construct the MST time string with AM/PM
    var mstTimeString = mstHours + ':' + ('0' + mstMinutes).slice(-2) + ' ' + ampm;

    // Construct the MST date and time string
    var mstDateTimeString = mstDate + ' ' + mstTimeString;

    return mstDateTimeString;
}

export const BookingCategory = {
    Vehicle_Booking: "Vehicle_Booking",
    Addition_Amount: "Addition_Amount",
    Paid_after_Rental: "Paid_after_Rental",
    Product_Purchase: "Product_Purchase",
    Paid_Event_RSVP: "Paid_Event_RSVP",
    POS_FoodAndB: "Food_and_Beverage"
  };

  export const BOOKINGSTATUS = {
    Attending: "Attending",
    Cancel: "Cancel",
    Waiting: "Waiting",
}

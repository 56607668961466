import React, { useEffect, useState } from 'react';
import { Divider, Image, Text } from '@fluentui/react-components';
import './ProductList.css'; // Import the CSS file

const placeholderImageUrl = 'https://placehold.co/800?text=No+Image&font=roboto';

const ProductList = ({ data, onProductClick, isReset, selectedProductIds, toggleProductSelection, adjustedProducts = [] }) => {

  const handleProductClick = (product) => {
    toggleProductSelection(product.id);
    onProductClick(product);
  };

  return (
    <div className="product-list">
      {data.map((item) => {
        // Check if the item's product ID matches any item in adjustedProducts
        const adjustedProduct = adjustedProducts.find(prod => prod.id === item.id);
        const quantity = adjustedProduct ? adjustedProduct.quantity : 0; // Extract the quantity or default to 0
        return (
          <div className={`parent-container ${selectedProductIds.includes(item.id) ? 'selected' : ''}`} style={selectedProductIds.includes(item.id) ? { border: "1.5px solid #FFBA97", borderRadius: "12px", background: "#F9F9F9" } : {}} onClick={() => handleProductClick(item)}>
            <div
              className={`pointer ${selectedProductIds.includes(item.id) ? 'selected' : ''}`}
              style={{
                width: "142px",
                borderRadius: "12px 12px 0px 0px",
                height: "124px",
                overflow: "hidden",
                display: 'flex',
                justifyContent: 'center',
                border: '1px solid #D7D7D7',
                borderBottom: 'none'
              }}
              key={item.id}
            >
              <Image style={{ height: "100%", objectFit: "contain" }} src={item.images.length > 0 ? item.images[0] : placeholderImageUrl} alt={item.productName} width={80} height={100} />
            </div>
            {/*<Divider/>*/}
            <div className={"product__price-container product-item"} style={{ height: "89px" }}>
              <Text className="product_label" style={{ width: 120, paddingBottom: "20px" }} block>{item.productName}</Text>
              <div>
                <Text block className={"product_price"}>{`$${item.price / 100}`}</Text>
                {
                  quantity > 0 && <Text className={"product_qty"}>+{quantity}</Text>
                }
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductList;

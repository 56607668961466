import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface, DialogTitle,
    DialogTrigger,
    Label,
    Table, TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    TableCellActions,
} from "@fluentui/react-components";
import { DeleteFilled, DeleteRegular, EditFilled, EditRegular, bundleIcon } from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import { CloseSVG, UserFormAddSVG } from "../static/svgs";
const EditIcon = bundleIcon(EditFilled, EditRegular);

const columns = [
    { columnKey: "firstName", label: "First Name" },
    { columnKey: "lastName", label: "Last Name" },
    { columnKey: "isAttending", label: "Is Attending" },
    { columnKey: "noOfGuest", label: "Guests" },
    { columnKey: "total", label: "Total" },
];
const waitcolumns = [
    { columnKey: "firstName", label: "First Name" },
    { columnKey: "lastName", label: "Last Name" },
];

function RSVPDialog(props) {

    const [totalAttending, setTotalAttending] = useState(0);
    const [maxAttending, setMaxAttending] = useState(0);
    const [rsvpsArr, setRsvpsArr] = useState([]);

    useEffect(() => {
        if (props.selectedEvent) {

            let attendingArr = props.selectedEvent?.rsvps.filter(rsvp => rsvp.isAttending == true);
            if (attendingArr && attendingArr.length > 0) {
                let updatedArr = attendingArr.map(obj => {
                    return {
                        ...obj,
                        subtotal: obj.noOfGuest + 1
                    };
                });
                const totalNoOfGuests = attendingArr.reduce((total, item) => total + item.noOfGuest, 0);
                const totalNoOfMember = attendingArr.length;
                let grandTotal = 0
                if (totalNoOfGuests && totalNoOfGuests != NaN) {
                    setTotalAttending(totalNoOfGuests + totalNoOfMember)
                    grandTotal = totalNoOfGuests + totalNoOfMember
                } else {
                    setTotalAttending(totalNoOfMember)
                    grandTotal = totalNoOfMember
                }
                updatedArr.push({ isGrandTotal: true, firstName: "TOTAL:", isAttending: "", subtotal: grandTotal });
                setRsvpsArr(updatedArr)
                setMaxAttending(props.selectedEvent?.maximumAttendees)
            } else {
                setRsvpsArr([])
                setTotalAttending(0)
            }
        } else {
            setRsvpsArr([])
            setTotalAttending(0)
        }
    }, [props.selectedEvent]);

    function onEditClick(rsvps) {
        props.onEditRsvpClick(rsvps)
    }
    return (
        <Dialog
            open={props.open}
            onOpenChange={(event, data) => {
                props.setOpen(data.open);
            }}
        >
            <DialogSurface>
                <DialogBody>
                    <DialogTitle className={"d-flex justify-space-between"}>
                        <div className={'pointer'} onClick={() => { onEditClick(null) }}>RSVPS({totalAttending}) <UserFormAddSVG /></div>
                        <div>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <div className={"pointer"}><CloseSVG /></div>
                                </DialogTrigger>
                            </DialogActions>
                        </div>
                    </DialogTitle>

                    <DialogContent>
                        {props.selectedEvent && props.selectedEvent.rsvps && <div className={"table-layout"} style={{ marginTop: 24 }}><Table
                            aria-label="Table with cell actions">
                            <TableHeader className={"list-row"}>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableHeaderCell key={column.columnKey}>
                                            <span className={"rsvps-header-typo"}>{column.label}</span>
                                        </TableHeaderCell>
                                    ))}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {rsvpsArr.map((rsvps, index) =>
                                    <TableRow key={`rsvp${index}`}>
                                        {
                                            rsvps.isGrandTotal ?
                                                <TableCell className={"rsvps-header-typo "}
                                                >{rsvps.firstName}</TableCell> :
                                                <TableCell
                                                    className={"rsvps-cell-typo "}>{rsvps.firstName}</TableCell>
                                        }
                                        <TableCell className={"rsvps-cell-typo "}>{rsvps.lastName}</TableCell>
                                        <TableCell
                                            className={"rsvps-cell-typo "}><div className={rsvps.isAttending == "" ? "" : rsvps.isAttending ? "freeBox" : "priceBox"}>{rsvps.isAttending == "" ? "" : rsvps.isAttending ? "Yes" : "No"}</div></TableCell>
                                        <TableCell className={"rsvps-cell-typo "}>
                                            {rsvps.noOfGuest}

                                        </TableCell>
                                        {
                                            rsvps.isGrandTotal ?
                                                <TableCell className={"rsvps-header-typo "}
                                                >{rsvps.subtotal}</TableCell> :
                                                <TableCell
                                                    className={"rsvps-cell-typo "}>
                                                    {rsvps.subtotal}
                                                    <TableCellActions>
                                                        <Button
                                                            icon={<EditIcon />}
                                                            appearance="subtle"
                                                            aria-label="Edit"
                                                            onClick={() => onEditClick(rsvps)}
                                                        />
                                                    </TableCellActions>
                                                </TableCell>
                                        }
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table></div>
                        }
                        {/*    {props.selectedEvent && props.selectedEvent.rsvpWaitlist ?*/}
                        {/*    <>*/}
                        {/*        <div style={{ marginTop: 30 }}></div>*/}
                        {/*        <Label>*/}
                        {/*            Waitlisted*/}
                        {/*        </Label>*/}
                        {/*        <Table aria-label="Table with cell actions">*/}
                        {/*            <TableHeader>*/}
                        {/*                <TableRow>*/}
                        {/*                    {waitcolumns.map((column) => (*/}
                        {/*                        <TableHeaderCell key={column.columnKey}>*/}
                        {/*                            {column.label}*/}
                        {/*                        </TableHeaderCell>*/}
                        {/*                    ))}*/}
                        {/*                </TableRow>*/}
                        {/*            </TableHeader>*/}
                        {/*            <TableBody>*/}
                        {/*                {props.selectedEvent.rsvpWaitlist.map((rsvps, index) =>*/}
                        {/*                    <TableRow key={`waitrsvp${index}`}>*/}
                        {/*                        <TableCell>{rsvps.firstName}</TableCell>*/}
                        {/*                        <TableCell>{rsvps.lastName}</TableCell>*/}
                        {/*                        /!* <TableCell>{rsvps.isAttending ? "Yes" : "No"}</TableCell> *!/*/}
                        {/*                        <TableCell>{rsvps.noOfGuest}</TableCell>*/}
                        {/*                    </TableRow>*/}
                        {/*                )}*/}
                        {/*            </TableBody>*/}
                        {/*        </Table>*/}
                        {/*    </> :*/}
                        {/*    <div></div>*/}
                        {/*}*/}
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog >
    )
}

export default RSVPDialog;
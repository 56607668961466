import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, getDocs, setDoc, query, where } from "firebase/firestore";
import { db } from "./firebase";

const serviceProviderCollection = collection(db, "ServiceProvider");

class ServiceProviderDataService {
    // addServiceProvider = (newServiceProvider) => {
    //     return addDoc(serviceProviderCollection, newServiceProvider);
    // }

    addServiceProvider = (newServiceProvider, uuid) => {
        return setDoc(doc(db, "ServiceProvider", uuid), newServiceProvider);
    }

    updateServiceProvider = (id, updatedServiceProvider) => {
        const serviceProviderDoc = doc(db, "ServiceProvider", id);
        return updateDoc(serviceProviderDoc, updatedServiceProvider);
    }

    deleteServiceProvider = (id) => {
        const serviceProviderDoc = doc(db, "ServiceProvider", id);
        return deleteDoc(serviceProviderDoc);
    }

    getAllServiceProvider = () => {
        return getDocs(serviceProviderCollection)
    }

    getServiceProviderByEmail = (email) => {
        const q = query(serviceProviderCollection, where("email", "==", email));
        return getDocs(q);
    }

    getServiceProvider = (id) => {
        const serviceProviderDoc = doc(db, "ServiceProvider", id);
        return getDoc(serviceProviderDoc);
    }
}

export default new ServiceProviderDataService();
import { configureStore } from "@reduxjs/toolkit";
import { authReducer, layoutReducer, memberReducer } from "./slices";

const store = configureStore({
  reducer: {
    auth: authReducer,
    layout: layoutReducer,
    member: memberReducer
  },
});

export default store;

import React, { useEffect, useState } from "react";
import Layout from "./components/Layout";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, trackException } from "./services/firebase";
import { useNavigate } from "react-router-dom";
import {
    makeStyles, shorthands, Persona, Button, Table, TableBody,
    TableHeader, TableRow, TableHeaderCell, TableCell,
    TableCellLayout, Image, TableCellActions, Label, Spinner
} from "@fluentui/react-components";
import { query, collection, getDocs, where } from "firebase/firestore";
import { EditFilled, EditRegular, bundleIcon, DeleteFilled, DeleteRegular } from "@fluentui/react-icons";
import BookingDataService from "./services/bookingDataService";
import VehicleDataService from "./services/vehicleDataService";
import UserDataService from "./services/userDataService";
import ServiceProviderDataService from "./services/ServiceProviderDataService";
import VehicleServiceTypeDataService from "./services/VehicleServiceTypeDataService";
import moment from 'moment';
//import ImageDialog from "./components/ImageDialog";
import DeleteEventDialog from "./components/DeleteEventDialog";
import ServiceForm from "./components/Forms/ServiceForm";
import CustomSearchInput from "./components/CustomSearchInput";
import debounce from 'lodash/debounce';

const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "type", label: "Service type" },
    { columnKey: "serviceDate", label: "Service Date" },
    { columnKey: "vehicle", label: "Vehicle " },
    { columnKey: "createdBy", label: "Created By" }
];

function Services() {
    const styles = useStyles();
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedService, setSelectedService] = useState();
    const [allservices, setAllServices] = useState([]);
    const [allusers, setAllUsers] = useState([]);
    const [allvehicles, setAllVehicles] = useState([]);
    const [allvehicleServiceTypes, setAllVehicleServiceType] = useState([]);
    const [allserviceProviders, setAllServiceProvider] = useState([]);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredService, setFilteredService] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50); // Number of events per page
    const [startAfterDoc, setStartAfterDoc] = useState(null); // Track the last document of the previous page
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);

    useEffect(() => {
        if (page > 1) {
            loadMorePosts();
        }
    }, [page]);

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        setIsLoading(true);
        fetchUserNameAndServices();
    }, [user, loading]);

    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedService(selectedItem);
    }

    const openServiceForm = (selectedItem) => {
        let url = `${process.env.REACT_APP_WEB_APP}/owner/service/${selectedItem.id}?token=${user.accessToken}`;
        window.open(url, '_blank', 'noreferrer');
    }

    const fetchUserNameAndServices = async () => {
        setPage(1)
        setStartAfterDoc(null)
        setHasMoreData(true)
        try {
            if (!!user) {
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                if (userdoc.docs.length > 0) {
                    const vehiclesQuery = await VehicleDataService.getAllVehicle();
                    setAllVehicles(vehiclesQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

                    const usersQuery = await UserDataService.getAllUser();
                    setAllUsers(usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

                    //const servicesQuery = await BookingDataService.getAllVehicleService();
                    //setAllServices(servicesQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
                    //const servicesQuery = await BookingDataService.getAllVehicleService2();
                    //let allService = servicesQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    const { events, lastVisible } = await BookingDataService.getAllVehicleServiceWithPage(pageSize, null);
                    setStartAfterDoc(lastVisible);
                    let allService = events;
                    setAllServices(allService);
                    setFilteredService(allService);
                    if (allService.length < pageSize) {
                        setHasMoreData(false)
                    }

                    const serviceProvidersQuery = await ServiceProviderDataService.getAllServiceProvider();
                    setAllServiceProvider(serviceProvidersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

                    const vehicleServiceTypesQuery = await VehicleServiceTypeDataService.getAllVehicleServiceType();
                    setAllVehicleServiceType(vehicleServiceTypesQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

                    //console.log(allevents);
                } else {
                    console.log('no access');
                }
                setIsLoading(false);
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            console.error(err);
            setIsLoading(false);
            alert("An error occured while fetching user data");
        }
    };
    const loadMorePosts = async () => {
        const { events, lastVisible } = await BookingDataService.getAllVehicleServiceWithPage(pageSize, startAfterDoc);
        // Update the startAfterDoc for the next page
        setStartAfterDoc(lastVisible);
        if (events.length < pageSize) {
            setHasMoreData(false)
            if (events.length > 0) {
                setAllServices(prevEvents => [...prevEvents, ...events]);
                setFilteredService(prevEvents => [...prevEvents, ...events]);
            }
            // You can handle this case, e.g., display a message
        } else {
            // Append the new events to the existing events
            setAllServices(prevEvents => [...prevEvents, ...events]);
            setFilteredService(prevEvents => [...prevEvents, ...events]);
        }
    };


    const DeleteService = async () => {
        BookingDataService.deleteService(selectedService.id);
        setIsDeleteDialogOpen(false);
        fetchUserNameAndServices();
    };

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allservices.filter(event =>
            event.vehicleDetails.vehicleName.toLowerCase().includes(lowerSearch)
        );
        setFilteredService(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };
    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };
    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    {/* <Button shape="circular" appearance="primary" onClick={() => openForm(undefined)}>New Service</Button> */}
                    <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    />
                </div>
                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredService.length > 0 && filteredService.map((item) => {
                                let serType = item.requestedServices ? item.requestedServices[0].requestType : ''
                                let ownerName = item.ownerDetails ? item.ownerDetails.ownerName : ''
                                let vehicleDetails = item.vehicleDetails
                                return (
                                    <TableRow key={item.id}>
                                        <TableCell className={"table-row-fonts__primary"}>
                                            <TableCellLayout>
                                                {/* {item.serviceObj.type} */}
                                                {serType}
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell className={"table-row-fonts__desc"}>
                                            <TableCellLayout>
                                                {/* <Label>{item.serviceDate && moment(item.serviceDate.seconds * 1000).format('Do MMMM YYYY hh mm A')}</Label> */}
                                                <Label>{item.createdTimestamp && moment(item.createdTimestamp.seconds * 1000).format('Do MMMM YYYY hh mm A')}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell className={"table-row-fonts__desc"}>{vehicleDetails.vehicleName}
                                        </TableCell>
                                        <TableCell className={"table-row-fonts__desc"}>
                                            <TableCellLayout>
                                                <Label>{ownerName}</Label>
                                            </TableCellLayout>
                                            <TableCellActions>
                                                <Button
                                                    icon={<EditIcon />}
                                                    appearance="subtle"
                                                    aria-label="Edit"
                                                    onClick={() => openServiceForm(item)}
                                                />
                                                {/* <Button
                                                    icon={<DeleteIcon />}
                                                    appearance="subtle"
                                                    aria-label="Delte"
                                                    onClick={() => openDeleteForm(item)}
                                                /> */}
                                            </TableCellActions>
                                        </TableCell>
                                    </TableRow>
                                )
                            }

                            )}
                        </TableBody>
                    </Table>
                </div>
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    {isLoading && <Spinner />} {/* Render loader if loading state is true */}
                    {!isLoading && hasMoreData && searchTerm == "" && <Button onClick={handleLoadMore}>Load More...</Button>} {/* Render button if not loading */}
                </div>
            </div>
            <ServiceForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)} selectedService={selectedService}
                updateGrid={fetchUserNameAndServices} vehicles={allvehicles}
                serviceType={allvehicleServiceTypes} serviceProvider={allserviceProviders} ></ServiceForm>
            {/* <ImageDialog open={open} setOpen={setOpen} selectedEvent={selectedService}></ImageDialog> */}
            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeleteService} text={'Service'} />
        </Layout>
    )
}

export default Services;
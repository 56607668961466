import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { Dismiss24Regular } from "@fluentui/react-icons";
import {
    Button, Image, Input, Label, makeStyles, shorthands, Textarea, Switch, Divider
} from "@fluentui/react-components";
import { DatePicker } from "@fluentui/react-datepicker-compat";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, Controller } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { storage } from "../../services/firebase";
import { RocketRegular, CalendarCancelFilled } from "@fluentui/react-icons";
import {
    ref,
    uploadBytesResumable,
    getDownloadURL
} from "firebase/storage";
import EventDataService from "../../services/eventDataService";
import moment from "moment";
import { bundleIcon, DeleteFilled, DeleteRegular } from "@fluentui/react-icons";
import heic2any from 'heic2any';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./PostForm.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { AtomicBlockUtils, EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import { AddressSVG, DateSVG } from "../../static/svgs";
import { mergeDateTime, convertToMST1, getTimeFromDate } from "../../Helper";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialEventState = {
    "startDate": "",
    "rsvps": [],
    "name": "",
    "previewText": "",
    "id": "",
    "images": [],
    "endDate": "",
    "price": 0,
    "content": "",
    "address": {
        "street1": "",
        "state": "",
        "country": "",
        "city": "",
        "street2": "",
        "zip": ""
    },
    "minimumAttendees": 0,
    "rsvpUids": [],
    "maximumAttendees": 0,
    "isSignature": false,
    "mainImage": "",
    "rsvps": [],
    "rsvpWaitlist": [],
    "rsvpUids": []
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

function EventForm(props) {
    const styles = useStyles();
    const [mainImage, setMainImage] = useState(props.selectedEvent && typeof (props.selectedEvent) == "object" ? props.selectedEvent.mainImage : "");
    const [images, setImages] = useState(props.selectedEvent && typeof (props.selectedEvent) == "object" ? props.selectedEvent.images : []);
    const [fileMainImage, setFileMainImage] = useState("");
    const [uploadedImages, setUploadedImages] = useState([])
    const [percent, setPercent] = useState(0);
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedEvent && typeof (props.selectedEvent) == "object" ? props.selectedEvent : InitialEventState
    });
    const [outputEditorState, setEditorState] = useState(EditorState.createEmpty());

    const handleEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
    };

    const onSubmit = async formdata => {
        var data = formdata;
        var id = "";
        const mergedstartDateTime = mergeDateTime(data.startDate, data.startTime);
        const mergedendDateTime = mergeDateTime(data.endDate, data.endTime);
        data.startDate = moment(mergedstartDateTime).toISOString();
        data.endDate = moment(mergedendDateTime).toISOString();
        data.rsvps = formdata.rsvps ? formdata.rsvps : []
        data.rsvpWaitlist = formdata.rsvpWaitlist ? formdata.rsvpWaitlist : []
        data.rsvpUids = formdata.rsvpUids ? formdata.rsvpUids : []
        if (outputEditorState) {
            const htmlData = draftToHtml(convertToRaw(outputEditorState.getCurrentContent()))
            const modifiedHtml = removeFontFamily(htmlData);
            data.htmlContent = modifiedHtml;
        }

        if (props.selectedEvent && typeof (props.selectedEvent) == "object") {
            id = props.selectedEvent.id;
            if (uploadedImages && uploadedImages.length > 0) { } else {
                data.images = images
            }
            await EventDataService.updateEvent(props.selectedEvent.id, data);
        } else {
            const addeddata = await EventDataService.addEvent(data);
            id = addeddata.id;
            data.id = id;
            await EventDataService.updateEvent(id, data);
        }
        if (mainImage && fileMainImage) {
            const storageRef = ref(storage, `/events/${Date.now()}_${fileMainImage.name}`);
            const uploadTask = uploadBytesResumable(storageRef, fileMainImage);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );

                    // update progress
                    setPercent(percent);
                },
                (err) => console.log(err),
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                        console.log(url);
                        data.mainImage = url;
                        await EventDataService.updateEvent(id, data);
                    });
                }
            );
        } else {
            if (mainImage && mainImage != "") { } else {
                data.mainImage = "";
                await EventDataService.updateEvent(id, data);
            }
        }
        if (uploadedImages.length > 0) {
            uploadedImages.map((image) => {
                const storageRef = ref(storage, `/events/${Date.now()}_${image.name}`);
                const uploadTask = uploadBytesResumable(storageRef, image);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );

                        // update progress
                        setPercent(percent);
                    },
                    (err) => console.log(err),
                    () => {
                        // download url
                        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                            console.log(url);
                            data.images.push(url);
                            await EventDataService.updateEvent(id, data);
                        });
                    }
                );
            })
        } else {
        }
        resetAndReload()
    }

    function resetAndReload() {
        props.setIsOpen(false);
        props.updateGrid();
    }

    const handleChangeMainImage = (event) => {
        setMainImage(URL.createObjectURL(event.target.files[0]));
        setFileMainImage(event.target.files[0]);
    }

    const handleChangeImages = async (event) => {
        const choosenFile = Array.prototype.slice.call(event.target.files);
        const uploaded = [...uploadedImages];
        const tempimages = [...images];
        await Promise.all(
            choosenFile.map(async (file) => {
                const ext = (file.name ? file.name.split('.').pop() : file.path.split('.').pop()).toLowerCase();

                if (ext === 'heic' || ext === 'heif') {
                    if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                        const convertedImage = await heic2any({ blob: file });
                        tempimages.push(URL.createObjectURL(convertedImage));
                        uploaded.push(convertedImage);
                    }
                } else {
                    if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                        tempimages.push(URL.createObjectURL(file));
                        uploaded.push(file);
                    }
                }
            })
        );
        setImages(tempimages);
        setUploadedImages(uploaded);
    };

    const removeMainImage = () => {
        setMainImage("");
        setFileMainImage("");
    }

    const removeImages = (index) => {
        var tempuploadedImages = [...uploadedImages];
        tempuploadedImages.splice(index, 1);
        setUploadedImages(tempuploadedImages);
        var tempimages = [...images];
        tempimages.splice(index, 1);
        setImages(tempimages);
    }

    const handleImageUpload = (file) => {
        return new Promise((resolve, reject) => {
            try {
                let imgName = (props.selectedEvent && typeof (props.selectedEvent) == "object") ? `${props.selectedEvent.name}_${file.name}` : file.name
                const storageRef = ref(storage, `/events/${imgName}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                uploadTask.on(
                    'state_changed',
                    (snapshot) => { },
                    (error) => { reject(error); },
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            resolve({
                                data: {
                                    link: url,
                                },
                            });
                        });
                    }
                );
            } catch (error) {
                console.log('Error uploading image:', error);
                reject(error);
            }
        });
    };

    const removeFontFamily = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        const removeFontFamilyFromElement = (element) => {
            if (element.nodeType === 1 && element.hasAttribute('style')) {
                element.style.cssText = element.style.cssText.replace(/font-family[^;]+;?/g, '');
            }

            for (let i = 0; i < element.childNodes.length; i++) {
                removeFontFamilyFromElement(element.childNodes[i]);
            }
        };

        removeFontFamilyFromElement(doc.body);

        // Serialize the modified HTML back to string
        const modifiedHtml = new XMLSerializer().serializeToString(doc.body);

        return modifiedHtml;
    };
    useEffect(() => {
        setFileMainImage("");
        setUploadedImages([]);
        const fields = ['name', 'content', 'previewText', 'maximumAttendees', 'minimumAttendees', 'mainImage', 'id', 'isSignature', 'images', 'rsvps', 'rsvpWaitlist', 'rsvpUids', 'startTime', 'endTime'];
        if (props.selectedEvent && typeof (props.selectedEvent) == "object") {
            fields.forEach(field => setValue(field, props.selectedEvent[field]));
            setValue('startDate', moment(props.selectedEvent['startDate']).toDate());
            setValue('endDate', moment(props.selectedEvent['endDate']).toDate());
            setValue('price', props.selectedEvent['price']);
            setValue('address.city', props.selectedEvent.address['city']);
            setValue('address.country', props.selectedEvent.address['country']);
            setValue('address.state', props.selectedEvent.address['state']);
            setValue('address.street1', props.selectedEvent.address['street1']);
            setValue('address.street2', props.selectedEvent.address['street2']);
            setValue('address.zip', props.selectedEvent.address['zip']);
            setMainImage(props.selectedEvent.mainImage);
            setImages(props.selectedEvent.images);
            if (props.selectedEvent.htmlContent) {
                const html = props.selectedEvent.htmlContent
                const contentBlock = htmlToDraft(html);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const outputEditorState = EditorState.createWithContent(contentState);
                    setEditorState(outputEditorState)
                }
            } else {
                setEditorState(EditorState.createEmpty())
            }
            const startTime = getTimeFromDate(props.selectedEvent.startDate);
            setValue('startTime', startTime);
            const endTime = getTimeFromDate(props.selectedEvent.endDate);
            setValue('endTime', endTime);

        } else {
            fields.forEach(field => setValue(field, InitialEventState[field]));
            setValue('startDate', '');
            setValue('endDate', '');
            setValue('price', '');
            setValue('address.city', InitialEventState.address['city']);
            setValue('address.country', InitialEventState.address['country']);
            setValue('address.state', InitialEventState.address['state']);
            setValue('address.street1', InitialEventState.address['street1']);
            setValue('address.street2', InitialEventState.address['street2']);
            setValue('address.zip', InitialEventState.address['zip']);

            setMainImage("");
            setImages([]);
            setEditorState(EditorState.createEmpty())
        }
    }, [props.selectedEvent]);

    const customToolbar = {
        options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'link', 'image'],
        image: {
            uploadCallback: handleImageUpload,
            alt: { present: true, mandatory: false },
        },
    };

    return (
        <DrawerOverlay
            size={"medium"}
            position={"right"}
            style={{ width: "504px" }}
            open={props.isOpen}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader style={{
            background: "#F7F7F7",
            padding: "26px 32px"
        }}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    + {props.selectedEvent && typeof (props.selectedEvent) == "object" ? "Edit" : "New"} Event
                </DrawerHeaderTitle>
            </DrawerHeader>

            <DrawerBody>
                <form onSubmit={handleSubmit(onSubmit)}
                    className={styles.root} onReset={reset}
                    id={props.selectedEvent && typeof (props.selectedEvent) == "object" ? props.selectedEvent.id : "New"}>
                    <Label className={"side-drawer-field__label"} style={{ marginTop: "32px   " }}>
                        Name*
                    </Label>
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <input {...field}
                            placeholder={"Enter Name"}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} />}
                    />
                    <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}>
                        Preview Text*
                    </Label>
                    <Controller
                        name="previewText"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Textarea {...field} resize="vertical"
                            placeholder={'Enter Preview Text'}
                            className={" side-drawer-field-Textarea side-drawer-field__gap side-drawer-field__gap"} style={{ borderRadius: "16px !important" }} />}
                    />
                    <Label className={"side-drawer-field__label "} style={{ marginTop: 24, marginBottom: 8 }}>
                        Content
                    </Label>
                    {/* (shift+enter for new line) */}
                    <Editor
                        editorState={outputEditorState}
                        wrapperClassName="wrapperClassName"
                        placeholder={"Enter Content"}
                        editorClassName="editor-container"
                        onEditorStateChange={handleEditorStateChange}
                        toolbar={customToolbar}
                        style={{ borderRadius: "16px" }}
                        className={"side-drawer-field-default  side-drawer-field__gap side-drawer-field__gap"}
                    //editorStyle={{ lineHeight: '90%' }}
                    />


                    <div className={"d-flex justify-space-between"} style={{ marginTop: 24 }}>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"} style={
                                {
                                    marginTop: "24px"
                                }
                            }>
                                Start Date
                            </Label>
                            <Controller
                                name="startDate"
                                control={control}
                                render={({ field }) => <div className={"datepicker-custom-wrap"}><DatePicker
                                    showTimeSelect
                                    selected={field.value}
                                    contentAfter={<DateSVG />}
                                    onSelectDate={field.onChange}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="Select Start Date"
                                    className={"side-drawer-field-default  side-drawer-field__gap side-drawer-field__gap"}

                                    {...field}
                                    dateFormat={'do MMMM yyyy hh:mm a'}
                                /></div>}
                            />
                        </div>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"} style={
                                {
                                    marginTop: "24px"
                                }
                            }>
                                Start Time
                            </Label>
                            <Controller
                                name="startTime"
                                control={control}
                                render={({ field }) => <input
                                    className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                                    type="time" onChange={field.onChange}{...field} />}
                            />
                        </div>


                    </div>

                    <div className={"d-flex justify-space-between"}>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"}>
                                End Date
                            </Label>
                            <Controller
                                name="endDate"
                                control={control}
                                render={({ field }) => <div className={"datepicker-custom-wrap"}><DatePicker
                                    showTimeSelect
                                    selected={field.value}
                                    contentAfter={<DateSVG />}
                                    onSelectDate={field.onChange}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="Select End Date"
                                    className={"side-drawer-field-default  side-drawer-field__gap side-drawer-field__gap"}
                                    {...field}
                                    dateFormat={'do MMMM yyyy hh:mm a'}
                                /></div>}
                            />
                        </div>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"} style={
                                {}
                            }>
                                End Time
                            </Label>
                            <Controller
                                name="endTime"
                                control={control}
                                render={({ field }) => <input
                                    className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                                    type="time" onChange={field.onChange}{...field} />}
                            />

                        </div>
                    </div>
                    <Label className={"side-drawer-field__label"}>
                        Price $
                    </Label>
                    <Controller
                        name="price"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <input min="0" type="number" placeholder={'$'} {...field}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} />}
                    />
                    <div className={"d-flex justify-space-between"}>

                        <span>

                            <Label className={"side-drawer-field__label"}>
                                Maximum Attendees
                            </Label>
                            <Controller
                                name="maximumAttendees"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input type="number" {...field}
                                    placeholder={"Enter Number"}
                                    className={"side-drawer-field side-drawer-field__gap side-drawer-field__gap"}
                                    style={{ width: "75%" }} />}
                            />
                        </span>
                        <span>

                            <Label className={"side-drawer-field__label"}>
                                Minimum Attendees
                            </Label>
                            <Controller
                                name="minimumAttendees"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input type="number" {...field}
                                    className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                                    placeholder={"Enter Number"}
                                    style={{ width: "75%" }} />}
                            /></span>
                    </div>
                    <Label className={"side-drawer-field__label"}>
                        Signature Event
                    </Label>
                    <Controller
                        name="isSignature"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                            <div onClick={() => field.onChange(!field.value)}>
                                <Switch checked={field.value} {...field} />
                            </div>
                        )}
                    />

                    <Divider className={"side-drawer-divider-gap"} />

                    <div className={"d-flex"} style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}>
                        <div><AddressSVG /></div>
                        <Label className={"side-drawer-section__typo"} style={{ marginLeft: "8px" }}>ADDRESS</Label>
                    </div>
                    Street 1

                    <Controller
                        name="address.street1"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input {...field}
                            placeholder={"Enter Street 1"}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} />}
                    />
                    <Label className={"side-drawer-field__label"}>
                        Street 2
                    </Label>
                    <Controller
                        name="address.street2"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input {...field}
                            placeholder={"Enter Street 2"}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} />}
                    />
                    {/*<Label className={"side-drawer-field__label"}>*/}
                    {/*    City*/}
                    {/*</Label>*/}
                    {/*<Controller*/}
                    {/*    name="address.city"*/}
                    {/*    control={control}*/}
                    {/*    rules={{ required: false }}*/}
                    {/*    render={({ field }) => <input {...field} className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} />}*/}
                    {/*/>*/}
                    {/*<Label className={"side-drawer-field__label"}>*/}
                    {/*    State*/}
                    {/*</Label>*/}
                    {/*<Controller*/}
                    {/*    name="address.state"*/}
                    {/*    control={control}*/}
                    {/*    rules={{ required: false }}*/}
                    {/*    render={({ field }) => <input {...field}  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}/>}*/}
                    {/*/>*/}
                    {/*<Label className={"side-drawer-field__label"}>*/}
                    {/*    Country*/}
                    {/*</Label>*/}
                    {/*<Controller*/}
                    {/*    name="address.country"*/}
                    {/*    control={control}*/}
                    {/*    rules={{ required: false }}*/}
                    {/*    render={({ field }) => <input {...field} className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}/>}*/}
                    {/*/>*/}
                    {/*<Label className={"side-drawer-field__label"}>*/}
                    {/*    Zip*/}
                    {/*</Label>*/}
                    {/*<Controller*/}
                    {/*    name="address.zip"*/}
                    {/*    control={control}*/}
                    {/*    rules={{ required: false }}*/}
                    {/*    render={({ field }) => <input {...field} className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} />}*/}
                    {/*/>*/}
                    <div className={"d-flex justify-space-between"}>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"}>City</Label>
                            <Controller
                                name="address.city"
                                key="address.city"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter City"}
                                    className={"side-drawer-field side-drawer-field__gap"} {...field} />}
                            />
                        </div>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"}>State</Label>
                            <Controller
                                name="address.state"
                                key="address.state"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter State"}
                                    className={"side-drawer-field side-drawer-field__gap"} {...field} />}
                            />
                        </div>
                    </div>
                    <div className={"d-flex justify-space-between"}>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"}>Country</Label>
                            <Controller
                                name="address.country"
                                key="address.country"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter Country"}
                                    className={"side-drawer-field side-drawer-field__gap"} {...field} />}
                            />
                        </div>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"}>Zip</Label>
                            <Controller
                                name="address.zip"
                                key="address.zip"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter Zip"}
                                    className={"side-drawer-field side-drawer-field__gap"} {...field} />}
                            />
                        </div>
                    </div>
                    <Divider className={"side-drawer-divider-gap"} />
                    <Label className={"side-drawer-field__label"}> Main Image</Label>
                    {mainImage &&
                        <div>
                            <Image width={100} height={100} src={mainImage} alt="Main Image"></Image>
                            <Button icon={<DeleteIcon />} onClick={removeMainImage} />
                        </div>}
                    {!mainImage &&
                        <label className="fileCtrlMultiple">
                            <input type="file" id="file1" onChange={handleChangeMainImage} accept="image/*"
                                className="" aria-label="File browser example" placeholder="Browse" />
                        </label>
                    }
                    <Label className={"side-drawer-field__label"} style={{ marginTop: 24 }}> Images</Label>
                    <div>
                        {images && images.length > 0 && images.map((img, index) => <>
                            <Image width={100} height={100} src={img} alt="Main Image"></Image>
                            <Button icon={<DeleteIcon />} onClick={() => removeImages(index)} />
                        </>)}
                    </div>
                    {
                        <label className="fileCtrlMultiple">
                            <input type="file" id="file2" onChange={handleChangeImages} accept="image/*, .heic"
                                className="" aria-label="File browser example" multiple />
                        </label>
                    }
                    <div className="btnActions">
                        <button appearance="primary" type="submit" className="form-submit-btn pointer" >
                            {props.selectedEvent && typeof (props.selectedEvent) == "object" ? 'Update' : 'Submit'}</button>
                        <button className="form-submit-cancel pointer"
                            onClick={() => props.setIsOpen(false)}>Cancel</button>
                    </div>
                </form>
            </DrawerBody>
        </DrawerOverlay>
    )
}

export default EventForm
import {
    Button,
    Label,
    Table, TableBody,
    TableCell,
    TableCellActions,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands
} from "@fluentui/react-components";
import { DeleteFilled, DeleteRegular, EditFilled, EditRegular, bundleIcon } from "@fluentui/react-icons";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteEventDialog from "./components/DeleteEventDialog";
import VehicleForm from "./components/Forms/VehicleForm";
import ImageDialog from "./components/ImageDialog";
import Layout from "./components/Layout";
import { auth, db, trackException } from "./services/firebase";
import keyCafeDataService from "./services/keyCafeDataService";
import rentalLocationDataService from "./services/rentalLocationDataService";
import UserDataService from "./services/userDataService";
import VehicleDataService from "./services/vehicleDataService";

const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "year", label: "Year" },
    { columnKey: "make", label: "Make" },
    { columnKey: "model", label: "Model" },
    { columnKey: "member", label: "Member" },
    { columnKey: "pricePerDay", label: "Cost per Day" },
    { columnKey: "isPartOfWarehouseRentalFleet", label: "Rental Fleet" },
    { columnKey: "isPartOfWarehouseSalesFleet", label: "Sales Fleet" },
    { columnKey: "images", label: "Images" }
];

function UserDetails() {
    const styles = useStyles();
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState();
    const [allvehicles, setAllVehicles] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [alllocations, setAllLocations] = useState([]);
    const [fobKeys, setfobKeys] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const navigate = useNavigate();
    const { state } = useLocation();

    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedVehicle(selectedItem);
    }

    const fetchUserNameAndVehicles = async () => {
        try {
            const userquery = query(collection(db, "Users"), where("email", "==", state?.email));
            const userdoc = await getDocs(userquery);
            if (userdoc.docs.length > 0) {
                const vehiclesQuery = await VehicleDataService.getAllVehicleByUserId(state?.id);
                let vehicleArr = vehiclesQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                setAllVehicles([...vehicleArr]);
                const usersQuery = await UserDataService.getAllUser();
                setAllUsers(usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
            } else {
                console.log('no access');
            }
        } catch (err) {
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    const fetchAllLocations = async () => {
        try {
            const vehiclesQuery = await rentalLocationDataService.getAllRentalLocation();
            setAllLocations(vehiclesQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        } catch (error) {
            console.error(error);
            alert("An error occured while fetching location data");
            trackException(JSON.stringify(error))
        }
    }

    const fetchAllFobKeyFIR = async () => {
        try {
            const fobkeysQuery = await keyCafeDataService.getAllFobKeys();
            setfobKeys(fobkeysQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        } catch (error) {
            console.error(error);
            trackException(JSON.stringify(error))
        }
    }

    useEffect(() => {
        if (state && state?.email) {
            fetchUserNameAndVehicles();
            fetchAllLocations();
            fetchAllFobKeyFIR();
        } else {
            navigate("/")
        }

    }, []);

    const openDeleteForm = (selectedItem) => {
        setIsDeleteDialogOpen(true);
        setSelectedVehicle(selectedItem);
    }

    const DeleteVehicle = async () => {
        VehicleDataService.deleteVehicle(selectedVehicle.id);
        setIsDeleteDialogOpen(false);
        fetchUserNameAndVehicles();
    };
    return (
        <Layout>
            <div className={styles.content}>
                {allvehicles.length > 0 && (
                    <div className={styles.breakFlex}>
                        <Button shape="circular" appearance="primary" onClick={() => navigate(-1)}>Back</Button>
                    </div>
                )
                }
                <div>
                    {state && state.firstName && state.lastName && (
                        <div className={styles.userDetails}>
                            <div className={styles.details}>
                                <p><strong>Name:</strong> {state.firstName} {state.lastName}</p>
                            </div>
                        </div>
                    )}
                </div>
                {/* <div style={{ display: 'flex', justifyContent: 'space-between', width: '20%' }}>
                    <div>
                    <Label title={''}>{}</Label>
                    <img src="https://www.informalnewz.com/wp-content/uploads/2023/04/Driver-license.jpg" alt="Image 1" style={{ width: '100%', marginRight: '10%' }} />
                    </div>
                    <div>
                    <img src="https://www.informalnewz.com/wp-content/uploads/2023/04/Driver-license.jpg" alt="Image 2" style={{ width: '100%' }} />
                    </div>
                </div> */}
                <p><strong>Vehicles</strong></p>
                <div>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        {allvehicles.length > 0 &&
                            <TableBody>
                                {allvehicles.map((item) =>
                                    <TableRow key={item.id}>
                                        <TableCell>
                                            <TableCellLayout>
                                                {item.year}
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>
                                                <Label title={item.make}>{item.make}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>
                                                <Label title={item.model}>{item.model}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>
                                                <Label>{allUsers.filter(t => t.id == item.owner)[0]?.firstName} &nbsp;
                                                    {allUsers.filter(t => t.id == item.owner)[0]?.lastName}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>
                                                <Label title={item.pricePerDay}>{item.pricePerDay}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell>
                                            <TableCellLayout>
                                                <Label title={item.isPartOfWarehouseRentalFleet}>{item.isPartOfWarehouseRentalFleet === true ? 'Yes' : 'No'}</Label>
                                            </TableCellLayout>
                                        </TableCell>
                                        <TableCell>{item.isPartOfWarehouseSalesFleet === true ? 'Yes' : 'No'}
                                            <TableCellActions>
                                                {/* <Button
                                                    icon={<EditIcon />}
                                                    appearance="subtle"
                                                    aria-label="Edit"
                                                    onClick={() => openForm(item)}
                                                /> */}
                                                <Button
                                                    icon={<DeleteIcon />}
                                                    appearance="subtle"
                                                    aria-label="Delte"
                                                    onClick={() => openDeleteForm(item)}
                                                />
                                            </TableCellActions></TableCell>
                                        <TableCell>
                                            <Button className={"btnBorderOnly"} shape="circular" onClick={() => {
                                                setOpen(true);
                                                setSelectedVehicle(item);
                                            }}>Images</Button>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        }
                    </Table>
                </div>
            </div>
            <VehicleForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)} selectedVehicle={selectedVehicle}
                updateGrid={fetchUserNameAndVehicles} locations={alllocations} fobKeys={fobKeys} users={allUsers}></VehicleForm>
            <ImageDialog open={open} setOpen={setOpen} selectedEvent={selectedVehicle}></ImageDialog>
            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeleteVehicle} text={'Vehicle'} />
        </Layout>
    )
}

export default UserDetails;
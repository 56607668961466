import { useEffect, useState } from "react";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { Dismiss24Regular } from "@fluentui/react-icons";
import {
    Button, Image, Input, Label, makeStyles, shorthands, Textarea, Dropdown, Option
} from "@fluentui/react-components";
import { useForm, Controller } from "react-hook-form";
import { storage } from "../../services/firebase";
import { RocketRegular, CalendarCancelFilled } from "@fluentui/react-icons";
import {
    ref,
    uploadBytesResumable,
    getDownloadURL
} from "firebase/storage";
import moment from "moment";
import { bundleIcon, DeleteFilled, DeleteRegular } from "@fluentui/react-icons";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import BookingDataService from "../../services/bookingDataService";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialServiceState = {
    "bookObjId": "",
    "bookingType": "VehicleService",
    "createdBy": "",
    "createdDate": new Date().toISOString(),
    "description": "",
    "id": "",
    "serviceDate": "",
    "serviceProviderId": "",
    "vehicleId": "",
    "serviceObj": {
        "id": "",
        "description": "",
        "type": "",
        "serviceProviderIds": []
    }
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

function ServiceForm(props) {
    const styles = useStyles();
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedService ? props.selectedService : InitialServiceState
    });

    const onSubmit = async formdata => {
        var data = formdata;
        var id = "";
        if (props.selectedService) {
            id = props.selectedService.id;
            await BookingDataService.updateService(props.selectedService.id, data);
        } else {
            const addeddata = await BookingDataService.addService(data);
            id = addeddata.id;
        }
        props.setIsOpen(false);
        props.updateGrid();
    }

    useEffect(() => {
        const fields = ['bookObjId', 'createdBy', 'description', 'id',
            'serviceDate', 'serviceProviderId', 'vehicleId'];
        if (props.selectedService) {
            fields.forEach(field => setValue(field, props.selectedService[field]));
            setValue('serviceDate', moment(props.selectedService['serviceDate'].seconds*1000).toDate());
        } else {
            fields.forEach(field => setValue(field, InitialServiceState[field]));
        }
    }, [props.selectedService])

    return (
        <DrawerOverlay
            size={"medium"}
            position={"right"}
            open={props.isOpen}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    {props.selectedService ? "Edit" : "New"} Service
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <form onSubmit={handleSubmit(onSubmit)}
                    className={styles.root} onReset={reset} id={props.selectedService ? props.selectedService.id : "New"}>
                    <Label>
                        Description*
                    </Label>
                    <Controller
                        name="description"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Textarea {...field} resize="vertical" />}
                    />
                    <Label>
                        Service Date
                    </Label>
                    <Controller
                        name="serviceDate"
                        control={control}
                        render={({ field }) => <DatePicker
                            allowTextInput
                            value={field.value}
                            onSelectDate={field.onChange}
                            className={styles.control}
                            placeholder="Select a date..."
                            {...field}
                        />}
                    />
                    <Label>
                        Vehicle*
                    </Label>
                    <Controller
                        name="vehicleId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Dropdown multiselect={false}
                            onOptionSelect={(ev, data) => field.onChange(data.selectedOptions[0])}
                            selectedOptions={[field.value]}>
                            {props.vehicles.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {option.make} {option.model}
                                </Option>
                            ))}
                        </Dropdown>}
                    />
                    <Label>
                        Service Type*
                    </Label>
                    <Controller
                        name="serviceObj.id"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Dropdown multiselect={false}
                            onOptionSelect={(ev, data) => field.onChange(data.selectedOptions[0])}
                            selectedOptions={[field.value]}>
                            {props.serviceType.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {option.type}
                                </Option>
                            ))}
                        </Dropdown>}
                    />
                    <Label>
                        Service Provider*
                    </Label>
                    <Controller
                        name="serviceProviderId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Dropdown multiselect={false}
                            onOptionSelect={(ev, data) => field.onChange(data.selectedOptions[0])}
                            selectedOptions={[field.value]}>
                            {props.serviceProvider.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {option.providerName}
                                </Option>
                            ))}
                        </Dropdown>}
                    />
                    <div className="btnActions">
                        <Button appearance="primary" type="submit" className="btnSubmit" icon={<RocketRegular />}>
                            {props.selectedService ? 'Update' : 'Submit'}</Button>
                        <Button className="btnCancel" icon={<CalendarCancelFilled />}
                            onClick={() => props.setIsOpen(false)}>Cancel</Button>
                    </div>
                </form>
            </DrawerBody>
        </DrawerOverlay>
    )
}

export default ServiceForm
import {
    Button,
    Image,
    Input, Label, makeStyles, shorthands
} from "@fluentui/react-components";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { CalendarCancelFilled, DeleteFilled, DeleteRegular, Dismiss24Regular, RocketRegular, bundleIcon } from "@fluentui/react-icons";
import {
    getDownloadURL,
    ref,
    uploadBytesResumable
} from "firebase/storage";
import heic2any from 'heic2any';
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { storage } from "../../services/firebase";
import roomsDataService from "../../services/roomsDataService";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialRoomAddEditState = {
    "name": "",
    "id": "",
    "images": [],
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

function RoomAddEditForm(props) {
    const styles = useStyles();
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedRoom ? props.selectedRoom : InitialRoomAddEditState
    });
    const [images, setImages] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([])
    const [percent, setPercent] = useState(0);

    const onSubmit = async formdata => {
        var data = formdata;
        var id = "";
        data.createdDate = new Date().toISOString();
        if (props.selectedRoom) {
            id = props.selectedRoom.id;
            await roomsDataService.updateRooms(props.selectedRoom.id, data);
        } else {
            const addeddata = await roomsDataService.addRooms(data);
            id = addeddata.id;
            data.id = id;
            await roomsDataService.updateRooms(id, data);
        }

        if (uploadedImages.length > 0) {
            uploadedImages.map((image) => {
                const storageRef = ref(storage, `/rooms/${image.name}`);
                const uploadTask = uploadBytesResumable(storageRef, image);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => { },
                    (err) => console.log(err),
                    () => {
                        // download url
                        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                            console.log(url);
                            data.images.push(url);
                            await roomsDataService.updateRooms(id, data);
                            props.setIsOpen(false);
                        });
                    }
                );
            })
        } else {
            if (images.length == 0) {
                data.images = [];
                await roomsDataService.updateRooms(id, data);
                props.setIsOpen(false);
            } else {
                data.images = images;
                await roomsDataService.updateRooms(id, data);
                props.setIsOpen(false);
            }
        }
        props.setIsOpen(false);
        props.updateGrid();
    }

    useEffect(() => {
        const fields = ['name', 'id'];
        if (props.selectedRoom) {
            fields.forEach(field => setValue(field, props.selectedRoom[field]));
            if (props.selectedRoom.images && props.selectedRoom.images.length > 0) {
                setImages(props.selectedRoom.images);
            } else {
                setImages([]);
            }
        } else {
            fields.forEach(field => setValue(field, InitialRoomAddEditState[field]));
            setImages([]);
            setUploadedImages([])
        }
    }, [props.selectedRoom])

    const handleChangeImages = async (event) => {
        const choosenFile = Array.prototype.slice.call(event.target.files);
        const uploaded = [...uploadedImages];
        const tempimages = [...images];
        await Promise.all(
            choosenFile.map(async (file) => {
                const ext = (file.name ? file.name.split('.').pop() : file.path.split('.').pop()).toLowerCase();

                if (ext === 'heic' || ext === 'heif') {
                    if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                        const convertedImage = await heic2any({ blob: file });
                        tempimages.push(URL.createObjectURL(convertedImage));
                        uploaded.push(convertedImage);
                    }
                } else {
                    if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                        tempimages.push(URL.createObjectURL(file));
                        uploaded.push(file);
                    }
                }
            })
        );
        setImages(tempimages);
        setUploadedImages(uploaded);
    };

    const removeImages = (index) => {
        var tempuploadedImages = [...uploadedImages];
        tempuploadedImages.splice(index, 1);
        setUploadedImages(tempuploadedImages);
        var tempimages = [...images];
        tempimages.splice(index, 1);
        setImages(tempimages);
    }


    return (
        <DrawerOverlay
            size={"medium"}
            position={"right"}
            style={{ width: "504px" }}
            open={props.isOpen}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader  style={{
            background: "#F7F7F7",
            padding: "26px 32px"
        }}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    + {props.selectedRoom ? "Edit" : "New"} Rooms
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody style={{position:"relative"}}>
                <form onSubmit={handleSubmit(onSubmit)}
                    className={styles.root} onReset={reset} id={props.selectedRoom ? props.selectedRoom.id : "New"}>
                    <Label className={"side-drawer-field__label"} style={{marginTop:32}}>
                        Name*
                    </Label>
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Input className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                                                      placeholder={"Enter Name"}
                                                      {...field} />}
                    />
                    <Label className={"side-drawer-field__label"} style={{marginBottom:"8px"}}> Images</Label>
                    <div>
                        {images && images.length > 0 && images.map((img, index) => <>
                            <Image width={100} height={100} src={img} alt="Main Image"></Image>
                            <Button icon={<DeleteIcon />} onClick={() => removeImages(index)} />
                        </>)}
                    </div>
                    {
                        <label className="fileCtrlMultiple">
                            <input type="file" id="file2" onChange={handleChangeImages} accept="image/*, .heic" className="inputFileCtrl" aria-label="File browser example" multiple />
                        </label>
                    }
                    <div  style={{position:"absolute",bottom:0,width:"90%"}}>

                    <div className=" btnActions">
                        <button appearance="primary" type="submit" className="form-submit-btn pointer">
                            {props.selectedRoom ? 'Update' : 'Submit'}</button>
                        <button className="form-submit-cancel pointer"
                            onClick={() => props.setIsOpen(false)}>Cancel</button>
                    </div>
                    </div>
                </form>
            </DrawerBody>
        </DrawerOverlay>
    )
}

export default RoomAddEditForm
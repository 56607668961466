import {
    Button,
    Dropdown,
    Label,
    Option,
    Textarea,
    makeStyles, shorthands
} from "@fluentui/react-components";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { CalendarCancelFilled, DeleteFilled, DeleteRegular, Dismiss24Regular, RocketRegular, bundleIcon } from "@fluentui/react-icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
// import DatePicker from "react-datepicker";
import { DatePicker } from "@fluentui/react-datepicker-compat";

import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import BookingDataService from "../../services/bookingDataService";
import { DateSVG } from "../../static/svgs";
import { mergeDateTime, convertToMST1, getTimeFromDate } from "../../Helper";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialCoachState = {
    "bookObjId": "",
    "bookingType": "Coach",
    "description": "",
    "startDate": "",
    "endDate": "",
    "id": "",
    "coachType": "",
    "status": "",
    "createdBy": "",
    "createdDate": new Date().toISOString(),
    "memberId": "",
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

const coachTypes = [
    { "id": "Golf coaching", "name": "Golf coaching" },
    { "id": "Racing Simulator coaching", "name": "Racing Simulator coaching" }
]

function CoachForm(props) {
    const styles = useStyles();
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedCoach ? props.selectedCoach : InitialCoachState
    });
    const [selectedMemberPlaceholder, setSeletedMemberPlaceholder] = useState('');
    const [selectedCoachPlaceholder, setSelectedCoachPlaceholder] = useState('');

    const onSubmit = async formdata => {
        var data = formdata;
        var id = "";

        let selectedCoachMember = props.coachMembers.filter(obj => obj.id == data.coach);
        data.selectedCoach = selectedCoachMember[0];
        const mergedstartDateTime = mergeDateTime(data.startDate, data.startTime);
        const mergedendDateTime = mergeDateTime(data.endDate, data.endTime);
        data.startDate = moment(mergedstartDateTime).toISOString();
        data.endDate = moment(mergedendDateTime).toISOString();

        if (props.selectedCoach) {
            id = props.selectedCoach.id;
            try {
                await BookingDataService.updateBooking(props.selectedCoach.id, data);
            } catch (error) {
                console.log("error->", error)
            }
        } else {
            data.createdBy = props.createdBy;
            const addeddata = await BookingDataService.addBooking(data);
            id = addeddata.id;
        }
        props.setIsOpen(false);
        props.updateGrid();
    }

    useEffect(() => {
        const fields = ['bookObjId', 'bookingType', 'description', 'coachType', 'coach', 'startDate',
            'endDate', 'id', 'status', 'createdBy', 'createdDate', 'startTime', 'endTime'];
        if (props.selectedCoach) {
            fields.forEach(field => setValue(field, props.selectedCoach[field]));
            setValue('startDate', moment(props.selectedCoach['startDate']).toDate());
            setValue('endDate', moment(props.selectedCoach['endDate']).toDate());
            const startTime = getTimeFromDate(props.selectedCoach.startDate);
            setValue('startTime', startTime);
            const endTime = getTimeFromDate(props.selectedCoach.endDate);
            setValue('endTime', endTime);

            let selectedMember = props.users.filter(obj => obj.id == props.selectedCoach.memberId);
            if (selectedMember[0] && selectedMember[0].firstName) {
                let str = `${selectedMember[0].firstName} ${selectedMember[0].lastName}`
                setSeletedMemberPlaceholder(str);
            } else {
                setSeletedMemberPlaceholder('');
            }
            let selectedCoachMember = props.coachMembers.filter(obj => obj.id == props.selectedCoach.coach);
            if (selectedCoachMember[0] && selectedCoachMember[0].firstName) {
                let str = `${selectedCoachMember[0].firstName} ${selectedCoachMember[0].lastName}`
                setSelectedCoachPlaceholder(str);
            } else {
                setSelectedCoachPlaceholder('');
            }
        } else {
            fields.forEach(field => setValue(field, InitialCoachState[field]));
            setSeletedMemberPlaceholder('');
            setSelectedCoachPlaceholder('');
        }
    }, [props.selectedCoach])

    function updateSelectedMember(selobj) {
        let _ = props.users.filter(obj => obj.id == selobj[0]);
    }

    return (
        <DrawerOverlay
            size={"medium"}
            position={"right"}
            style={{ width: "504px" }}
            open={props.isOpen}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader style={{
            background: "#F7F7F7",
            padding: "26px 32px"
        }}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    + {props.selectedCoach ? "Edit" : "New"} Coach Booking
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <form onSubmit={handleSubmit(onSubmit)}
                    className={styles.root} onReset={reset} id={props.selectedCoach ? props.selectedCoach.id : "New"}>
                    <Label className={"side-drawer-field__label"} style={{ marginTop: 32 }}>
                        Coach Type*
                    </Label>
                    <Controller
                        name="coachType"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <Dropdown multiselect={false}
                            className={"side-drawer-field-dropdown  side-drawer-field__gap"}
                            placeholder={"Select Coach Type"}
                            onOptionSelect={(ev, data) => field.onChange(data.selectedOptions[0])}
                            selectedOptions={[field.value]}>
                            {coachTypes.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {option.name}
                                </Option>
                            ))}
                        </Dropdown>}
                    />
                    {
                        props.coachMembers && (
                            <>
                                <Label className={"side-drawer-field__label"}>
                                    Coach*
                                </Label>
                                <Controller
                                    name="coach"
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field }) => <Dropdown multiselect={false}
                                        className={"side-drawer-field-dropdown  side-drawer-field__gap"}
                                        onOptionSelect={(ev, data) => field.onChange(data.selectedOptions[0])}
                                        placeholder={"Select Coach "}
                                        selectedOptions={[field.value]}>
                                        {props.coachMembers.map((option) => (
                                            <Option key={option.id} value={option.id}>
                                                {`${option.firstName} ${option.lastName}`}
                                            </Option>
                                        ))}
                                    </Dropdown>}
                                />
                            </>
                        )
                    }
                    <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}>
                        Description
                    </Label>
                    <Controller
                        name="description"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <Textarea
                            placeholder={'Enter Description'}
                            className={" side-drawer-field-Textarea side-drawer-field__gap side-drawer-field__gap"}
                            style={{ borderRadius: "16px !important" }}
                            {...field} resize="vertical" />}
                    />
                    <div className={"d-flex justify-space-between"}>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"} style={
                                {
                                    marginTop: "24px"
                                }
                            }>
                                Start Date
                            </Label>
                            <Controller
                                name="startDate"
                                control={control}
                                render={({ field }) => <div className={"datepicker-custom-wrap"}><DatePicker
                                    showTimeSelect
                                    selected={field.value}
                                    contentAfter={<DateSVG />}
                                    onSelectDate={field.onChange}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="Select Start Date"
                                    className={"side-drawer-field-default  side-drawer-field__gap side-drawer-field__gap"}

                                    {...field}
                                    dateFormat={'do MMMM yyyy hh:mm a'}
                                /></div>}
                            />
                        </div>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"} style={
                                {
                                    marginTop: "24px"
                                }
                            }>
                                Start Time
                            </Label>
                            <Controller
                                name="startTime"
                                control={control}
                                render={({ field }) => <input
                                    className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                                    type="time" onChange={field.onChange}{...field} />}
                            />
                        </div>


                    </div>

                    <div className={"d-flex justify-space-between"}>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"}>
                                End Date
                            </Label>
                            <Controller
                                name="endDate"
                                control={control}
                                render={({ field }) => <div className={"datepicker-custom-wrap"}><DatePicker
                                    showTimeSelect
                                    selected={field.value}
                                    contentAfter={<DateSVG />}
                                    onSelectDate={field.onChange}
                                    style={{
                                        width: "100%",
                                        height: "44px"
                                    }}
                                    placeholder="Select End Date"
                                    className={"side-drawer-field-default  side-drawer-field__gap side-drawer-field__gap"}
                                    {...field}
                                    dateFormat={'do MMMM yyyy hh:mm a'}
                                /></div>}
                            />
                        </div>
                        <div style={{ display: "grid", width: "49%" }}>
                            <Label className={"side-drawer-field__label"} style={
                                {}
                            }>
                                End Time
                            </Label>
                            <Controller
                                name="endTime"
                                control={control}
                                render={({ field }) => <input
                                    className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                                    type="time" onChange={field.onChange}{...field} />}
                            />

                        </div>
                    </div>

                    <Label className={"side-drawer-field__label"}>
                        Member*
                    </Label>
                    <Controller
                        name="memberId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Dropdown multiselect={false}
                            className={"side-drawer-field-dropdown  side-drawer-field__gap"}
                            onOptionSelect={(ev, data) => {
                                field.onChange(data.selectedOptions[0])
                                updateSelectedMember(data.selectedOptions)
                            }}
                            selectedOptions={[field.value]}
                            placeholder={"Select Member"}
                        >
                            {props.users.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {`${option.firstName} ${option.lastName}`}
                                </Option>
                            ))}
                        </Dropdown>}
                    />
                    <div style={{marginTop:'120px'}}/>
                    <div style={{ position: "absolute", bottom: 0, width: "90%" }}>
                        <div className="btnActions">
                            <button appearance="primary" type="submit" className="form-submit-btn pointer">
                                {props.selectedCoach ? 'Update' : 'Submit'}</button>
                            <button className="form-submit-cancel pointer"
                                onClick={() => props.setIsOpen(false)}>Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </DrawerBody>
        </DrawerOverlay>
    )
}

export default CoachForm
import {
    Button,
    Input, Label,
    Textarea,
    makeStyles, shorthands
} from "@fluentui/react-components";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { CalendarCancelFilled, DeleteFilled, DeleteRegular, Dismiss24Regular, RocketRegular, bundleIcon } from "@fluentui/react-icons";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { Base_Url } from "../../services/firebase";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialKeyState = {
    "id": "",
    "name": "",
    "description": "",
    "serialNumber": "",
    "createdDate": new Date().toISOString()
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

function KeyCafeKeyForm(props) {
    const styles = useStyles();
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedKey ? props.selectedKey : InitialKeyState
    });
    const onSubmit = async formdata => {
        var data = formdata;
        var id = "";
        if (props.selectedKey) {
            //TODO: Call Edit key API
        } else {
            await createKeyAPI(data)
        }
    }

    const createKeyAPI = async (req) => {
        let data = JSON.stringify({
            "name": req.name,
            "description": req.description,
            "serialNumber": req.serialNumber
        });
        let config = {
            method: 'post',
            url: `${Base_Url}/api/CreateKey`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                console.log(response.data);
                if (response && response.data) {
                    if (response.data && response.data.data.error) {
                        alert(response.data.data.error?.errorMessage)
                    } else {
                        return;
                    }
                }
                props.setIsOpen(false);
                props.updateGrid();
            })
            .catch((error) => {
                console.log(error);
                alert(error)
            });
    }

    useEffect(() => {
        const fields = ['id', 'name', 'description', 'serialNumber'];
        if (props.selectedKey) {
            fields.forEach(field => setValue(field, props.selectedKey[field]));
        } else {
            fields.forEach(field => setValue(field, InitialKeyState[field]));
        }
    }, [props.selectedKey])

    return (
        <DrawerOverlay
            size={"medium"}
            position={"right"}
            open={props.isOpen}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    {props.selectedKey ? "Edit" : "New"} Key
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <form onSubmit={handleSubmit(onSubmit)}
                    className={styles.root} onReset={reset} id={props.selectedKey ? props.selectedKey.id : "New"}>
                    <Label>
                        Name*
                    </Label>
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Input {...field} />}
                    />
                    <Label>
                        Description*
                    </Label>
                    <Controller
                        name="description"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Textarea {...field} resize="vertical" />}
                    />
                    <Label>
                        FOB#
                    </Label>
                    <div>The serial number of the fob attached to the key.</div>
                    <Controller
                        name="serialNumber"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Input {...field} />}
                    />
                    <div className="btnActions">
                        <Button appearance="primary" type="submit" className="btnSubmit" icon={<RocketRegular />}>
                            {props.selectedKey ? 'Update' : 'Submit'}</Button>
                        <Button className="btnCancel" icon={<CalendarCancelFilled />}
                            onClick={() => props.setIsOpen(false)}>Cancel</Button>
                    </div>
                </form>
            </DrawerBody>
        </DrawerOverlay>
    )
}

export default KeyCafeKeyForm
import React, { useEffect, useState } from "react";
import Layout from "./components/Layout";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, trackException } from "./services/firebase";
import { useNavigate } from "react-router-dom";
import {
    makeStyles, shorthands, Persona, Button, Table, TableBody,
    TableHeader, TableRow, TableHeaderCell, TableCell,
    TableCellLayout, Image, TableCellActions, Label
} from "@fluentui/react-components";
import { query, collection, getDocs, where } from "firebase/firestore";
import { EditFilled, EditRegular, bundleIcon, DeleteFilled, DeleteRegular } from "@fluentui/react-icons";
import DrivingRoadDataService from "./services/drivingRoadsDataService";
import moment from 'moment';
import ImageDialog from "./components/ImageDialog";
import DeleteEventDialog from "./components/DeleteEventDialog";
import DrivingRoadForm from "./components/Forms/DrivingRoadForm";
import rentalLocationDataService from "./services/rentalLocationDataService";
import CustomSearchInput from "./components/CustomSearchInput";
import debounce from 'lodash/debounce';
import { PlusSVG } from "./static/svgs";

const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "name", label: "Name" },
    { columnKey: "description", label: "Description" },
    { columnKey: "images", label: "Images" }
];

function DrivingRoads() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedDrivingRoad, setSelectedDrivingRoad] = useState();
    const [alldrivingRoads, setAllDrivingRoads] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredDrivingRoad, setFilteredDrivingRoad] = useState([]);

    const [alllocations, setAllLocations] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const navigate = useNavigate();
    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedDrivingRoad(selectedItem);
    }

    const fetchUserNameAndDrivingRoads = async () => {
        try {
            if (!!user) {
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                if (userdoc.docs.length > 0) {
                    const drivingRoadsQuery = await DrivingRoadDataService.getAllDrivingRoad();
                    let drivingRoadsArr = drivingRoadsQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                    setAllDrivingRoads(drivingRoadsArr);
                    setFilteredDrivingRoad(drivingRoadsArr)
                    //console.log(allevents);
                } else {
                    console.log('no access');
                }
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    const fetchAllLocations = async () => {
        try {
            const vehiclesQuery = await rentalLocationDataService.getAllRentalLocation();
            setAllLocations(vehiclesQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        } catch (error) {
            console.error(error);
            alert("An error occured while fetching location data");
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        fetchUserNameAndDrivingRoads();
        fetchAllLocations();
    }, [user, loading]);

    const openDeleteForm = (selectedItem) => {
        setIsDeleteDialogOpen(true);
        setSelectedDrivingRoad(selectedItem);
        // fetchUserNameAndEvents();
    }

    const DeleteDrivingRoad = async () => {
        DrivingRoadDataService.deleteDrivingRoad(selectedDrivingRoad.id);
        setIsDeleteDialogOpen(false);
        fetchUserNameAndDrivingRoads();
    };

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = alldrivingRoads.filter(event =>
            event.name.toLowerCase().includes(lowerSearch)
        );
        setFilteredDrivingRoad(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };

    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <Button icon={<PlusSVG />} className={"primary-btn"} shape="circular" appearance="primary" onClick={() => openForm(undefined)}>{'New Driving Road'.toUpperCase()}</Button>
                    <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    />
                </div>
                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredDrivingRoad.length > 0 && filteredDrivingRoad.map((item) =>
                                <TableRow key={item.id}>
                                    <TableCell className={"table-row-fonts__primary"}>
                                        <TableCellLayout>
                                            {item.name}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label title={item.description}>{item.description.substring(0, 150)}</Label>
                                        </TableCellLayout>

                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <Button className={"btnBorderOnly"} shape="circular" onClick={() => {
                                            setOpen(true);
                                            setSelectedDrivingRoad(item);
                                        }}>Images</Button>
                                        <TableCellActions>
                                            <Button
                                                icon={<EditIcon />}
                                                appearance="subtle"
                                                aria-label="Edit"
                                                onClick={() => openForm(item)}
                                            />
                                            <Button
                                                icon={<DeleteIcon />}
                                                appearance="subtle"
                                                aria-label="Delte"
                                                onClick={() => openDeleteForm(item)}
                                            />
                                        </TableCellActions>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <DrivingRoadForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)} selectedDrivingRoad={selectedDrivingRoad}
                updateGrid={fetchUserNameAndDrivingRoads} locations={alllocations}></DrivingRoadForm>
            <ImageDialog open={open} setOpen={setOpen} selectedEvent={selectedDrivingRoad}></ImageDialog>
            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeleteDrivingRoad} text={'DrivingRoad'} />
        </Layout>
    )
}

export default DrivingRoads;
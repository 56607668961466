import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase";

const attractionsCollection = collection(db, "Attractions");

class AttractionDataService {
    addAttraction = (newAttraction) => {
        return addDoc(attractionsCollection, newAttraction);
    }

    updateAttraction = (id, updatedAttraction) => {
        const attractionsDoc = doc(db, "Attractions", id);
        return updateDoc(attractionsDoc, updatedAttraction);
    }

    deleteAttraction = (id) => {
        const attractionsDoc = doc(db, "Attractions", id);
        return deleteDoc(attractionsDoc);
    }

    getAllAttraction = () => {
        return getDocs(attractionsCollection)
    }

    getAttraction = (id) => {
        const attractionsDoc = doc(db, "Attractions", id);
        return getDoc(attractionsDoc);
    }
}

export default new AttractionDataService();
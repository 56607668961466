import {
    Button,
    Dropdown,
    Input, Label,
    Option,
    Textarea,
    makeStyles, shorthands
} from "@fluentui/react-components";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { CalendarCancelFilled, DeleteFilled, DeleteRegular, Dismiss24Regular, RocketRegular, bundleIcon } from "@fluentui/react-icons";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import NotificationDataService from "../../services/notificationDataService";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialNotificationState = {
    "isRead": false,
    "message": "",
    "subject": "",
    "createdDate": new Date().toISOString(),
    "acknowledged": false,
    "createdFor": "All Users",
    "createdBy": ""
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

function NotificationForm(props) {
    const styles = useStyles();
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedNotification ? props.selectedNotification : InitialNotificationState
    });

    const onSubmit = async formdata => {
        var data = formdata;
        var id = "";
        if (props.selectedNotification) {
            id = props.selectedNotification.id;
            data.id = id;
            await NotificationDataService.updatenotification(props.selectedNotification.id, data);
        } else {
            data.createdBy = props.createdBy;
            if (data.createdFor) {
                if (typeof (data.createdFor) === 'string') {
                    data.createdFor = [data.createdFor];
                }
                await Promise.all(data.createdFor.map(async (singleitem) => {
                    var tempdata = data;
                    if (singleitem != 'All Users') {
                        tempdata.createdFor = singleitem;
                        const addeddata = await NotificationDataService.addnotification(tempdata);
                        id = addeddata.id;
                    } else if (singleitem == 'All Users') {
                        tempdata.createdFor = '';
                        const addeddata = await NotificationDataService.addnotification(tempdata);
                        id = addeddata.id;
                    }
                    tempdata.id = id;
                    await NotificationDataService.updatenotification(id, tempdata);
                }));
            }
        }
        props.setIsOpen(false);
        props.updateGrid();
    }

    useEffect(() => {
        const fields = ['message', 'subject', 'createdFor'];
        if (props.selectedNotification) {
            fields.forEach(field => setValue(field, props.selectedNotification[field]));
        } else {
            fields.forEach(field => setValue(field, InitialNotificationState[field]));
        }
    }, [props.selectedNotification])

    return (
        <DrawerOverlay
            style={{ width: "504px" }}
            size={"medium"}
            position={"right"}
            open={props.isOpen}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader style={{
            background: "#F7F7F7",
            padding: "26px 32px"
        }}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    + {props.selectedNotification ? "Edit" : "New"} Notification
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <form onSubmit={handleSubmit(onSubmit)}
                    className={styles.root} onReset={reset} id={props.selectedNotification ? props.selectedNotification.id : "New"}>
                    <Label className={"side-drawer-field__label"} style={{ marginTop: "32px   " }}>
                        To
                    </Label>
                    <Controller
                        name="createdFor"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Dropdown multiselect={true}
                            className={"side-drawer-field-dropdown  side-drawer-field__gap"}
                                                         placeholder={"Select User"}
                            onOptionSelect={(ev, data) => field.onChange(data.selectedOptions)}
                            selectedOptions={typeof (field.value) === "string" ? [field.value ? field.value : 'All Users'] : field.value}>
                            <Option key={'All Users'} value={'All Users'}>
                                {`All Users`}
                            </Option>
                            {props.options.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {`${option.firstName} ${option.lastName}`}
                                </Option>
                            ))}
                        </Dropdown>}
                    />
                    <Label className={"side-drawer-field__label"}>
                        Subject
                    </Label>
                    <Controller
                        name="subject"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Input className={"side-drawer-field  side-drawer-field__gap"} placeholder={"Enter Subject"} {...field} />}
                    />
                    <Label className={"side-drawer-field__label"} style={{marginBottom:8}}>
                        Message
                    </Label>
                    <Controller
                        name="message"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Textarea className={" side-drawer-field-Textarea side-drawer-field__gap side-drawer-field__gap"} placeholder={"Enter Message"} style={{ borderRadius: "16px !important" }}
                            {...field} resize="vertical" />}
                    />
                    <div style={{ position: "absolute", bottom: 0, width: "90%" }}>
                        <div className="btnActions">
                            <button appearance="primary" type="submit" className="form-submit-btn pointer">
                                {props.selectedNotification ? 'Update' : 'Submit'}</button>
                            <button className="form-submit-cancel pointer"
                                onClick={() => props.setIsOpen(false)}>Cancel</button>
                        </div>
                    </div>
                </form>
            </DrawerBody>
        </DrawerOverlay>
    )
}

export default NotificationForm
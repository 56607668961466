import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "./firebase";

const rentalCollection = collection(db, "Rental");

class RentalDataService {
    addRental = (newRental) => {
        return addDoc(rentalCollection, newRental);
    }

    updateRental = (id, updatedRental) => {
        const rentalDoc = doc(db, "Rental", id);
        return updateDoc(rentalDoc, updatedRental);
    }

    deleteRental = (id) => {
        const rentalDoc = doc(db, "Rental", id);
        return deleteDoc(rentalDoc);
    }

    getAllRental = () => {
        const q = query(rentalCollection, orderBy("startDate",'desc'));
        return getDocs(q);
    }

    getRental = (id) => {
        const rentalDoc = doc(db, "Rental", id);
        return getDoc(rentalDoc);
    }
}

export default new RentalDataService();
// Import the functions you need from the SDKs you need
import axios from "axios";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut
} from "firebase/auth";
import { getDatabase } from "firebase/database";
import {
  getFirestore
} from "firebase/firestore";
import { getStorage } from "firebase/storage";

// import { getAuth as getAdminAuth, createCustomToken } from "firebase-admin/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
export const Base_Url = process.env.REACT_APP_API_URL

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
// export const adminAuth = getAdminAuth();
export const auth = getAuth(app);
export const db = getFirestore(app);
export const database = getDatabase(app);
export const storage = getStorage(app);

export const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    trackEventAnalytics({ error: JSON.stringify(err) })
    alert(err.message);
  }
};
export const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    // await addDoc(collection(db, "users"), {
    //   uid: user.uid,
    //   name,
    //   authProvider: "local",
    //   email,
    // });
    return user;
  } catch (err) {
    trackEventAnalytics({ error: JSON.stringify(err) })
    console.error(err);
    alert(err.message);
    throw err
  }
};

export const registerWithEmailAndPassword2 = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    return user;
  } catch (err) {
    trackEventAnalytics({ error: JSON.stringify(err) })
    throw err
  }
};
export const apiRegisterWithEmailAndPassword2 = async (email, password) => {
  try {
    const response = await axios.post(`${Base_Url}/api/createNewUser`, {
      email: email,
      password: password,
    });
    if (response.data.error) {
      throw response.data.error
    } else {
      return response.data.data;
    }
  } catch (err) {
    trackEventAnalytics({ error: JSON.stringify(err) })
    throw err
  }
};

export const createCustomerSubscriptionPOSTRequest = async (priceToken, customerId) => {
  try {
    const response = await axios.post(`${Base_Url}/createCustomerSubscriptionPOST`, {
      priceToken,
      customerId,
      metadata: { isPaymentFrom: "Subscriptions" }
    });
    if (response.data.error) {
      throw response.data.error
    } else {
      return response.data.data;
    }
  } catch (err) {
    trackEventAnalytics({ error: JSON.stringify(err) })
    throw err
  }
};
export const createOneTimePaymentWithProductIdRequest = async (productId, customerId) => {
  try {
    const response = await axios.post(`${Base_Url}/createOneTimePaymentWithProductId`, {
      productId,
      customerId,
      metadata: { isPaymentFrom: "Addon" }
    });
    if (response.data.error) {
      throw response.data.error
    } else {
      return response.data.data;
    }
  } catch (err) {
    trackEventAnalytics({ error: JSON.stringify(err) })
    throw err
  }
};


export const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true
  } catch (err) {
    trackEventAnalytics({ error: JSON.stringify(err) })
    console.error(err);
    alert(err.message);
    return false
  }
};
export const logout = () => {
  signOut(auth);
};


export const userAnalytics = (user) => {
  setUserId(analytics, user.uid);
}

export const trackEventAnalytics = (obj) => {
  let analyticsObj = obj
  try {
    const analytics = getAnalytics();
    logEvent(analytics, 'error_content', {
      obj
    });
  } catch (err) {
  }
};

export const trackException = (desc) => {
  try {
    const analytics = getAnalytics();
    logEvent(analytics, 'exception', {
      description: desc
    });
  } catch (err) {
  }
}
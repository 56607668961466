import { FluentProvider, createDarkTheme, createLightTheme } from '@fluentui/react-components';
import React from 'react';
import { render } from "react-dom"; // add this
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById('root'));

const myNewTheme = {
  10: "#050301",
  20: "#1F160A",
  30: "#342211",
  40: "#452D14",
  50: "#563717",
  60: "#68421A",
  70: "#7B4C1C",
  80: "#8E581E",
  90: "#A16320",
  100: "#B56F22",
  110: "#CA7B24",
  120: "#DE8726",
  130: "#EE9639",
  140: "#F5A95F",
  150: "#FABC82",
  160: "#FECFA6"
};

const lightTheme = {
  ...createLightTheme(myNewTheme),
};

const darkTheme = {
  ...createDarkTheme(myNewTheme),
};


darkTheme.colorBrandForeground1 = myNewTheme[110];
darkTheme.colorBrandForeground2 = myNewTheme[120];


render(
  <React.StrictMode>
    <FluentProvider theme={lightTheme}>
      <Provider store={store}>
        <App />
      </Provider>
    </FluentProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {
    Button, Image, Input, Label, makeStyles, shorthands, Textarea, Spinner, Dropdown, Option
} from "@fluentui/react-components";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { CalendarCancelFilled, DeleteFilled, DeleteRegular, Dismiss24Regular, RocketRegular, bundleIcon } from "@fluentui/react-icons";
import { AtomicBlockUtils, EditorState, convertToRaw, ContentState, Modifier } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Timestamp } from "firebase/firestore";
import htmlToDraft from 'html-to-draftjs';
import {
    getDownloadURL,
    getStorage,
    ref,
    uploadBytesResumable
} from "firebase/storage";
import heic2any from 'heic2any';
import moment from "moment";
import { useEffect, useState } from "react";
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Controller, useForm } from "react-hook-form";
import { storage } from "../../services/firebase";
import PostDataService from "../../services/postDataService";
import "./PostForm.css";
import { DateSVG } from "../../static/svgs";
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialPostState = {
    "date": "",
    "createdDate": "",
    "fullText": "",
    "headerText": "",
    "previewText": "",
    "id": "",
    "images": [],
    "mainImage": "",
    "categoriesId": "",
    "selectedCategory":{}
};

const categoriesList = [
    { "id": "1001", "name": "WAREHOUSE Recaps" },
    { "id": "1002", "name": "Auto News" },
    { "id": "1003", "name": "Motorcycles" },
    { "id": "1004", "name": "Watches" },
    { "id": "1005", "name": "Art" },
    { "id": "1006", "name": "Blog Posts" },
]

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

function PostForm(props) {
    const styles = useStyles();
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedPost ? props.selectedPost : InitialPostState
    });
    const [mainImage, setMainImage] = useState(props.selectedPost ? props.selectedPost.mainImage : "");
    const [images, setImages] = useState(props.selectedPost ? props.selectedPost.images : []);
    const [fileMainImage, setFileMainImage] = useState("");
    const [uploadedImages, setUploadedImages] = useState([])
    const [percent, setPercent] = useState(0);
    const [outputEditorState, setEditorState] = useState(EditorState.createEmpty());
    const [isLoading, setIsLoading] = useState(false)
    const [selectedCategoryPlaceholder, setSelectedCategoryPlaceholder] = useState('Select categories');

    const handleEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
    };

    const onSubmit = async formdata => {
        var data = formdata;
        var id = "";
        data.date = data.date.toString();
        data.dateUTC = new Date(data.date).toISOString()
        const atimestamp = Timestamp.fromDate(new Date(data.date));
        data.dateTimestamp = atimestamp;
        if (outputEditorState) {
            const htmlData = draftToHtml(convertToRaw(outputEditorState.getCurrentContent()))
            const modifiedHtml = removeFontFamily(htmlData);
            data.htmlContent = modifiedHtml;
        }
        let selectedCategories = categoriesList.filter(obj => obj.id == data.categoriesId);
        data.selectedCategory = selectedCategories[0];

        if (props.selectedPost) {
            id = props.selectedPost.id;
            if (uploadedImages && uploadedImages.length > 0) { } else {
                data.images = images;
            }
            await PostDataService.updatePost(props.selectedPost.id, data);
        } else {
            const addeddata = await PostDataService.addPost(data);
            id = addeddata.id;
        }
        if (mainImage && fileMainImage) {
            const storageRef = ref(storage, `/posts/${id}_${fileMainImage.name}`);
            const uploadTask = uploadBytesResumable(storageRef, fileMainImage);
            setIsLoading(true)
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    // update progress
                    setPercent(percent);
                },
                (err) => {
                    console.log(err);
                    setIsLoading(false)
                    onCloseForm()
                },
                () => {
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                        data.mainImage = url;
                        await PostDataService.updatePost(id, data);
                    });
                    setIsLoading(false)
                    onCloseForm()
                }
            );
        } else {
            if (mainImage && mainImage != "") { } else {
                data.mainImage = "";
                await PostDataService.updatePost(id, data);
                props.setIsOpen(false);
            }
            onCloseForm()
        }
        if (uploadedImages.length > 0) {
            uploadedImages.map((image) => {
                const storageRef = ref(storage, `/posts/${Date.now()}_${image.name}`);
                const uploadTask = uploadBytesResumable(storageRef, image);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                        // update progress
                        setPercent(percent);
                    },
                    (err) => console.log(err),
                    () => {
                        // download url
                        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                            console.log(url);
                            data.images.push(url);
                            await PostDataService.updatePost(id, data);
                        });
                    }
                );
            })
        } else {
            if (images && images.length > 0) { } else {
                data.images = [];
                await PostDataService.updatePost(id, data);
                props.setIsOpen(false);
            }
        }
    }

    function onCloseForm() {
        props.setIsOpen(false);
        props.updateGrid();
    }

    const handleChangeMainImage = (event) => {
        setMainImage(URL.createObjectURL(event.target.files[0]));
        setFileMainImage(event.target.files[0]);
    }

    const handleChangeImages = async (event) => {
        const choosenFile = Array.prototype.slice.call(event.target.files);
        const uploaded = [...uploadedImages];
        const tempimages = [...images];
        await Promise.all(
            choosenFile.map(async (file) => {
                const ext = (file.name ? file.name.split('.').pop() : file.path.split('.').pop()).toLowerCase();

                if (ext === 'heic' || ext === 'heif') {
                    if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                        const convertedImage = await heic2any({ blob: file });
                        tempimages.push(URL.createObjectURL(convertedImage));
                        uploaded.push(convertedImage);
                    }
                } else {
                    if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                        tempimages.push(URL.createObjectURL(file));
                        uploaded.push(file);
                    }
                }
            })
        );
        setImages(tempimages);
        setUploadedImages(uploaded);
    };

    const removeMainImage = () => {
        setMainImage("");
        setFileMainImage("");
    }

    const removeImages = (index) => {
        var tempuploadedImages = [...uploadedImages];
        tempuploadedImages.splice(index, 1);
        setUploadedImages(tempuploadedImages);
        var tempimages = [...images];
        tempimages.splice(index, 1);
        setImages(tempimages);
    }

    useEffect(() => {
        const fields = ['fullText', 'headerText', 'previewText', 'id', 'mainImage', 'images', 'categoriesId'];
        if (props.selectedPost) {
            setFileMainImage("")
            setUploadedImages([])
            fields.forEach(field => setValue(field, props.selectedPost[field]));
            setValue('date', moment(props.selectedPost['date']).toDate());
            setMainImage(props.selectedPost.mainImage);
            setImages(props.selectedPost.images);
            if (props.selectedPost.htmlContent) {
                const html = props.selectedPost.htmlContent
                const contentBlock = htmlToDraft(html);
                if (contentBlock) {
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    const outputEditorState = EditorState.createWithContent(contentState);
                    setEditorState(outputEditorState)
                }
            } else {
                setEditorState(EditorState.createEmpty())
            }
            if (props.selectedPost.selectedCategory) {
                setSelectedCategoryPlaceholder(props.selectedPost.selectedCategory.name)
            } else {
                setSelectedCategoryPlaceholder("Select")
            }
        } else {
            setFileMainImage("")
            setUploadedImages([])
            fields.forEach(field => setValue(field, InitialPostState[field]));
            setMainImage("");
            setImages([]);
            setEditorState(EditorState.createEmpty())
        }
    }, [props.selectedPost])

    const handleImageUpload = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const storageRef = ref(storage, `/Announcements/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);
                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                    },
                    (error) => {
                        console.log('Error uploading image:', error);
                        reject(error);
                    },
                    () => {
                        // Image uploaded successfully, get the download URL
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            // Set default height and width values here
                            resolve({
                                data: {
                                    link: url,
                                },
                            });
                        });
                    }
                );
            } catch (error) {
                console.log('Error uploading image:', error);
                reject(error);
            }
        });
    };

    const removeFontFamily = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');

        const removeFontFamilyFromElement = (element) => {
            if (element.nodeType === 1 && element.hasAttribute('style')) {
                element.style.cssText = element.style.cssText.replace(/font-family[^;]+;?/g, '');
            }

            for (let i = 0; i < element.childNodes.length; i++) {
                removeFontFamilyFromElement(element.childNodes[i]);
            }
        };

        removeFontFamilyFromElement(doc.body);

        // Serialize the modified HTML back to string
        const modifiedHtml = new XMLSerializer().serializeToString(doc.body);

        return modifiedHtml;
    };
    const customToolbar = {
        options: ['inline', 'blockType', 'fontSize', 'list', 'colorPicker', 'link', 'image'],
        image: {
            uploadCallback: handleImageUpload,
            alt: { present: true, mandatory: false },
        },
    };

    return (
        <DrawerOverlay
            size={"medium"}
            position={"right"}
            open={props.isOpen}
            style={{ width: "504px" }}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader
            style={{
                background: "#F7F7F7",
                padding: "26px 32px"
            }}
        >
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    + {props.selectedPost ? "Edit" : "New"} Announcement
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody >
                <form onSubmit={handleSubmit(onSubmit)}
                    className={styles.root} onReset={reset} id={props.selectedPost ? props.selectedPost.id : "New"}>
                    <Label className={"side-drawer-field__label"} style={{ marginTop: 32 }}>
                        Header Text*
                    </Label>
                    <Controller
                        name="headerText"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <input placeholder={"Enter Header Text"} className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
                    />
                    <Label className={"side-drawer-field__label"}>
                        Preview Text*
                    </Label>
                    <Controller
                        name="previewText"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Textarea placeholder={"Enter Preview Text"} className={"side-drawer-field-Textarea side-drawer-field__gap side-drawer-field__gap"} style={{ borderRadius: "16px !important", marginTop: 8 }} {...field} resize="vertical" />}
                    />
                    <Label className={"side-drawer-field__label"} style={{ marginTop: 24, marginBottom: 8, }}>
                        Full Text
                    </Label>
                    {/* <Controller
                        name="fullText"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Textarea {...field} resize="vertical" />}
                    /> */}
                    <Editor
                        editorState={outputEditorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="editor-container"
                        placeholder={"Enter Full Text"}
                        onEditorStateChange={handleEditorStateChange}
                        toolbar={customToolbar}
                        style={{ borderRadius: "16px", marginTop: 8 }}
                        className={"side-drawer-field-default  side-drawer-field__gap side-drawer-field__gap"}
                    // editorStyle={{ lineHeight: '90%' }}
                    />
                    <Label className={"side-drawer-field__label"} style={{ marginTop: 32 }}>
                        Categories
                    </Label>
                    <Controller
                        name="categoriesId"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <Dropdown multiselect={false}
                            className={"side-drawer-field-dropdown  side-drawer-field__gap"}
                            placeholder={selectedCategoryPlaceholder}
                            onOptionSelect={(ev, data) => field.onChange(data.selectedOptions[0])}
                            selectedOptions={[field.value]}>
                            {categoriesList.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {option.name}
                                </Option>
                            ))}
                        </Dropdown>}
                    />
                    <Label className={"side-drawer-field__label"} style={{ marginTop: 24 }}>
                        Date
                    </Label>
                    <Controller
                        name="date"
                        control={control}
                        render={({ field }) => <div className={"datepicker-custom-wrap"}><DatePicker
                            allowTextInput
                            value={field.value}
                            onSelectDate={field.onChange}
                            contentAfter={<DateSVG />}
                            style={{
                                width: "100%",
                                height: "44px"
                            }}
                            className={"side-drawer-field-date  side-drawer-field__gap side-drawer-field__gap"}
                            placeholder="Select Date"
                            {...field}
                        /></div>}
                    />
                    <Label className={"side-drawer-field__label"} style={{ marginBottom: "8px" }}> Main Image</Label>
                    {mainImage &&
                        <div>
                            <Image width={100} height={100} src={mainImage} alt="Main Image"></Image>
                            <Button icon={<DeleteIcon />} onClick={removeMainImage} />
                        </div>}
                    {!mainImage &&
                        <label className="fileCtrl">
                            <input type="file" id="file1" onChange={handleChangeMainImage} accept="image/*" className="inputFileCtrl" aria-label="File browser example" placeholder="Browse" />
                        </label>
                    }
                    <Label className={"side-drawer-field__label"} style={{ marginTop: 24, marginBottom: 8 }}> Images</Label>
                    <div>
                        {images && images.length > 0 && images.map((img, index) => <>
                            <Image width={100} height={100} src={img} alt="Main Image"></Image>
                            <Button icon={<DeleteIcon />} onClick={() => removeImages(index)} />
                        </>)}
                    </div>
                    {
                        <label className="fileCtrlMultiple">
                            <input type="file" id="file2" onChange={handleChangeImages} accept="image/*, .heic" className="inputFileCtrl" aria-label="File browser example" multiple />
                        </label>
                    }
                    {
                        isLoading ?
                            <Spinner label="Loading..." /> :
                            <div className="btnActions" >
                                <button appearance="primary" type="submit" className="form-submit-btn pointer">
                                    {props.selectedPost ? 'Update' : 'Submit'}</button>
                                <button className="form-submit-cancel pointer"
                                    onClick={() => props.setIsOpen(false)}>Cancel</button>
                            </div>
                    }

                </form>
            </DrawerBody>
        </DrawerOverlay>
    )
}

export default PostForm
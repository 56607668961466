import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, getDocs,startAfter,query,limit, orderBy } from "firebase/firestore";
import { db } from "./firebase";

const postCollection = collection(db, "Posts");

class PostDataService {
    addPost = (newPost) => {
        return addDoc(postCollection, newPost);
    }

    updatePost = (id, updatedPost) => {
        const postDoc = doc(db, "Posts", id);
        return updateDoc(postDoc, updatedPost);
    }

    deletePost = (id) => {
        const postDoc = doc(db, "Posts", id);
        return deleteDoc(postDoc);
    }

    getAllPost = () => {
        return getDocs(postCollection)
    }

    getAllPostsWithPage = async (pageSize, startAfterdt) => {
        let q = query(postCollection, orderBy("dateUTC", 'desc')); // Initialize query
        if (startAfterdt) {
            q = query(q, startAfter(startAfterdt)); // Corrected: Use query to chain methods
        }
        if (pageSize) {
            q = query(q, limit(pageSize)); // Corrected: Use query to chain methods
        }
        const snapshot = await getDocs(q);
        const events = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        console.log(events)
        const lastVisible = snapshot.docs[snapshot.docs.length - 1];
        return { events, lastVisible };
    };

    getPost = (id) => {
        const postDoc = doc(db, "Posts", id);
        return getDoc(postDoc);
    }
}

export default new PostDataService();
import React, { useEffect, useState } from "react";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { Dismiss24Regular } from "@fluentui/react-icons";
import {
    Button, Image, Input, Label, makeStyles, shorthands, Textarea, Dropdown, Option, Divider
} from "@fluentui/react-components";
import { useForm, Controller } from "react-hook-form";
import { storage } from "../../services/firebase";
import { RocketRegular, CalendarCancelFilled } from "@fluentui/react-icons";
import {
    ref,
    uploadBytesResumable,
    getDownloadURL
} from "firebase/storage";
import moment from "moment";
import { bundleIcon, DeleteFilled, DeleteRegular } from "@fluentui/react-icons";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import AttractionDataService from "../../services/attractionsDataService";
import {AddressSVG} from "../../static/svgs";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialAttractionState = {
    "rentalLocationId": "",
    "name": "",
    "description": "",
    "id": "",
    "images": [],
    "address": {
        "street1": "",
        "state": "",
        "country": "",
        "city": "",
        "street2": "",
        "zip": ""
    }
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

function AttractionForm(props) {
    const styles = useStyles();
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedAttraction ? props.selectedAttraction : InitialAttractionState
    });
    const [images, setImages] = useState(props.selectedAttraction ? props.selectedAttraction.images : []);
    const [uploadedImages, setUploadedImages] = useState([])
    const [percent, setPercent] = useState(0);

    const onSubmit = async formdata => {
        var data = formdata;
        data.images = images;
        var id = "";
        if (props.selectedAttraction) {
            id = props.selectedAttraction.id;
            await AttractionDataService.updateAttraction(props.selectedAttraction.id, data);
        } else {
            const addeddata = await AttractionDataService.addAttraction(data);
            id = addeddata.id;
        }
        if (uploadedImages.length > 0) {
            uploadedImages.map((image) => {
                const storageRef = ref(storage, `/attractions/${image.name}`);
                const uploadTask = uploadBytesResumable(storageRef, image);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );

                        // update progress
                        setPercent(percent);
                    },
                    (err) => console.log(err),
                    () => {
                        // download url
                        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                            console.log(url);
                            data.images.push(url);
                            await AttractionDataService.updateAttraction(id, data);
                            props.setIsOpen(false);
                        });
                    }
                );
            })
        }
        props.setIsOpen(false);
        props.updateGrid();
    }

    const handleChangeImages = (event) => {
        const choosenFile = Array.prototype.slice.call(event.target.files);
        const uploaded = [...uploadedImages];
        const tempimages = [...images];
        choosenFile.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                tempimages.push(URL.createObjectURL(event.target.files[0]));
                uploaded.push(file);
            }
        });
        setImages(tempimages);
        setUploadedImages(uploaded);
    }

    const removeImages = (index) => {
        var tempuploadedImages = [...uploadedImages];
        tempuploadedImages.splice(index, 1);
        setUploadedImages(tempuploadedImages);
        var tempimages = [...images];
        tempimages.splice(index, 1);
        setImages(tempimages);
    }

    useEffect(() => {
        const fields = ['rentalLocationId', 'name', 'description', 'id'];
        if (props.selectedAttraction) {
            fields.forEach(field => setValue(field, props.selectedAttraction[field]));
            setImages(props.selectedAttraction.images);
            setValue('address.city', props.selectedAttraction.address['city']);
            setValue('address.country', props.selectedAttraction.address['country']);
            setValue('address.state', props.selectedAttraction.address['state']);
            setValue('address.street1', props.selectedAttraction.address['street1']);
            setValue('address.street2', props.selectedAttraction.address['street2']);
            setValue('address.zip', props.selectedAttraction.address['zip']);
        } else {
            fields.forEach(field => setValue(field, InitialAttractionState[field]));
            setImages([]);
            setValue('address.city', InitialAttractionState.address['city']);
            setValue('address.country', InitialAttractionState.address['country']);
            setValue('address.state', InitialAttractionState.address['state']);
            setValue('address.street1', InitialAttractionState.address['street1']);
            setValue('address.street2', InitialAttractionState.address['street2']);
            setValue('address.zip', InitialAttractionState.address['zip']);
        }
    }, [props.selectedAttraction])

    return (
        <DrawerOverlay
            size={"medium"}
            position={"right"}
            style={{ width: "504px" }}
            open={props.isOpen}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader style={{
            background: "#F7F7F7",
            padding: "26px 32px"
        }}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    + {props.selectedAttraction ? "Edit" : "New"} Attraction
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <form onSubmit={handleSubmit(onSubmit)}
                    className={styles.root} onReset={reset} id={props.selectedAttraction ? props.selectedAttraction.id : "New"}>
                    <Label className={"side-drawer-field__label"} style={{marginTop:32}}>
                        Name*
                    </Label>
                    <Controller
                        name="name"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Input
                            placeholder={"Enter Name"}
                            className={"side-drawer-field  side-drawer-field__gap"}


                            {...field} />}
                    />
                    <Label className={"side-drawer-field__label"} style={{marginBottom:8}}>
                        Description*
                    </Label>
                    <Controller
                        name="description"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Textarea
                            placeholder={"Enter Description"}
                            className={"side-drawer-field-Textarea  side-drawer-field__gap"}


                            {...field} resize="vertical" />}
                    />
                    <Label className={"side-drawer-field__label"} style={{marginTop:24}}>
                        Rental Location*
                    </Label>
                    <Controller
                        name="rentalLocationId"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Dropdown


                            placeholder={"Select Rental Location "}
                            className={"side-drawer-field-dropdown  side-drawer-field__gap"}

                            multiselect={false}
                            onOptionSelect={(ev, data) => field.onChange(data.selectedOptions[0])}
                            selectedOptions={[field.value]}>
                            {props.locations.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {option.location}
                                </Option>
                            ))}
                        </Dropdown>}
                    />
                    <Divider className={"side-drawer-divider-gap"} />

                    <div className={"d-flex"} style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}>
                        <div><AddressSVG /></div>
                        <Label className={"side-drawer-section__typo"} style={{ marginLeft: "8px" }}>ADDRESS</Label>
                    </div>

                    <Label className={"side-drawer-field__label"}>
                        Street 1
                    </Label>
                    <Controller
                        name="address.street1"
                        key="address.street1"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input
                            placeholder={"Enter Street 1"}
                            className={"side-drawer-field  side-drawer-field__gap"} {...field} />}
                    />
                    <Label className={"side-drawer-field__label"}>
                        Street 2
                    </Label>
                    <Controller
                        name="address.street2"
                        key="address.street2"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input
                            placeholder={"Enter Street 2"}
                            className={"side-drawer-field  side-drawer-field__gap"} {...field} />}
                    />

                    <div className={"d-flex justify-space-between"}>
                        <div style={{ display: "grid",width:"49%"}}>
                            <Label className={"side-drawer-field__label"}>City</Label>
                            <Controller
                                name="address.city"
                                key="address.city"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter City"}
                                    className={"side-drawer-field side-drawer-field__gap"} {...field} />}
                            />
                        </div>
                        <div style={{ display: "grid",width:"49%" }}>
                            <Label className={"side-drawer-field__label"}>State</Label>
                            <Controller
                                name="address.state"
                                key="address.state"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter State"}
                                    className={"side-drawer-field side-drawer-field__gap"} {...field} />}
                            />
                        </div>
                    </div>
                    <div className={"d-flex justify-space-between"}>
                        <div style={{ display: "grid", width:"49%" }}>
                            <Label className={"side-drawer-field__label"}>Country</Label>
                            <Controller
                                name="address.country"
                                key="address.country"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter Country"}
                                    className={"side-drawer-field side-drawer-field__gap"} {...field} />}
                            />
                        </div>
                        <div style={{ display: "grid" ,width:"49%"}}>
                            <Label className={"side-drawer-field__label"}>Zip</Label>
                            <Controller
                                name="address.zip"
                                key="address.zip"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter Zip"}
                                    className={"side-drawer-field side-drawer-field__gap"} {...field} />}
                            />
                        </div>
                    </div>
                    <Label className={"side-drawer-field__label"} style={{marginBottom:8}}> Images</Label>
                    <div>
                        {images && images.length > 0 && images.map((img, index) => <>
                            <Image width={100} height={100} src={img} alt="Main Image"></Image>
                            <Button icon={<DeleteIcon />} onClick={() => removeImages(index)} />
                        </>)}
                    </div>
                    {
                        <label className="fileCtrlMultiple">
                            <input type="file" id="file2" onChange={handleChangeImages} accept="image/*" className="inputFileCtrl" aria-label="File browser example" multiple />
                        </label>
                    }
                    <div className="btnActions">
                        <button appearance="primary" type="submit" className="form-submit-btn">
                            {props.selectedAttraction ? 'Update' : 'Submit'}</button>
                        <button className="form-submit-cancel"
                            onClick={() => props.setIsOpen(false)}>Cancel</button>
                    </div>
                </form>
            </DrawerBody>
        </DrawerOverlay>
    )
}

export default AttractionForm
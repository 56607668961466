import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase";

const notificationCollection = collection(db, "Notifications");
const mailCollection = collection(db, "mail");

class NotificationDataService {
    addnotification = (newnotification) => {
        return addDoc(notificationCollection, newnotification);
    }

    updatenotification = (id, updatednotification) => {
        const notificationDoc = doc(db, "Notifications", id);
        return updateDoc(notificationDoc, updatednotification);
    }

    deletenotification = (id) => {
        const notificationDoc = doc(db, "Notifications", id);
        return deleteDoc(notificationDoc);
    }

    getAllnotification = () => {
        return getDocs(notificationCollection)
    }

    getnotification = (id) => {
        const notificationDoc = doc(db, "Notifications", id);
        return getDoc(notificationDoc);
    }

    addEmail = (mailData) => {
        return addDoc(mailCollection, mailData);
    }

}

export default new NotificationDataService();
import {
    Button, Table, TableBody,
    TableCell,
    TableCellActions,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands, Spinner
} from "@fluentui/react-components";
import { DeleteFilled, DeleteRegular, EditFilled, EditRegular, bundleIcon } from "@fluentui/react-icons";
import { collection, getDocs, query, where } from "firebase/firestore";
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import CustomSearchInput from "./components/CustomSearchInput";
import DeleteEventDialog from "./components/DeleteEventDialog";
import PostForm from "./components/Forms/PostForm";
import ImageDialog from "./components/ImageDialog";
import Layout from "./components/Layout";
import { auth, db, trackException } from "./services/firebase";
import PostDataService from "./services/postDataService";
import { PlusSVG } from "./static/svgs";

const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "headerText", label: "Header Text" },
    { columnKey: "previewText", label: "Preview Text" },
    { columnKey: "date", label: "Date" },
    { columnKey: "images", label: "Images" }
];

function Posts() {
    const styles = useStyles();
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedPost, setSelectedPost] = useState();
    const [allposts, setAllPosts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50); // Number of events per page
    const [startAfterDoc, setStartAfterDoc] = useState(null); // Track the last document of the previous page
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        setIsLoading(true);
        fetchUserNameAndPosts();
    }, [user, loading]);
    useEffect(() => {
        if (page > 1) {
            loadMorePosts();
        }
    }, [page]);

    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedPost(selectedItem);
    }

    const fetchUserNameAndPosts = async () => {
        setPage(1)
        setStartAfterDoc(null)
        setHasMoreData(true)
        try {
            if (!!user) {
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                if (userdoc.docs.length > 0) {
                    //const postsQuery = await PostDataService.getAllPost();
                    //const allPostArr = postsQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                    const { events, lastVisible } = await PostDataService.getAllPostsWithPage(pageSize, null);
                    // Update the startAfterDoc for the next page
                    setStartAfterDoc(lastVisible);
                    let allEventArr = events
                    setAllPosts(allEventArr);
                    setFilteredPosts(allEventArr)
                    if (allEventArr.length < pageSize) {
                        setHasMoreData(false)
                    }
                    //console.log(allevents);
                } else {
                    console.log('no access');
                }
                setIsLoading(false);
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            setIsLoading(false);
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    const loadMorePosts = async () => {
        const { events, lastVisible } = await PostDataService.getAllPostsWithPage(pageSize, startAfterDoc);
        // Update the startAfterDoc for the next page
        setStartAfterDoc(lastVisible);
        if (events.length < pageSize) {
            setHasMoreData(false)
            if (events.length > 0) {
                setAllPosts(prevEvents => [...prevEvents, ...events]);
                setFilteredPosts(prevEvents => [...prevEvents, ...events]);
            }
            // You can handle this case, e.g., display a message
        } else {
            // Append the new events to the existing events
            setAllPosts(prevEvents => [...prevEvents, ...events]);
            setFilteredPosts(prevEvents => [...prevEvents, ...events]);
        }
    };


    const openDeleteForm = (selectedItem) => {
        setIsDeleteDialogOpen(true);
        setSelectedPost(selectedItem);
        // fetchUserNameAndEvents();
    }

    const DeletePost = async () => {
        PostDataService.deletePost(selectedPost.id);
        setAllPosts([])
        setFilteredPosts([])
        setIsDeleteDialogOpen(false);
        fetchUserNameAndPosts();
    };

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allposts.filter(event =>
            event.headerText.toLowerCase().includes(lowerSearch)
        );
        setFilteredPosts(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };
    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    return (
        <Layout>
            <div className={styles.content}>
                <div className={styles.breakFlex}>
                </div>
                <div className={"d-flex justify-space-between"}>
                    <Button icon={<PlusSVG />} className={"primary-btn"} shape="circular" appearance="primary" onClick={() => openForm(undefined)}>{'New Announcement'.toUpperCase()}</Button>
                    <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    />
                </div>
                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredPosts.length > 0 && filteredPosts.map((item) =>
                                <TableRow key={item.id}>
                                    <TableCell className={"table-row-fonts__primary"}>
                                        <TableCellLayout>
                                            {item.headerText}
                                        </TableCellLayout>
                                    </TableCell>
                                    {/* <TableCell>
                                        <TableCellLayout>
                                            <p key={item.id} dangerouslySetInnerHTML={{ __html: makeLinksClickable(item.fullText.substring(0, 150)) }}></p>
                                        </TableCellLayout>
                                    </TableCell> */}
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <p key={item.id} dangerouslySetInnerHTML={{ __html: makeLinksClickable(item.previewText.substring(0, 150)) }}></p>
                                            {/* <Label title={item.previewText}>{item.previewText.substring(0, 150)}</Label> */}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>{moment(item.date).format('Do MMMM YYYY hh mm A')}
                                    </TableCell>
                                    <TableCell>
                                        <Button className={"btnBorderOnly"} shape="circular" onClick={() => {
                                            setOpen(true);
                                            setSelectedPost(item);
                                        }}>Images</Button>
                                        <TableCellActions>
                                            <Button
                                                icon={<EditIcon />}
                                                appearance="subtle"
                                                aria-label="Edit"
                                                onClick={() => openForm(item)}
                                            />
                                            <Button
                                                icon={<DeleteIcon />}
                                                appearance="subtle"
                                                aria-label="Delte"
                                                onClick={() => openDeleteForm(item)}
                                            />
                                        </TableCellActions>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    {isLoading && <Spinner />} {/* Render loader if loading state is true */}
                    {!isLoading && hasMoreData && searchTerm == "" && <Button onClick={handleLoadMore}>Load More...</Button>} {/* Render button if not loading */}
                </div>
            </div>
            <PostForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)} selectedPost={selectedPost}
                updateGrid={fetchUserNameAndPosts}></PostForm>
            <ImageDialog open={open} setOpen={setOpen} selectedEvent={selectedPost}></ImageDialog>
            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeletePost} text={'Post'} />
        </Layout>
    )
}

export default Posts;


function makeLinksClickable(text) {
    return text.replace(/(https?:\/\/\S+)/g, '<a href="$1" target="_blank">$1</a>');
}
import {
    Button,
    Label,
    Table, TableBody,
    TableCell,
    TableCellActions,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands
} from "@fluentui/react-components";
import { DeleteFilled, DeleteRegular, EditFilled, EditRegular, bundleIcon } from "@fluentui/react-icons";
import { collection, getDocs, query, where } from "firebase/firestore";
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import DeleteEventDialog from "./components/DeleteEventDialog";
import VehicleServiceTypeForm from "./components/Forms/VehicleServiceTypeForm";
import Layout from "./components/Layout";
import ServiceProviderDataService from "./services/ServiceProviderDataService";
import VehicleServiceTypeDataService from "./services/VehicleServiceTypeDataService";
import { auth, db, trackException } from "./services/firebase";
import CustomSearchInput from "./components/CustomSearchInput";
import { PlusSVG } from "./static/svgs";
const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "type", label: "Type", width: "30%" },
    { columnKey: "description", label: "Description", width: "50%" },
    { columnKey: "", label: "", width: "20%" },
];

function VehicleServiceType() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedVehicleServiceType, setSelectedVehicleServiceType] = useState();
    const [allvehicleServiceTypes, setAllVehicleServiceType] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredServiceType, setFilteredServiceType] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [options, setOptions] = useState([]);
    const navigate = useNavigate();
    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedVehicleServiceType(selectedItem);
    }

    const fetchUserNameAndVehicleServiceType = async () => {
        try {
            if (!!user) {
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                if (userdoc.docs.length > 0) {
                    const vehicleServiceTypesQuery = await VehicleServiceTypeDataService.getAllVehicleServiceType();
                    let allServiceType = vehicleServiceTypesQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                    setAllVehicleServiceType(allServiceType);
                    setFilteredServiceType(allServiceType)
                    //console.log(allevents);
                } else {
                    console.log('no access');
                }
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    const fetchOptions = async () => {
        try {
            const serviceProvidersQuery = await ServiceProviderDataService.getAllServiceProvider();
            // console.log(serviceProvidersQuery);
            setOptions(serviceProvidersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        } catch (error) {
            trackException(JSON.stringify(error))
            console.error(error);
        }
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        fetchUserNameAndVehicleServiceType();
        fetchOptions();
    }, [user, loading]);

    const openDeleteForm = (selectedItem) => {
        setIsDeleteDialogOpen(true);
        setSelectedVehicleServiceType(selectedItem);
        // fetchUserNameAndEvents();
    }

    const DeleteVehicleServiceType = async () => {
        VehicleServiceTypeDataService.deleteVehicleServiceType(selectedVehicleServiceType.id);
        setIsDeleteDialogOpen(false);
        fetchUserNameAndVehicleServiceType();
    };

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allvehicleServiceTypes.filter(event =>
            event.type.toLowerCase().includes(lowerSearch)
        );
        setFilteredServiceType(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };

    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <Button icon={<PlusSVG />} className={"primary-btn"} shape="circular" appearance="primary" onClick={() => openForm(undefined)}>{'New Vehicle Service Type'.toUpperCase()}</Button>
                    <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    />
                </div>
                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow className={""}>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey} style={{ minWidth: column.width }}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredServiceType.length > 0 && filteredServiceType.map((item) =>
                                <TableRow key={item.id}>
                                    <TableCell className={"table-row-fonts__primary"}>
                                        <TableCellLayout>
                                            <Label title={item.type}>{item.type}</Label>
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            {item.description}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <TableCellActions>
                                                <Button
                                                    icon={<EditIcon />}
                                                    appearance="subtle"
                                                    aria-label="Edit"
                                                    onClick={() => openForm(item)}
                                                />
                                                <Button
                                                    icon={<DeleteIcon />}
                                                    appearance="subtle"
                                                    aria-label="Delte"
                                                    onClick={() => openDeleteForm(item)}
                                                />
                                            </TableCellActions>
                                        </TableCellLayout>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <VehicleServiceTypeForm isOpen={isFormOpen}
                setIsOpen={(val) => setIsFormOpen(val)}
                selectedVehicleServiceType={selectedVehicleServiceType}
                options={options} updateGrid={fetchUserNameAndVehicleServiceType}></VehicleServiceTypeForm>
            {/* <ImageDialog open={open} setOpen={setOpen} selectedEvent={selectedVehicleServiceType}></ImageDialog> */}
            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeleteVehicleServiceType} text={'VehicleServiceType'} />
        </Layout>
    )
}

export default VehicleServiceType;
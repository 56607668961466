import {
    Button,
    Label,
    Table, TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands
} from "@fluentui/react-components";
import { DeleteFilled, DeleteRegular, EditFilled, EditRegular, bundleIcon } from "@fluentui/react-icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import { Base_Url, auth, trackException } from "./services/firebase";
// import ImageDialog from "./components/ImageDialog";
import debounce from 'lodash/debounce';
import CustomSearchInput from "./components/CustomSearchInput";
import DeleteEventDialog from "./components/DeleteEventDialog";
import KeyCafeKeyForm from "./components/Forms/KeyCafeKeyForm";
import { PlusSVG } from "./static/svgs";
const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "id", label: "Key Id" },
    { columnKey: "name", label: "Name" },
    { columnKey: "description", label: "Description" },
    { columnKey: "serialNumber", label: "FOB#" },
];

let hasMoreData = true;
let pageNum = 1;

function KeyCafeKeys() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedKeyItem, setSelectedKeyItem] = useState();
    const [allKeys, setAllKeys] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredKeys, setFilteredKeys] = useState([]);
    const [hasNextPage, setHaseNextPage] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const navigate = useNavigate();
    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedKeyItem(selectedItem);
    }

    const fetchAllFobKeyList = async () => {
        if (hasMoreData == false) {
            return;
        }
        let config = {
            method: 'get',
            url: `${Base_Url}/api/getKeysPage?page=${pageNum}`,
            //url: `http://127.0.0.1:5001/warehouse-dev-7dcd6/us-central1/api/getKeysPage?page=${pageNum}`,
            headers: {}
        };
        axios.request(config)
            .then((response) => {
                if (response && response.data && response.data.data) {
                    if (pageNum != 1) {
                        if (response.data.data.length > 0) {
                            let allKeysArr = [...allKeys, ...response.data.data]
                            setFilteredKeys(allKeysArr)
                            setAllKeys(allKeysArr)
                        }
                    } else {
                        let allKeysArr = response.data.data
                        setAllKeys(allKeysArr)
                        setFilteredKeys(allKeysArr)
                    }
                    pageNum = pageNum + 1
                    if (response.data.data.length > 0) {
                    } else {
                        setHaseNextPage(false)
                        hasMoreData = false
                    }
                }
            })
            .catch((error) => {
                setHaseNextPage(false)
                hasMoreData = false
                console.log(error);
                trackException(JSON.stringify(error))
            });
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        resetAndFeatch()
    }, [user, loading]);

    const resetAndFeatch = () => {
        setHaseNextPage(true)
        hasMoreData = true;
        pageNum = 1;
        fetchAllFobKeyList();
    }
    const openDeleteForm = (selectedItem) => {
        setIsDeleteDialogOpen(true);
        setSelectedKeyItem(selectedItem);
        // fetchUserNameAndEvents();
    }

    const DeleteRoom = async () => {
        //BookingDataService.deleteBooking(selectedKeyItem.id);
        setIsDeleteDialogOpen(false);
        resetAndFeatch()
    };

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allKeys.filter(event => {
            return (
                (event.name && event.name.toLowerCase().includes(lowerSearch)) ||
                (event.serialNumber && event?.serialNumber.toLowerCase().includes(lowerSearch))
            )
        });
        setFilteredKeys(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };

    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    {/* <Button icon={<PlusSVG />} className={"primary-btn"} shape="circular" appearance="primary" onClick={() => openForm(undefined)}>{'Add Key'.toUpperCase()}</Button> */}
                    <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    />
                </div>
                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredKeys.length > 0 && filteredKeys.map((item) =>
                                <TableRow key={item.id}>
                                    <TableCell className={"table-row-fonts__primary"}>
                                        <TableCellLayout>
                                            {item?.id}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            {item?.name}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            {item?.description}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <Label>{item?.serialNumber}</Label>
                                        {/* <TableCellActions>
                                            <Button
                                                icon={<EditIcon />}
                                                appearance="subtle"
                                                aria-label="Edit"
                                                onClick={() => openForm(item)}
                                            />
                                            <Button
                                                icon={<DeleteIcon />}
                                                appearance="subtle"
                                                aria-label="Delte"
                                                onClick={() => openDeleteForm(item)}
                                            />
                                        </TableCellActions> */}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                {
                    hasNextPage == true && searchTerm == "" ?
                        <div className={styles.breakFlex}>
                            <Button shape="circular" appearance="primary" onClick={() => fetchAllFobKeyList()}>Load More...</Button>
                        </div> : <div />
                }
            </div>
            <KeyCafeKeyForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)} selectedKey={selectedKeyItem}
                updateGrid={resetAndFeatch} />
            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeleteRoom} text={'Key'} />
        </Layout>
    )
}

export default KeyCafeKeys;
import {
    Button, Label, Table, TableBody,
    TableCell,
    TableCellActions,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands
} from "@fluentui/react-components";
import { DeleteFilled, DeleteRegular, EditFilled, EditRegular, bundleIcon } from "@fluentui/react-icons";
import { collection, getDocs, query, where } from "firebase/firestore";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import DeleteEventDialog from "./components/DeleteEventDialog";
import RentalForm from "./components/Forms/RentalForm";
import Layout from "./components/Layout";
import { auth, db, trackException } from "./services/firebase";
import RentalDataService from "./services/rentalDataService";
import UserDataService from "./services/userDataService";
import VehicleDataService from "./services/vehicleDataService";
import debounce from 'lodash/debounce';
import CustomSearchInput from "./components/CustomSearchInput";
import { PlusSVG } from "./static/svgs";

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const columns = [
    { columnKey: "vehicle", label: "Vehicle" },
    { columnKey: "startDate", label: "Start Date" },
    { columnKey: "endDate", label: "End Date" },
    { columnKey: "createdBy", label: "Created By" },
];

function Rental() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [allRentals, setAllRentals] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredAllRentals, setFilteredAllRentals] = useState([]);
    const [rentalDateArr, setRentalDateArr] = useState([]);
    const [allRentalFeetVehicle, setAllRentalFeetVehicle] = useState([]);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedRental, setSelectedRental] = useState();
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [allusers, setAllUsers] = useState([]);
    const [currentUserData, setCurrentUser] = useState({});

    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedRental(selectedItem);
    }
    const navigate = useNavigate();

    const fetchAllUsers = async () => {
        const usersQuery = await UserDataService.getAllUser();
        setAllUsers(usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    }
    const fetchUserNameAndRental = async () => {
        try {
            if (!!user) {
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                if (userdoc.docs.length > 0) {
                    const currentuser = userdoc.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    setCurrentUser(currentuser[0]);

                    const rentalVehicleQuery = await VehicleDataService.getRentalFleetVehicle();
                    setAllRentalFeetVehicle(rentalVehicleQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

                    const eventQuery = await RentalDataService.getAllRental();
                    let originalArray = eventQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                    setAllRentals(originalArray);
                    setFilteredAllRentals(originalArray)

                    const newArray = originalArray.map(obj => {
                        return {
                            startDate: obj.startDate,
                            endDate: obj.endDate,
                            id: obj.id,
                            vehicleId: obj?.vehicle?.id,
                        };
                    });
                    setRentalDateArr(newArray)
                    //console.log("newArray->",newArray)
                } else {
                    console.log('no access');
                }
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };
    const openDeleteForm = (selectedItem) => {
        setIsDeleteDialogOpen(true);
        setSelectedRental(selectedItem);
    }
    const DeleteRental = async () => {
        RentalDataService.deleteRental(selectedRental.id);
        setIsDeleteDialogOpen(false);
        fetchUserNameAndRental();
    };

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        fetchAllUsers();
        fetchUserNameAndRental();
    }, [user, loading]);

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allRentals.filter(event => {
            return (
                (event.vehicle && event.vehicle.year.toLowerCase().includes(lowerSearch)) ||
                (event.vehicle && event.vehicle.model.toLowerCase().includes(lowerSearch)) ||
                (event.vehicle && event.vehicle.make.toLowerCase().includes(lowerSearch))
            )
        });
        setFilteredAllRentals(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };

    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <Button icon={<PlusSVG />} className={"primary-btn"} shape="circular" appearance="primary" onClick={() => openForm(undefined)}>{'New Rental'.toUpperCase()}</Button>
                    <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    />
                </div>
                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredAllRentals.length > 0 && filteredAllRentals.map((item) =>
                                <TableRow key={item.id}>
                                    <TableCell className={"table-row-fonts__primary"}>
                                        <TableCellLayout>
                                            {item.vehicle?.make} {item.vehicle?.model} {item.vehicle?.year}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>{moment(item.startDate).utc().format('Do MMMM YYYY hh mm A')}</TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>{moment(item.endDate).utc().format('Do MMMM YYYY hh mm A')}</TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label>{allusers.filter(t => t.id == item.userId)[0]?.firstName} &nbsp;
                                                {allusers.filter(t => t.id == item.userId)[0]?.lastName}</Label>
                                        </TableCellLayout>
                                        <TableCellActions>
                                            <Button
                                                icon={<EditIcon />}
                                                appearance="subtle"
                                                aria-label="Edit"
                                                onClick={() => openForm(item)}
                                            />
                                            <Button
                                                icon={<DeleteIcon />}
                                                appearance="subtle"
                                                aria-label="Delte"
                                                onClick={() => openDeleteForm(item)}
                                            />
                                        </TableCellActions>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <RentalForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)} vehicles={allRentalFeetVehicle} currentUserData={currentUserData} selectedRental={selectedRental}
                updateGrid={fetchUserNameAndRental} users={allusers} rentalDateArr={rentalDateArr}></RentalForm>
            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeleteRental} text={'Rental'} />
        </Layout>
    );
}
export default Rental;
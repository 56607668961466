import React from 'react';

const PolicyPDF = () => {
    return (
        <div>
            <iframe src="https://firebasestorage.googleapis.com/v0/b/warehouse-dev-7dcd6.appspot.com/o/WHPolicy.pdf?alt=media&token=38f74a07-de36-4d69-aec9-472b293e24f6" width="100%" height={getCurrentDimension().height} />
        </div>
    );
};
export default PolicyPDF;
function getCurrentDimension(){
    return {
        width: window.innerWidth,
      	height: window.innerHeight
    }
}
import {
    Button, Table, TableBody,
    TableCell,
    TableCellActions,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands
} from "@fluentui/react-components";
import { DeleteFilled, DeleteRegular, EditFilled, EditRegular, bundleIcon } from "@fluentui/react-icons";
import { collection, getDocs, query, where } from "firebase/firestore";
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import CustomSearchInput from "./components/CustomSearchInput";
import DeleteEventDialog from "./components/DeleteEventDialog";
import RoomAddEditForm from "./components/Forms/RoomAddEditForm";
import Layout from "./components/Layout";
import { auth, db, trackException } from "./services/firebase";
import roomsDataService from "./services/roomsDataService";
import { PlusSVG } from "./static/svgs";
import ImageDialog from "./components/ImageDialog";

const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "name", label: "Name" },
    { columnKey: "image", label: "Images" },
];

function RoomAddEdit() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState();
    const [allrooms, setAllRooms] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredRoom, setFilteredRoom] = useState([]);

    const [open, setOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const navigate = useNavigate();
    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedRoom(selectedItem);
    }

    const fetchUserNameAndRooms = async () => {
        try {
            if (!!user) {
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                if (userdoc.docs.length > 0) {
                    const roomsQuery = await roomsDataService.getAllRooms();
                    let roomsArr = roomsQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                    setAllRooms(roomsArr);
                    setFilteredRoom(roomsArr);
                } else {
                    console.log('no access');
                }
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };


    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        fetchUserNameAndRooms();
    }, [user, loading]);

    const openDeleteForm = (selectedItem) => {
        setIsDeleteDialogOpen(true);
        setSelectedRoom(selectedItem);
        // fetchUserNameAndEvents();
    }

    const DeleteRoom = async () => {
        roomsDataService.deleteRooms(selectedRoom.id);
        setIsDeleteDialogOpen(false);
        fetchUserNameAndRooms();
    };

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allrooms.filter(event => {
            return (
                event.name.toLowerCase().includes(lowerSearch)
            )
        });
        setFilteredRoom(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };

    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <Button icon={<PlusSVG />} className={"primary-btn"} shape="circular" appearance="primary" onClick={() => openForm(undefined)}>{'New Room'.toUpperCase()}</Button>
                    <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    />
                </div>
                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredRoom && filteredRoom.length > 0 && filteredRoom.map((item) =>
                                <TableRow key={item.id}>
                                    <TableCell className={"table-row-fonts__primary"}>
                                        <TableCellLayout>
                                            {item.name}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <Button className={"btnBorderOnly"} shape="circular" onClick={() => {
                                            setOpen(true);
                                            setSelectedRoom(item)
                                            // setSelectedAttraction(item);
                                        }}>Images</Button>
                                        <TableCellActions>
                                            <Button
                                                icon={<EditIcon />}
                                                appearance="subtle"
                                                aria-label="Edit"
                                                onClick={() => openForm(item)}
                                            />
                                            <Button
                                                icon={<DeleteIcon />}
                                                appearance="subtle"
                                                aria-label="Delte"
                                                onClick={() => openDeleteForm(item)}
                                            />
                                        </TableCellActions>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <RoomAddEditForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)} selectedRoom={selectedRoom}
                updateGrid={fetchUserNameAndRooms} ></RoomAddEditForm>
             <ImageDialog open={open} setOpen={setOpen} selectedEvent={selectedRoom}></ImageDialog>
            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeleteRoom} text={'Room'} />
        </Layout>
    )
}

export default RoomAddEdit;
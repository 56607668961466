import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase";

const groupCollection = collection(db, "Group");

class GroupDataService {
    addGroup = (newGroup) => {
        return addDoc(groupCollection, newGroup);
    }

    updateGroup = (id, updatedGroup) => {
        const groupDoc = doc(db, "Group", id);
        return updateDoc(groupDoc, updatedGroup);
    }

    deleteGroup = (id) => {
        const groupDoc = doc(db, "Group", id);
        return deleteDoc(groupDoc);
    }

    getAllGroup = () => {
        return getDocs(groupCollection)
    }

    getGroup = (id) => {
        const groupDoc = doc(db, "Group", id);
        return getDoc(groupDoc);
    }
}

export default new GroupDataService();
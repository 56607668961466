import React, { useEffect, useState } from "react";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { Dismiss24Regular } from "@fluentui/react-icons";
import {
    Button, Image, Input, Label, makeStyles, shorthands, Textarea, Dropdown, Option, Divider
} from "@fluentui/react-components";
import { useForm, Controller } from "react-hook-form";
import { RocketRegular, CalendarCancelFilled } from "@fluentui/react-icons";
import moment from "moment";
import { bundleIcon, DeleteFilled, DeleteRegular } from "@fluentui/react-icons";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import ServiceProviderDataService from "../../services/ServiceProviderDataService";
import { registerWithEmailAndPassword2 } from "../../services/firebase";
import userDataService from "../../services/userDataService";
import "./UserForm.css"
import {AddressSVG} from "../../static/svgs";
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialServiceProviderState = {
    "providerName": "",
    "providerDescription": "",
    "id": "",
    "email": "",
    "servicesOffered": [],
    "address": {
        "street1": "",
        "state": "",
        "country": "",
        "city": "",
        "street2": "",
        "zip": ""
    },
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

function ServiceProviderForm(props) {
    const styles = useStyles();
    const [password, setPassword] = useState("");

    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedServiceProvider ? props.selectedServiceProvider : InitialServiceProviderState
    });

    const onSubmit = async formdata => {
        var data = formdata;
        var id = "";
        if (props.selectedServiceProvider) {
            id = props.selectedServiceProvider.id;
            await ServiceProviderDataService.updateServiceProvider(props.selectedServiceProvider.id, data);
        } else {
            try {
                const addeduser = await registerWithEmailAndPassword2(data.firstName, data.email, password);
                data.id = addeduser.uid;
                id = addeduser.uid;
                const addeddata = await ServiceProviderDataService.addServiceProvider(data, addeduser.uid);
            } catch (error) {
                if (error.code === 'auth/email-already-in-use' || error.code == 'auth/email-already-exists') {
                    alert("Currently you are a Warehouse Member, please contact support if you want to convert your profile to a Service provider.")
                    //Email alredy use in service provider
                    // const userInfoProvider = await ServiceProviderDataService.getServiceProviderByEmail(data.email);
                    // if (userInfoProvider.docs.length > 0) {
                    //     //console.log("userInfoProvider->", userInfoProvider.docs[0].data())
                    //     alert(error)
                    // } else {
                    //     //Check email use alredy use for member?
                    //     const userInfo = await userDataService.getUserByEmail(data.email);
                    //     if (userInfo.docs.length > 0) {
                    //         let memberData = userInfo.docs[0].data();
                    //         data.id = memberData.id
                    //         const addeddata = await ServiceProviderDataService.addServiceProvider(data, memberData.id);
                    //     }
                    // }
                }
            }
        }
        props.setIsOpen(false);
        props.updateGrid();
    }

    useEffect(() => {
        const fields = ['providerName', 'providerDescription', 'servicesOffered', 'id', 'email'];
        if (props.selectedServiceProvider) {
            fields.forEach(field => setValue(field, props.selectedServiceProvider[field]));
            setValue('address.city', props.selectedServiceProvider.address['city']);
            setValue('address.country', props.selectedServiceProvider.address['country']);
            setValue('address.state', props.selectedServiceProvider.address['state']);
            setValue('address.street1', props.selectedServiceProvider.address['street1']);
            setValue('address.street2', props.selectedServiceProvider.address['street2']);
            setValue('address.zip', props.selectedServiceProvider.address['zip']);
        } else {
            fields.forEach(field => setValue(field, InitialServiceProviderState[field]));
            setValue('address.city', InitialServiceProviderState.address['city']);
            setValue('address.country', InitialServiceProviderState.address['country']);
            setValue('address.state', InitialServiceProviderState.address['state']);
            setValue('address.street1', InitialServiceProviderState.address['street1']);
            setValue('address.street2', InitialServiceProviderState.address['street2']);
            setValue('address.zip', InitialServiceProviderState.address['zip']);
        }
    }, [props.selectedServiceProvider])

    return (
        <DrawerOverlay
            size={"medium"}
            position={"right"}
            style={{ width: "504px" }}
            open={props.isOpen}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader style={{
            background: "#F7F7F7",
            padding: "26px 32px"
        }}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    + {props.selectedServiceProvider ? "Edit" : "New"} Service Provider
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <form onSubmit={handleSubmit(onSubmit)}
                    className={styles.root} onReset={reset} id={props.selectedServiceProvider ? props.selectedServiceProvider.id : "New"}>
                    <Label className={"side-drawer-field__label"} style={{marginTop:32}}>Email*</Label>
                    <Controller
                        name="email"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <input
                            placeholder={"Enter Email"}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                            {...field} disabled={!!props.selectedServiceProvider ? true : false} />}
                    />
                    <Label className={"side-drawer-field__label"}>Password*</Label>
                    <input
                        name={"password"}
                        placeholder={"Enter Password"}
                        className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                        value={password} onChange={(ev, newVal) => setPassword(newVal?.value)}
                        disabled={!!props.selectedServiceProvider ? true : false} type="password" />
                    <Label className={"side-drawer-field__label"} >
                        Provider Name*
                    </Label>
                    <Controller
                        name="providerName"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <input
                            placeholder={"Enter Provider Name"}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                            {...field} />}
                    />


                    <Label className={"side-drawer-field__label"} style={{marginBottom:8}}>
                        Provider Description*
                    </Label>
                    <Controller
                        name="providerDescription"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Textarea

                            placeholder={"Enter Provider Description"}
                            className={"side-drawer-field-Textarea  side-drawer-field__gap side-drawer-field__gap"}
                            {...field} resize="vertical" />}
                    />
                    <Label className={"side-drawer-field__label"} style={{marginTop:24}}>
                        Services Offered*
                    </Label>
                    <Controller
                        name="servicesOffered"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Dropdown

                            placeholder={"Select Service Offered"}
                            className={"side-drawer-field-dropdown  side-drawer-field__gap side-drawer-field__gap"}
                            multiselect={true}
                            onOptionSelect={(ev, data) => field.onChange(data.selectedOptions)}
                            selectedOptions={field.value}>
                            {props.options.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {option.type}
                                </Option>
                            ))}
                        </Dropdown>}
                    />
                    <Divider className={"side-drawer-divider-gap"} />

                    <div className={"d-flex"} style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}>
                        <div><AddressSVG /></div>
                        <Label className={"side-drawer-section__typo"} style={{ marginLeft: "8px" }}>ADDRESS</Label>
                    </div>

                    <Label className={"side-drawer-field__label"}>
                        Street 1
                    </Label>
                    <Controller
                        name="address.street1"
                        key="address.street1"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input
                            placeholder={"Enter Street 1"}
                            className={"side-drawer-field  side-drawer-field__gap"} {...field} />}
                    />
                    <Label className={"side-drawer-field__label"}>
                        Street 2
                    </Label>
                    <Controller
                        name="address.street2"
                        key="address.street2"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input
                            placeholder={"Enter Street 2"}
                            className={"side-drawer-field  side-drawer-field__gap"} {...field} />}
                    />

                    <div className={"d-flex justify-space-between"}>
                        <div style={{ display: "grid",width:"49%"}}>
                            <Label className={"side-drawer-field__label"}>City</Label>
                            <Controller
                                name="address.city"
                                key="address.city"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter City"}
                                    className={"side-drawer-field side-drawer-field__gap"} {...field} />}
                            />
                        </div>
                        <div style={{ display: "grid" ,width:"49%"}}>
                            <Label className={"side-drawer-field__label"}>State</Label>
                            <Controller
                                name="address.state"
                                key="address.state"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter State"}
                                    className={"side-drawer-field side-drawer-field__gap"} {...field} />}
                            />
                        </div>
                    </div>
                    <div className={"d-flex justify-space-between"}>
                        <div style={{ display: "grid",width:"49%" }}>
                            <Label className={"side-drawer-field__label"}>Country</Label>
                            <Controller
                                name="address.country"
                                key="address.country"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter Country"}
                                    className={"side-drawer-field side-drawer-field__gap"} {...field} />}
                            />
                        </div>
                        <div style={{ display: "grid" ,width:"49%"}}>
                            <Label className={"side-drawer-field__label"}>Zip</Label>
                            <Controller
                                name="address.zip"
                                key="address.zip"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter Zip"}
                                    className={"side-drawer-field side-drawer-field__gap"} {...field} />}
                            />
                        </div>
                    </div>

                    <div className="btnActions">
                        <button appearance="primary" type="submit" className="form-submit-btn">
                            {props.selectedServiceProvider ? 'Update' : 'Submit'}</button>
                        <button className="form-submit-cancel"
                            onClick={() => props.setIsOpen(false)}>Cancel</button>
                    </div>
                </form>
            </DrawerBody>
        </DrawerOverlay>
    )
}

export default ServiceProviderForm
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSidebarOpen : true
};

const layOutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        toggleSidebar: (state, action) => {
            state.isSidebarOpen = action.payload;
        },

    },
});



export const{ toggleSidebar} = layOutSlice.actions;

export default layOutSlice.reducer;

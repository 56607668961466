import {
    Button,
    Dropdown,
    Input, Label,
    Option,
    Textarea,
    makeStyles, shorthands
} from "@fluentui/react-components";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay
} from "@fluentui/react-components/unstable";
import { CalendarCancelFilled, DeleteFilled, DeleteRegular, Dismiss24Regular, RocketRegular, bundleIcon } from "@fluentui/react-icons";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import VehicleServiceTypeDataService from "../../services/VehicleServiceTypeDataService";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialVehicleServiceTypeState = {
    "description": "",
    "type": "",
    "serviceProviderIds": [],
    "id": ""
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

function VehicleServiceTypeForm(props) {
    const styles = useStyles();
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedVehicleServiceType ? props.selectedVehicleServiceType : InitialVehicleServiceTypeState
    });

    const onSubmit = async formdata => {
        var data = formdata;
        var id = "";
        if (props.selectedVehicleServiceType) {
            id = props.selectedVehicleServiceType.id;
            await VehicleServiceTypeDataService.updateVehicleServiceType(props.selectedVehicleServiceType.id, data);
        } else {
            const addeddata = await VehicleServiceTypeDataService.addVehicleServiceType(data);
            id = addeddata.id;
        }
        props.setIsOpen(false);
        props.updateGrid();
    }

    useEffect(() => {
        const fields = ['description', 'type', 'serviceProviderIds', 'id'];
        if (props.selectedVehicleServiceType) {
            fields.forEach(field => setValue(field, props.selectedVehicleServiceType[field]));
        } else {
            fields.forEach(field => setValue(field, InitialVehicleServiceTypeState[field]));
        }
    }, [props.selectedVehicleServiceType])

    return (
        <DrawerOverlay
            size={"medium"}
            position={"right"}
            style={{ width: "504px" }}

            open={props.isOpen}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader style={{
            background: "#F7F7F7",
            padding: "26px 32px"
        }}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    + {props.selectedVehicleServiceType ? "Edit" : "New"} Vehicle Service Type
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <form onSubmit={handleSubmit(onSubmit)}
                    className={styles.root} onReset={reset} id={props.selectedVehicleServiceType ? props.selectedVehicleServiceType.id : "New"}>
                    <Label className={"side-drawer-field__label"} style={{ marginTop: "32px" }}>
                        Type*
                    </Label>
                    <Controller
                        name="type"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <input
                            className={"side-drawer-field  side-drawer-field__gap"}
                            placeholder={"Enter Type"}
                            {...field} />}
                    />
                    <Label className={"side-drawer-field__label"} style={{marginBottom:"8px"}}>
                        Description*
                    </Label>
                    <Controller
                        name="description"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <Textarea

                            className={"side-drawer-field-Textarea  side-drawer-field__gap"}
                            placeholder={"Enter Description"}

                            {...field} resize="vertical" />}
                    />
                    <Label className={"side-drawer-field__label"} style={{marginTop:24}}>
                        Service Providers
                    </Label>
                    <Controller
                        name="serviceProviderIds"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) =>
                            <Dropdown multiselect={true}
                                      className={"side-drawer-field-dropdown  side-drawer-field__gap"}
                                      placeholder={"Select Service Providers"}
                                      onOptionSelect={(ev, data) => field.onChange(data.selectedOptions)}
                                selectedOptions={field.value}>
                                {props.options.map((option) => (
                                    <Option key={option.id} value={option.id}>
                                        {option.providerName}
                                    </Option>
                                ))}
                            </Dropdown>}
                    />
                    <div className="btnActions">
                        <button appearance="primary" type="submit" className="form-submit-btn">
                            {props.selectedVehicleServiceType ? 'Update' : 'Submit'}</button>
                        <button className="form-submit-cancel"
                            onClick={() => props.setIsOpen(false)}>Cancel</button>
                    </div>
                </form>
            </DrawerBody>
        </DrawerOverlay>
    )
}

export default VehicleServiceTypeForm
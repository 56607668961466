import { collection, doc, getDoc, query, where, getDocs } from "firebase/firestore";
import { db } from "./firebase";

const keyCafeKeysCollection = collection(db, "KeyCafeKeys");

class KeyCafeDataService {
    getKey = (fob) => {
        const q = query(keyCafeKeysCollection, where("fob", "==", fob));
        const doc = getDocs(q);
        return doc;
    }

    getAllFobKeys = () => {
        return getDocs(keyCafeKeysCollection)
    }

}

export default new KeyCafeDataService();
import {
    Button,
    Label,
    Table, TableBody,
    TableCell,
    TableCellActions,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands, Spinner
} from "@fluentui/react-components";
import { DeleteFilled, DeleteRegular, EditFilled, EditRegular, bundleIcon } from "@fluentui/react-icons";
import { collection, getDocs, query, where } from "firebase/firestore";
import moment from 'moment';
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import BookingDataService from "./services/bookingDataService";
import { auth, db, trackException } from "./services/firebase";
// import ImageDialog from "./components/ImageDialog";
import DeleteEventDialog from "./components/DeleteEventDialog";
import RoomForm from "./components/Forms/RoomForm";
import roomsDataService from "./services/roomsDataService";
import UserDataService from "./services/userDataService";
import CustomSearchInput from "./components/CustomSearchInput";
import debounce from 'lodash/debounce';
import { PlusSVG } from "./static/svgs";

const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "roomType", label: "Room Type" },
    { columnKey: "description", label: "Description" },
    { columnKey: "startDate", label: "Start Date" },
    { columnKey: "endDate", label: "End Date" },
    { columnKey: "member", label: "Member" },
    { columnKey: "createdBy", label: "Created By" }
];

function Room() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedRoom, setSelectedRoom] = useState();
    const [allbookroom, setAllBookRoom] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredBookedroom, setFilteredBookedroom] = useState([]);
    const [allroom, setAllRoom] = useState([]);

    const [allusers, setAllUsers] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [currentuserId, setCurrentuserId] = React.useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50); // Number of events per page
    const [startAfterDoc, setStartAfterDoc] = useState(null); // Track the last document of the previous page
    const [isLoading, setIsLoading] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);

    const navigate = useNavigate();
    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedRoom(selectedItem);
    }
    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        setIsLoading(true);
        fetchUserNameAndRoom();
    }, [user, loading]);
    useEffect(() => {
        if (page > 1) {
            loadMorePosts();
        }
    }, [page]);

    const fetchUserNameAndRoom = async () => {
        setPage(1)
        setStartAfterDoc(null)
        setHasMoreData(true)
        try {
            if (!!user) {
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                const currentuser = userdoc.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                //console.log(currentuser[0].id);
                setCurrentuserId(currentuser[0].id);
                if (userdoc.docs.length > 0) {
                    const usersQuery = await UserDataService.getAllUser();
                    setAllUsers(usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

                    const roomsQuery1 = await roomsDataService.getAllRooms();
                    setAllRoom(roomsQuery1.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

                    //const roomsQuery = await BookingDataService.getAllRoom();
                    //let bookedRoomArr = roomsQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    const { events, lastVisible } = await BookingDataService.getAllRoomsWithPage(pageSize, startAfterDoc);
                    let bookedRoomArr = events;
                    setStartAfterDoc(lastVisible);
                    setAllBookRoom(bookedRoomArr);
                    setFilteredBookedroom(bookedRoomArr)
                    if (bookedRoomArr.length < pageSize) {
                        setHasMoreData(false)
                    }
                    //console.log(allevents);
                } else {
                    console.log('no access');
                }
                setIsLoading(false);
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            console.error(err);
            setIsLoading(false);
            alert("An error occured while fetching user data");
        }
    };

    const loadMorePosts = async () => {
        const { events, lastVisible } = await BookingDataService.getAllRoomsWithPage(pageSize, startAfterDoc);
        // Update the startAfterDoc for the next page
        setStartAfterDoc(lastVisible);
        if (events.length < pageSize) {
            setHasMoreData(false)
            if (events.length > 0) {
                setAllBookRoom(prevEvents => [...prevEvents, ...events]);
                setFilteredBookedroom(prevEvents => [...prevEvents, ...events]);
            }
            // You can handle this case, e.g., display a message
        } else {
            // Append the new events to the existing events
            setAllBookRoom(prevEvents => [...prevEvents, ...events]);
            setFilteredBookedroom(prevEvents => [...prevEvents, ...events]);
        }
    };

    const openDeleteForm = (selectedItem) => {
        setIsDeleteDialogOpen(true);
        setSelectedRoom(selectedItem);
        // fetchUserNameAndEvents();
    }

    const DeleteRoom = async () => {
        BookingDataService.deleteBooking(selectedRoom.id);
        setIsDeleteDialogOpen(false);
        fetchUserNameAndRoom();
    };

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allbookroom.filter(event => {
            if (typeof event.roomType === 'object' && event.roomType.value && event.roomType.label) {
                return (
                    event.roomType.value.toLowerCase().includes(lowerSearch) ||
                    event.roomType.label.toLowerCase().includes(lowerSearch) ||
                    event.description.toLowerCase().includes(lowerSearch)
                );
            } else {
                return (
                    event.roomType.toLowerCase().includes(lowerSearch) ||
                    event.description.toLowerCase().includes(lowerSearch)
                );
            }
        });
        setFilteredBookedroom(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };
    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };
    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <Button icon={<PlusSVG />} className={"primary-btn"} shape="circular" appearance="primary" onClick={() => openForm(undefined)}>{'New Room Booking'.toUpperCase()}</Button>
                    <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    />
                </div>
                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredBookedroom.length > 0 && filteredBookedroom.map((item) =>
                                <TableRow key={item.id}>
                                    <TableCell className={"table-row-fonts__primary"}>
                                        <TableCellLayout>
                                            {item.roomType.label ? item.roomType.label : item.roomType}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label title={item.description}>{item.description.substring(0, 150)}</Label>
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label>{moment(item.startDate).format('Do MMMM YYYY hh mm A')}</Label>
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>{moment(item.endDate).format('Do MMMM YYYY hh mm A')}

                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <Label>{allusers.filter(t => t.id == item.memberId)[0]?.firstName} &nbsp;
                                            {allusers.filter(t => t.id == item.memberId)[0]?.lastName}</Label>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <Label>{allusers.filter(t => t.id == item.createdBy)[0]?.firstName} &nbsp;
                                            {allusers.filter(t => t.id == item.createdBy)[0]?.lastName}</Label>
                                        <TableCellActions>
                                            <Button
                                                icon={<EditIcon />}
                                                appearance="subtle"
                                                aria-label="Edit"
                                                onClick={() => openForm(item)}
                                            />
                                            <Button
                                                icon={<DeleteIcon />}
                                                appearance="subtle"
                                                aria-label="Delte"
                                                onClick={() => openDeleteForm(item)}
                                            />
                                        </TableCellActions>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    {isLoading && <Spinner />} {/* Render loader if loading state is true */}
                    {!isLoading && hasMoreData && searchTerm == "" && <Button onClick={handleLoadMore}>Load More...</Button>} {/* Render button if not loading */}
                </div>
            </div>
            <RoomForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)} selectedRoom={selectedRoom}
                updateGrid={fetchUserNameAndRoom} createdBy={currentuserId} users={allusers} roomType={allroom}></RoomForm>
            {/* <ImageDialog open={open} setOpen={setOpen} selectedEvent={selectedRoom}></ImageDialog> */}
            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeleteRoom} text={'Room'} />
        </Layout>
    )
}

export default Room;
import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase";

const vehicleServiceTypeCollection = collection(db, "VehicleServiceTypes");

class VehicleServiceTypeDataService {
    addVehicleServiceType = (newVehicleServiceType) => {
        return addDoc(vehicleServiceTypeCollection, newVehicleServiceType);
    }

    updateVehicleServiceType = (id, updatedVehicleServiceType) => {
        const vehicleServiceTypeDoc = doc(db, "VehicleServiceTypes", id);
        return updateDoc(vehicleServiceTypeDoc, updatedVehicleServiceType);
    }

    deleteVehicleServiceType = (id) => {
        const vehicleServiceTypeDoc = doc(db, "VehicleServiceTypes", id);
        return deleteDoc(vehicleServiceTypeDoc);
    }

    getAllVehicleServiceType = () => {
        return getDocs(vehicleServiceTypeCollection)
    }

    getVehicleServiceType = (id) => {
        const vehicleServiceTypeDoc = doc(db, "VehicleServiceTypes", id);
        return getDoc(vehicleServiceTypeDoc);
    }
}

export default new VehicleServiceTypeDataService();
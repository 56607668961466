import React from "react";
import "./LoadingScreen.css"; // Import CSS for styling

function LoadingScreen() {
  return (
    <div className="loading-screen">
      <div className="loading-spinner"></div>
      <p>Loading...</p>
    </div>
  );
}

export default LoadingScreen;

import React, { useEffect, useState } from "react";
import Layout from "./components/Layout";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, trackException } from "./services/firebase";
import { useNavigate } from "react-router-dom";
import {
    makeStyles, shorthands, Persona, Button, Table, TableBody,
    TableHeader, TableRow, TableHeaderCell, TableCell,
    TableCellLayout, Image, TableCellActions, Label
} from "@fluentui/react-components";
import { query, collection, getDocs, where } from "firebase/firestore";
import { EditFilled, EditRegular, bundleIcon, DeleteFilled, DeleteRegular } from "@fluentui/react-icons";
import GroupDataService from "./services/groupDataService";
import moment from 'moment';
//import ImageDialog from "./components/ImageDialog";
import DeleteEventDialog from "./components/DeleteEventDialog";
import GroupForm from "./components/Forms/GroupForm";
import debounce from 'lodash/debounce';
import CustomSearchInput from "./components/CustomSearchInput";
import { PlusSVG } from "./static/svgs";
const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "title", label: "Title" },
    { columnKey: "valueMin", label: "ValueMin" },
    { columnKey: "valueMax", label: "ValueMax" },
    { columnKey: "pointsPerDay", label: "Points Per Day" },
    { columnKey: "pointsWeekend", label: "Points Weekend" },
    { columnKey: "overageMile", label: "Overage Mile" },
    { columnKey: "mileageAllowed", label: "Mileage Allowed" },
    { columnKey: "pricePerDay", label: "Price Per Day" },
    // { columnKey: "description", label: "Description" }
];

function Group() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState();
    const [allgroups, setAllGroup] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredGroups, setFilteredGroups] = useState([]);

    const [open, setOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const navigate = useNavigate();
    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedGroup(selectedItem);
    }

    const openFormNew = () => {
        setIsFormOpen(true);
        let r = (Math.random() + 1).toString(36).substring(7);
        setSelectedGroup(r);
    }

    const fetchUserNameAndGroup = async () => {
        try {
            if (!!user) {
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                if (userdoc.docs.length > 0) {
                    const groupsQuery = await GroupDataService.getAllGroup();
                    const sortedGroups = groupsQuery.docs
                        .map((doc) => ({ ...doc.data(), id: doc.id }))
                        .sort((a, b) => a.title.localeCompare(b.title)); // Sort by title
                    setAllGroup(sortedGroups);
                    setFilteredGroups(sortedGroups)
                } else {
                    console.log('no access');
                }
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        fetchUserNameAndGroup();
    }, [user, loading]);

    const openDeleteForm = (selectedItem) => {
        setIsDeleteDialogOpen(true);
        setSelectedGroup(selectedItem);
        // fetchUserNameAndEvents();
    }

    const DeleteGroup = async () => {
        GroupDataService.deleteGroup(selectedGroup.id);
        setIsDeleteDialogOpen(false);
        fetchUserNameAndGroup();
    };

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allgroups.filter(event => {
            return (
                event.title.toLowerCase().includes(lowerSearch) ||
                event.valueMin.toLowerCase().includes(lowerSearch) ||
                event.pointsPerDay.toLowerCase().includes(lowerSearch)
            )
        });
        setFilteredGroups(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };

    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <Button icon={<PlusSVG />} className={"primary-btn"} shape="circular" appearance="primary" onClick={() => openFormNew(undefined)}>New Group</Button>
                    <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    />
                </div>
                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredGroups.length > 0 && filteredGroups.map((item) =>
                                <TableRow key={item.id}>
                                    <TableCell className={"table-row-fonts__primary"}>
                                        <TableCellLayout>
                                            {item.title}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            {item?.valueMin}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            {item?.valueMax}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            {item?.pointsPerDay}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            {item?.pointsWeekend}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            {item?.overageMile}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            {item?.mileageAllowed}
                                        </TableCellLayout>
                                    </TableCell>

                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label>${item?.pricePerDay}</Label>
                                            <TableCellActions>
                                                <Button
                                                    icon={<EditIcon />}
                                                    appearance="subtle"
                                                    aria-label="Edit"
                                                    onClick={() => openForm(item)}
                                                />
                                                <Button
                                                    icon={<DeleteIcon />}
                                                    appearance="subtle"
                                                    aria-label="Delte"
                                                    onClick={() => openDeleteForm(item)}
                                                />
                                            </TableCellActions>
                                        </TableCellLayout>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <GroupForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)} selectedGroup={selectedGroup}
                updateGrid={fetchUserNameAndGroup}></GroupForm>
            {/*<ImageDialog open={open} setOpen={setOpen} selectedEvent={selectedGroup}></ImageDialog> */}
            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeleteGroup} text={'Group'} />
        </Layout>
    )
}

export default Group;
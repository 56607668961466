import {
  Button,
  Checkbox, Divider,
  Dropdown,
  Image,
  Label,
  Option,
  Textarea,
  makeStyles,
  shorthands,
  Link
} from "@fluentui/react-components";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { Dismiss24Regular } from "@fluentui/react-icons";


import { DeleteFilled, DeleteRegular, bundleIcon } from "@fluentui/react-icons";
import { Timestamp } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import { getTimeFromDate2, mergeDateTime, mergeDateTime2 } from "../../Helper";
import { Base_Url, storage, trackException } from "../../services/firebase";
import RentalDataService from "../../services/rentalDataService";
import { DateSVG, InsuranceSVG, IssueSVG } from "../../static/svgs";
import axios from "axios";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialRentalState = {
  id: "",
  vehicleId: "",
  startDate: "",
  endDate: "",
  drivingInstructions: "",
  vehicle: {},
  userId: "",
  isActive: false,
  price: "",
  isUsingWarehouseInsurance: false,
  insuranceInfo: {
    policyHolderName: "",
    insuranceCompany: "",
    policyNumber: "",
    state: "",
  },
  incidentComments: "",
  incidentIssues: "",
  leaveNotFueled: false,
  carWash: false,
  rentalCarWashPrice: 0,
  incidentImages: [],
  beforeRentalImg: {
    frontImageURLs: [],
    backImageURLs: [],
    rightSideImageURLs: [],
    leftsideImageURLs: [],
    otherPhotosImageURLs: [],
    mileagePhotosImageURLs: []
  },
  afterRentalImg: {
    frontImageURLs: [],
    backImageURLs: [],
    rightSideImageURLs: [],
    leftsideImageURLs: [],
    otherPhotosImageURLs: [],
    mileagePhotosImageURLs: []
  },
  finalComments: "",
  finalIssues: "",
  createdDate: "",
  odometerbefore: "",
  odometerafter: "",
  fuellevelbefore: "",
  fuellevelafter: "",
  rentalAgreeSignUrl: ""
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    ...shorthands.gap("2px"),
  },
  control: {
    maxWidth: "300px",
  },
});

function RentalForm(props) {
  const styles = useStyles();
  const [images, setImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  //Pre Fuel Image state
  const [imagesFrontPreFuel, setImagesFrontPreFuel] = useState([]);
  const [uploadedImagesFrontPreFuel, setUploadedImagesFrontPreFuel] = useState([]);

  const [imagesBackPreFuel, setImagesBackPreFuel] = useState([]);
  const [uploadedImagesBackPreFuel, setUploadedImagesBackPreFuel] = useState([]);

  const [imagesRightSidePreFuel, setImagesRightSidePreFuel] = useState([]);
  const [uploadedImagesRightSidePreFuel, setUploadedImagesRightSidePreFuel] = useState([]);

  const [imagesLeftSidePreFuel, setImagesLeftSidePreFuel] = useState([]);
  const [uploadedImagesLeftSidePreFuel, setUploadedImagesLeftSidePreFuel] = useState([]);

  const [imagesOtherPreFuel, setImagesOtherPreFuel] = useState([]);
  const [uploadedImagesOtherPreFuel, setUploadedImagesOtherPreFuel] = useState([]);

  const [imagesMileagePreFuel, setImagesMileagePreFuel] = useState([]);
  const [uploadedImagesMileagePreFuel, setUploadedImagesMileagePreFuel] = useState([]);

  //Post Fuel Image state
  const [imagesFrontPostFuel, setImagesFrontPostFuel] = useState([]);
  const [uploadedImagesFrontPostFuel, setUploadedImagesFrontPostFuel] = useState([]);

  const [imagesBackPostFuel, setImagesBackPostFuel] = useState([]);
  const [uploadedImagesBackPostFuel, setUploadedImagesBackPostFuel] = useState([]);

  const [imagesRightSidePostFuel, setImagesRightSidePostFuel] = useState([]);
  const [uploadedImagesRightSidePostFuel, setUploadedImagesRightSidePostFuel] = useState([]);

  const [imagesLeftSidePostFuel, setImagesLeftSidePostFuel] = useState([]);
  const [uploadedImagesLeftSidePostFuel, setUploadedImagesLeftSidePostFuel] = useState([]);

  const [imagesOtherPostFuel, setImagesOtherPostFuel] = useState([]);
  const [uploadedImagesOtherPostFuel, setUploadedImagesOtherPostFuel] = useState([]);

  const [imagesMileagePostFuel, setImagesMileagePostFuel] = useState([]);
  const [uploadedImagesMileagePostFuel, setUploadedImagesMileagePostFuel] = useState([]);

  const [rentalAgreeSignUrl, setRentalAgreeSignUrl] = useState([]);
  const [uploadedRentalAgreeSignUrl, setUploadedRentalAgreeSignUrl] = useState([]);

  const [percent, setPercent] = useState(0);
  const [placeholderV, setPlaceholderV] = useState("");

  const [selectedMemberPlaceholder, setSelectedMemberPlaceholder] = useState('');

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues: props.selectedRental
      ? props.selectedRental
      : InitialRentalState,
  });

  const onSubmit = async (formdata) => {
    const startDate = new Date(formdata.startDate);
    const endDate = new Date(formdata.endDate);
    const idToIgnore = formdata.id;

    if (endDate <= startDate) {
      alert("Invalid date range.");
    }
    // Find the objects in rentalDateArr that fall within the given date range, ignoring the specified id is current rental id
    const objectsInDateRange = props.rentalDateArr.filter((obj) =>
      doesDateRangeOverlap(
        new Date(obj.startDate),
        new Date(obj.endDate),
        startDate,
        endDate,
        obj.id,
        idToIgnore
      )
    );

    if (objectsInDateRange && objectsInDateRange.length > 0) {
      if (objectsInDateRange[0]?.vehicleId == formdata.vehicleId) {
        alert("Vehicle alredy booked for the date range.");
      } else {
        updateDataInDb(formdata);
      }
    } else {
      updateDataInDb(formdata);
    }
  };

  async function updateDataInDb(formdata) {
    var data = formdata;
    let selectedCar = props.vehicles.filter((obj) => obj.id == data.vehicleId);
    if (selectedCar && selectedCar.pricePerDay) {
      const startDate = new Date(data.startDate);
      const endDate = new Date(data.endDate);
      const diffInMs =
        new Date(endDate).valueOf() - new Date(startDate).valueOf();
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      let totalCostNew = selectedCar.pricePerDay * (diffInDays + 1);
      data.price = totalCostNew;
    }
    data.vehicle = selectedCar[0];
    const mergedstartDateTime = mergeDateTime2(data.startDate, data.startTime);
    const mergedendDateTime = mergeDateTime2(data.endDate, data.endTime);
    data.startDate = moment(mergedstartDateTime).toISOString();
    data.endDate = moment(mergedendDateTime).toISOString();
    // const startTimestamp = Timestamp.fromDate(new Date(mergedstartDateTime));
    // const endTimestamp = Timestamp.fromDate(new Date(mergedendDateTime));
    const startTimestamp = Timestamp.fromMillis(Date.parse(mergedstartDateTime));
    const endTimestamp = Timestamp.fromMillis(Date.parse(mergedendDateTime));

    data.startTimestamp = startTimestamp;
    data.endTimestamp = endTimestamp;
    data.createdDate = new Date().toISOString();

    if (props.selectedRental) {
      data.id = props.selectedRental.id;
      await RentalDataService.updateRental(props.selectedRental.id, data);
    } else {
      data.userId = props.currentUserData.id;
      const addeddata = await RentalDataService.addRental(data);
      data.id = addeddata.id;
      await RentalDataService.updateRental(data.id, data);
    }
    let id = data.id;
    if (uploadedRentalAgreeSignUrl.length > 0) {
      data.rentalAgreeSignUrl = await uploadImage(uploadedRentalAgreeSignUrl[0], `/PolicyAgreeSign/${id}`);
    }
    //Image processing
    processMultipleImageUrls(images, uploadedImages, (id) => `/IncidentReport/${id}-all-${Date.now()}.png`, "imageURLs", "imageURLs", RentalDataService, id, props, data);

    processMultipleImageUrls(imagesFrontPreFuel, uploadedImagesFrontPreFuel, (id) => `/Reservation/${id}-all-${Date.now()}.png`, "beforeRentalImg", "frontImageURLs", RentalDataService, id, props, data);
    processMultipleImageUrls(imagesBackPreFuel, uploadedImagesBackPreFuel, (id) => `/Reservation/${id}-all-${Date.now()}.png`, "beforeRentalImg", "backImageURLs", RentalDataService, id, props, data);
    processMultipleImageUrls(imagesLeftSidePreFuel, uploadedImagesLeftSidePreFuel, (id) => `/Reservation/${id}-all-${Date.now()}.png`, "beforeRentalImg", "leftsideImageURLs", RentalDataService, id, props, data);
    processMultipleImageUrls(imagesRightSidePreFuel, uploadedImagesRightSidePreFuel, (id) => `/Reservation/${id}-all-${Date.now()}.png`, "beforeRentalImg", "rightSideImageURLs", RentalDataService, id, props, data);
    processMultipleImageUrls(imagesMileagePreFuel, uploadedImagesMileagePreFuel, (id) => `/Reservation/${id}-all-${Date.now()}.png`, "beforeRentalImg", "mileagePhotosImageURLs", RentalDataService, id, props, data);
    processMultipleImageUrls(imagesOtherPreFuel, uploadedImagesOtherPreFuel, (id) => `/Reservation/${id}-all-${Date.now()}.png`, "beforeRentalImg", "otherPhotosImageURLs", RentalDataService, id, props, data);

    processMultipleImageUrls(imagesFrontPostFuel, uploadedImagesFrontPostFuel, (id) => `/Reservation/${id}-all-${Date.now()}.png`, "afterRentalImg", "frontImageURLs", RentalDataService, id, props, data);
    processMultipleImageUrls(imagesBackPostFuel, uploadedImagesBackPostFuel, (id) => `/Reservation/${id}-all-${Date.now()}.png`, "afterRentalImg", "backImageURLs", RentalDataService, id, props, data);
    processMultipleImageUrls(imagesLeftSidePostFuel, uploadedImagesLeftSidePostFuel, (id) => `/Reservation/${id}-all-${Date.now()}.png`, "afterRentalImg", "leftsideImageURLs", RentalDataService, id, props, data);
    processMultipleImageUrls(imagesRightSidePostFuel, uploadedImagesRightSidePostFuel, (id) => `/Reservation/${id}-all-${Date.now()}.png`, "afterRentalImg", "rightSideImageURLs", RentalDataService, id, props, data);
    processMultipleImageUrls(imagesMileagePostFuel, uploadedImagesMileagePostFuel, (id) => `/Reservation/${id}-all-${Date.now()}.png`, "afterRentalImg", "mileagePhotosImageURLs", RentalDataService, id, props, data);
    processMultipleImageUrls(imagesOtherPostFuel, uploadedImagesOtherPostFuel, (id) => `/Reservation/${id}-all-${Date.now()}.png`, "afterRentalImg", "otherPhotosImageURLs", RentalDataService, id, props, data);

    props.setIsOpen(false);
    props.updateGrid();
  }

  useEffect(() => {
    setUploadedImagesFrontPreFuel([]);
    setUploadedImagesBackPreFuel([]);
    setUploadedImagesRightSidePreFuel([]);
    setUploadedImagesLeftSidePreFuel([]);
    setUploadedImagesOtherPreFuel([]);
    setUploadedImagesMileagePreFuel([]);

    setUploadedImagesFrontPostFuel([]);
    setUploadedImagesBackPostFuel([]);
    setUploadedImagesRightSidePostFuel([]);
    setUploadedImagesLeftSidePostFuel([]);
    setUploadedImagesOtherPostFuel([]);
    setUploadedImagesMileagePostFuel([]);

    setUploadedRentalAgreeSignUrl([])
    if (props.selectedRental) {
      console.log("props.selectedRental->", props.selectedRental)
      const fields = [
        "id",
        "userId",
        "price",
        "odometerbefore",
        "fuellevelbefore",
        "odometerafter",
        "fuellevelafter",
        "drivingInstructions",
        "incidentIssues",
        "leaveNotFueled",
        "carWash",
        "rentalCarWashPrice",
        'startTime', 'endTime', 'rentalAgreeSignUrl'
      ];
      fields.forEach((field) =>
        setValue(
          field,
          props.selectedRental[field]
            ? props.selectedRental[field]
            : InitialRentalState[field]
        )
      );
      setValue("startDate", moment(props.selectedRental["startDate"]).toDate());
      setValue("endDate", moment(props.selectedRental["endDate"]).toDate());
      let insuranceI = props.selectedRental.insuranceInfo;
      setValue(
        "insuranceInfo.policyHolderName",
        insuranceI["policyHolderName"]
      );
      setValue(
        "insuranceInfo.insuranceCompany",
        insuranceI["insuranceCompany"]
      );
      setValue("insuranceInfo.policyNumber", insuranceI["policyNumber"]);
      setValue(
        "insuranceInfo.state",
        insuranceI["state"] ? insuranceI["state"] : ""
      );
      setValue("vehicleId", props.selectedRental.vehicle?.id);
      setValue("incidentComments", props.selectedRental?.incidentComments);
      setValue("incidentIssues", props.selectedRental?.incidentIssues);

      const startTime = getTimeFromDate2(props.selectedRental.startDate);
      setValue('startTime', startTime);
      const endTime = getTimeFromDate2(props.selectedRental.endDate);
      setValue('endTime', endTime);

      let selectedMember = props.users.filter(obj => obj.id == props.selectedRental.userId);
      if (selectedMember[0] && selectedMember[0].firstName) {
        let str = `${selectedMember[0].firstName} ${selectedMember[0].lastName}`
        setSelectedMemberPlaceholder(str);
      } else {
        setSelectedMemberPlaceholder('');
      }

      let selectedCar = props.vehicles.filter(
        (obj) => obj.id == props.selectedRental.vehicle?.id
      );

      if (selectedCar[0] && selectedCar[0].make) {
        let str = `${selectedCar[0].make} ${selectedCar[0].model}`;
        setPlaceholderV(str);
      } else {
        setPlaceholderV("");
      }

      if (props.selectedRental && props.selectedRental.rentalAgreeSignUrl) {
        setRentalAgreeSignUrl([props.selectedRental.rentalAgreeSignUrl]);
      } else {
        setRentalAgreeSignUrl([]);
      }

      if (props.selectedRental && props.selectedRental.incidentImages) {
        setImages(props.selectedRental.incidentImages);
      } else {
        setImages({ incidentImages: [] });
      }

      if (props.selectedRental && props.selectedRental.beforeRentalImg) {
        if (props.selectedRental.beforeRentalImg.frontImageURLs) {
          setImagesFrontPreFuel(props.selectedRental.beforeRentalImg.frontImageURLs);
        } else {
          setImagesFrontPreFuel([]);
        }
      } else {
        setImagesFrontPreFuel([]);
      }

      if (props.selectedRental.beforeRentalImg && props.selectedRental.beforeRentalImg.backImageURLs) {
        setImagesBackPreFuel(props.selectedRental.beforeRentalImg.backImageURLs);
      } else {
        setImagesBackPreFuel([]);
      }
      if (props.selectedRental.beforeRentalImg && props.selectedRental.beforeRentalImg.leftsideImageURLs) {
        setImagesLeftSidePreFuel(props.selectedRental.beforeRentalImg.leftsideImageURLs);
      } else {
        setImagesLeftSidePreFuel([]);
      }
      if (props.selectedRental.beforeRentalImg && props.selectedRental.beforeRentalImg.rightSideImageURLs) {
        setImagesRightSidePreFuel(props.selectedRental.beforeRentalImg.rightSideImageURLs);
      } else {
        setImagesRightSidePreFuel([]);
      }
      if (props.selectedRental.beforeRentalImg && props.selectedRental.beforeRentalImg.otherPhotosImageURLs) {
        setImagesOtherPreFuel(props.selectedRental.beforeRentalImg.otherPhotosImageURLs);
      } else {
        setImagesOtherPreFuel([]);
      }
      if (props.selectedRental.beforeRentalImg && props.selectedRental.beforeRentalImg.mileagePhotosImageURLs) {
        setImagesMileagePreFuel(props.selectedRental.beforeRentalImg.mileagePhotosImageURLs);
      } else {
        setImagesMileagePreFuel([]);
      }


      if (props.selectedRental.afterRentalImg && props.selectedRental.afterRentalImg.frontImageURLs) {
        setImagesFrontPostFuel(props.selectedRental.afterRentalImg.frontImageURLs);
      } else {
        setImagesFrontPostFuel([]);
      }
      if (props.selectedRental.afterRentalImg && props.selectedRental.afterRentalImg.backImageURLs) {
        setImagesBackPostFuel(props.selectedRental.afterRentalImg.backImageURLs);
      } else {
        setImagesBackPostFuel([]);
      }
      if (props.selectedRental.afterRentalImg && props.selectedRental.afterRentalImg.leftsideImageURLs) {
        setImagesLeftSidePostFuel(props.selectedRental.afterRentalImg.leftsideImageURLs);
      } else {
        setImagesLeftSidePostFuel([]);
      }
      if (props.selectedRental.afterRentalImg && props.selectedRental.afterRentalImg.rightSideImageURLs) {
        setImagesRightSidePostFuel(props.selectedRental.afterRentalImg.rightSideImageURLs);
      } else {
        setImagesRightSidePostFuel([]);
      }
      if (props.selectedRental.afterRentalImg && props.selectedRental.afterRentalImg.otherPhotosImageURLs) {
        setImagesOtherPostFuel(props.selectedRental.afterRentalImg.otherPhotosImageURLs);
      } else {
        setImagesOtherPostFuel([]);
      }
      if (props.selectedRental.afterRentalImg && props.selectedRental.afterRentalImg.mileagePhotosImageURLs) {
        setImagesMileagePostFuel(props.selectedRental.afterRentalImg.mileagePhotosImageURLs);
      } else {
        setImagesMileagePostFuel([]);
      }



    } else {
      setValue("startDate", "");
      setValue("endDate", "");
      setValue("incidentComments", "");
      setValue("incidentIssues", "");
      setValue("odometerbefore", "");
      setValue("fuellevelbefore", "");
      setValue("odometerafter", "");
      setValue("fuellevelafter", "");
      setValue("drivingInstructions", "");
      setPlaceholderV("");
      setValue("leaveNotFueled", false);
      setValue("carWash", false);
      setValue("rentalCarWashPrice", "");

      setImages([]);
      setImagesFrontPreFuel([]);
      setImagesFrontPostFuel([]);
      setImagesBackPreFuel([]);
      setImagesLeftSidePreFuel([]);
      setImagesRightSidePreFuel([]);
      setImagesOtherPreFuel([]);
      setImagesMileagePreFuel([]);
      setRentalAgreeSignUrl([]);
      setSelectedMemberPlaceholder("");
      if (props.currentUserData && props.currentUserData.insuranceInfo) {
        let insuranceI = props.currentUserData.insuranceInfo;
        setValue(
          "insuranceInfo.policyHolderName",
          insuranceI["policyHolderName"]
        );
        setValue(
          "insuranceInfo.insuranceCompany",
          insuranceI["insuranceCompany"]
        );
        setValue("insuranceInfo.policyNumber", insuranceI["policyNumber"]);
        setValue("insuranceInfo.state", insuranceI["state"]);
      }
    }
  }, [props.selectedRental]);

  const handleChangeImages = (event, setUploadedImages, setImages, uploadedImages, images) => {
    const choosenFile = Array.prototype.slice.call(event.target.files);

    const uploaded = [...uploadedImages];
    const tempimages = [...images];
    choosenFile.some((file) => {

      const ext = (
        file.name ? file.name.split(".").pop() : file.path.split(".").pop()
      ).toLowerCase();
      // If heic or heif, convert to jpeg
      if (ext === "heic" || ext === "heif") {
        //TODO: Give HEIC Support
      } else {
        if (uploaded.findIndex((f) => f.name === file.name) === -1) {
          tempimages.push(URL.createObjectURL(file));
          uploaded.push(file);
        }
      }
    });
    setImages(tempimages);
    setUploadedImages(uploaded);
  };

  const removeImages = (index, setUploadedImages, setImages, uploadedImages, images) => {
    var tempuploadedImages = [...uploadedImages];
    tempuploadedImages.splice(index, 1);
    setUploadedImages(tempuploadedImages);
    var tempimages = [...images];
    tempimages.splice(index, 1);
    setImages(tempimages);
  };

  async function processMultipleImageUrls(previousImgUrls, imageUrls, storageRefPathTemplate, dataPropToUpdate, frontImageURLsProp, rentalDataService, id, props, data) {
    try {
      if (imageUrls.length > 0) {
        const downloadUrls = [];
        await Promise.all(imageUrls.map(async (imageUrl) => {
          const storageRef = ref(storage, storageRefPathTemplate(data.id));
          const uploadTask = uploadBytesResumable(storageRef, imageUrl);

          await new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const percent = Math.round(
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                // Update progress if needed
              },
              reject, // Reject on error
              async () => {
                const url = await getDownloadURL(uploadTask.snapshot.ref);
                downloadUrls.push(url);
                resolve(); // Resolve the promise once URL is pushed
              }
            );
          });

        }));

        const filteredUrls = previousImgUrls.filter(url => !url.includes('blob'));
        data[dataPropToUpdate] = { ...data[dataPropToUpdate], [frontImageURLsProp]: [...filteredUrls, ...downloadUrls] }; // Merge existing data with dynamic property name

      } else {
        const filteredUrls = previousImgUrls.filter(url => !url.includes('blob'));
        data[dataPropToUpdate] = { ...data[dataPropToUpdate], [frontImageURLsProp]: filteredUrls }; // Set to empty array if no URLs
      }
      //console.log(JSON.stringify(data)); // Log the updated data);
      await rentalDataService.updateRental(id, data);
      props.setIsOpen(false);
      props.updateGrid();
    } catch (error) {
      trackException(JSON.stringify(error))
      console.error("Error processing image URLs:", error);
      // Handle errors appropriately (e.g., display user-friendly messages)
    }
  }

  const uploadImage = (image, storagePath) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, storagePath);
      const uploadTask = uploadBytesResumable(storageRef, image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        },
        (err) => reject(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            resolve(url);
          }).catch((error) => reject(error));
        }
      );
    });
  };

  const handleChangeSignImages = (event) => {
    const choosenFile = Array.prototype.slice.call(event.target.files);
    const uploaded = [];
    const tempimages = [];
    choosenFile.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        tempimages.push(URL.createObjectURL(event.target.files[0]));
        uploaded.push(file);
      }
    });
    setRentalAgreeSignUrl(tempimages);
    setUploadedRentalAgreeSignUrl(uploaded);
  }

  const removeSignImages = (index) => {
    const tempUploadedImages = [...uploadedRentalAgreeSignUrl];
    tempUploadedImages.splice(index, 1);
    setUploadedRentalAgreeSignUrl(tempUploadedImages);
    const tempImages = [...rentalAgreeSignUrl];
    tempImages.splice(index, 1);
    setRentalAgreeSignUrl(tempImages);
  }

  function generateAgrement() {
    const dateTimeStamp = new Date().toISOString().replace(/[:.]/g, '-');
    let pdfPath = "https://firebasestorage.googleapis.com/v0/b/warehouse-dev-7dcd6.appspot.com/o/WHPolicy.pdf?alt=media&token=38f74a07-de36-4d69-aec9-472b293e24f6"

    // if (process.env.REACT_APP_FIREBASE_PROJECT_ID == 'warehouse-prod-40386') {
    //   pdfPath = ""
    // }

    let data = JSON.stringify({
      "signaturePath": `${rentalAgreeSignUrl}`,
      "pdfPath": pdfPath,
      "outputFilePath": `signed-documents/${props.selectedRental.userId}/SignAgreement_signed_${dateTimeStamp}.pdf`
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${Base_Url}/api/addSignature`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        if (response && response.data && response.data.url) {
          console.log("res->", response)
          window.open(response.data.url, '_blank');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleClick = () => {
    generateAgrement();
  };

  return (
    <DrawerOverlay
      size={"medium"}
      style={{ width: "504px" }}
      position={"right"}
      open={props.isOpen}
      onOpenChange={(_, { open }) => props.setIsOpen(open)}
    >
      <DrawerHeader style={{
        background: "#F7F7F7",
        padding: "26px 32px"
      }}>
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={() => props.setIsOpen(false)}
            />
          }
        >
          + {props.selectedRental ? "Edit" : "New"} Rental
        </DrawerHeaderTitle>
      </DrawerHeader>
      <DrawerBody >
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.root}
          onReset={reset}
          id={props.selectedRental ? props.selectedRental.id : "New"}
        >
          {
            rentalAgreeSignUrl && rentalAgreeSignUrl != "" ?
              <Label>
                <Link href="#" onClick={handleClick} underline>
                  Download Agreement
                </Link>
              </Label> : <div />
          }

          <Label className={"side-drawer-field__label"} style={{ marginTop: "32px   " }}>Vehicle*</Label>
          <Controller
            name="vehicleId"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Dropdown
                className={"side-drawer-field-dropdown  side-drawer-field__gap"}

                multiselect={false}
                onOptionSelect={(ev, data) =>
                  field.onChange(data.selectedOptions[0])
                }
                selectedOptions={[field.value]}
                placeholder={"Select Vehicle"}
              >
                {props.vehicles.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option?.make} {option?.model}
                  </Option>
                ))}
              </Dropdown>
            )}
          />
          <div className={"d-flex justify-space-between"}>
            <div style={{ display: "grid", width: "49%" }}>
              <Label className={"side-drawer-field__label"} style={
                {
                  marginTop: "24px"
                }
              }>
                Start Date
              </Label>
              <Controller
                name="startDate"
                control={control}
                render={({ field }) => <div className={"datepicker-custom-wrap"}><DatePicker
                  showTimeSelect
                  selected={field.value}
                  contentAfter={<DateSVG />}
                  onSelectDate={field.onChange}
                  style={{
                    width: "100%",
                    height: "44px"
                  }}
                  placeholder="Select Start Date"
                  className={"side-drawer-field-default  side-drawer-field__gap side-drawer-field__gap"}

                  {...field}
                  dateFormat={'do MMMM yyyy hh:mm a'}
                /></div>}
              />
            </div>
            <div style={{ display: "grid", width: "49%" }}>
              <Label className={"side-drawer-field__label"} style={
                {
                  marginTop: "24px"
                }
              }>
                Start Time
              </Label>
              <Controller
                name="startTime"
                control={control}
                render={({ field }) => <input
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} type="time"
                  onChange={field.onChange}{...field} />}
              />
            </div>


          </div>

          <div className={"d-flex justify-space-between"}>
            <div style={{ display: "grid", width: "49%" }}>
              <Label className={"side-drawer-field__label"}>
                End Date
              </Label>
              <Controller
                name="endDate"
                control={control}
                render={({ field }) => <div className={"datepicker-custom-wrap"}><DatePicker
                  showTimeSelect
                  selected={field.value}
                  contentAfter={<DateSVG />}
                  onSelectDate={field.onChange}
                  style={{
                    width: "100%",
                    height: "44px"
                  }}
                  placeholder="Select End Date"
                  className={"side-drawer-field-default  side-drawer-field__gap side-drawer-field__gap"}
                  {...field}
                  dateFormat={'do MMMM yyyy hh:mm a'}
                /></div>}
              />
            </div>
            <div style={{ display: "grid", width: "49%" }}>
              <Label className={"side-drawer-field__label"} style={
                {}
              }>
                End Time
              </Label>
              <Controller
                name="endTime"
                control={control}
                render={({ field }) => <input
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} type="time"
                  onChange={field.onChange}{...field} />}
              />

            </div>
          </div>
          <Label className={"side-drawer-field__label"}>
            Member
          </Label>
          <Controller
            name="userId"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <Dropdown multiselect={false}
              className={"side-drawer-field-dropdown  side-drawer-field__gap"}
              onOptionSelect={(ev, data) => {
                field.onChange(data.selectedOptions[0])
              }}
              selectedOptions={[field.value]}
              placeholder={"Enter Member" || selectedMemberPlaceholder}
            >
              {props.users.map((option) => (
                <Option key={option.id} value={option.id}>
                  {`${option.firstName} ${option.lastName}`}
                </Option>
              ))}
            </Dropdown>}
          />
          <div className={"d-flex justify-space-between"}>
            <div style={{ display: "grid", width: "49%" }}>
              <Label className={"side-drawer-field__label"}>Odometer Before</Label>
              <Controller
                name="odometerbefore"
                control={control}
                rules={{ required: false }}
                render={({ field }) => <input
                  placeholder={"Enter Number"}
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field}
                  type={"number"} />}
              />
            </div>
            <div style={{ display: "grid", width: "49%" }}>
              <Label className={"side-drawer-field__label"}>Odometer After</Label>
              <Controller
                name="odometerafter"
                control={control}
                rules={{ required: false }}
                render={({ field }) => <input
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field}
                  placeholder={"Enter Number"}

                  type={"number"} />}
              />
            </div>
          </div>
          <div className={"d-flex justify-space-between"}>
            <div style={{ display: "grid", width: "49%" }}>
              <Label className={"side-drawer-field__label"}>Fuel level Before</Label>
              <Controller
                name="fuellevelbefore"
                control={control}
                rules={{ required: false }}
                render={({ field }) => <input
                  placeholder={"Enter Number"}
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field}
                  type={"number"} />}
              />
            </div>
            <div style={{ display: "grid", width: "49%" }}>
              <Label className={"side-drawer-field__label"}>Fuel level After</Label>
              <Controller
                name="fuellevelafter"
                control={control}
                rules={{ required: false }}
                render={({ field }) => <input
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field}
                  placeholder={"Enter Number"}

                  type={"number"} />}
              />
            </div>
          </div>


          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}>Driving Instructions</Label>
          <Controller
            name="drivingInstructions"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <Textarea
              placeholder={"Enter Driving Instructions"}
              className={"side-drawer-field-Textarea  side-drawer-field__gap side-drawer-field__gap"} {...field}
              resize="vertical" />}
          />

          <Controller
            name="leaveNotFueled"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={field.value}
                onChange={field.onChange}
                label="Leave Not Fueled"
              />
            )}
          />

          <Controller
            name="carWash"
            control={control}
            render={({ field }) => (
              <Checkbox
                {...field}
                checked={field.value}
                onChange={field.onChange}
                label="Car wash"
              />
            )}
          />
          <Label className={"side-drawer-field__label"} style={{ marginTop: 24 }}>Wash Price </Label>
          <Controller
            name="rentalCarWashPrice"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <input
              placeholder={"Enter Wash Price"}
              className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}{...field} />}
          />

          <Divider className={"side-drawer-divider-gap"} />
          <div className={"d-flex"} style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}>
            <div><InsuranceSVG /></div>
            <Label className={"side-drawer-section__typo"} style={{ marginLeft: "8px" }}>INSURANCE</Label>
          </div>
          {/*<Label style={{fontWeight: "bold"}}>Insurance</Label>*/}
          <Label className={"side-drawer-field__label"}>PolicyHolder Name</Label>
          <Controller
            name="insuranceInfo.policyHolderName"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <input
              placeholder={"Enter Policy Holder Name"}
              className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}{...field} />}
          />
          <Label className={"side-drawer-field__label"}>Insurance Company</Label>
          <Controller
            name="insuranceInfo.insuranceCompany"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <input
              placeholder={"Enter Insurance Company"}
              className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}{...field} />}
          />
          <Label className={"side-drawer-field__label"}>Policy Number</Label>
          <Controller
            name="insuranceInfo.policyNumber"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <input
              placeholder={"Enter Policy Number"}
              className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}{...field} />}
          />
          <Label className={"side-drawer-field__label"}>State</Label>
          <Controller
            name="insuranceInfo.state"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <input
              placeholder={"Enter State"}

              className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}{...field} />}
          />
          <Divider className={"side-drawer-divider-gap"} />

          <div className={"d-flex"} style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}>
            <div><IssueSVG /></div>
            <Label className={"side-drawer-section__typo"} style={{ marginLeft: "8px" }}>ISSUE</Label>
          </div>


          <Label className={"side-drawer-field__label"}>Issue Statement</Label>
          <Controller
            name="incidentIssues"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <input
              placeholder={"Enter Issue Statement"}
              className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}{...field} />}
          />
          <Label className={"side-drawer-field__label"}>Comments</Label>
          <Controller
            name="incidentComments"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <input
              placeholder={"Enter Comments"}
              className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}{...field} />}
          />
          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}>Issues Images</Label>
          <div>
            {images &&
              images.length > 0 &&
              images.map((img, index) => (
                <>
                  <Image
                    width={100}
                    height={100}
                    src={img}
                    alt="Main Image"
                  ></Image>
                  <Button
                    icon={<DeleteIcon />}
                    onClick={() => removeImages(index, setUploadedImages, setImages, uploadedImages, images)}
                  />
                </>
              ))}
          </div>
          {
            <label className="fileCtrlMultiple">
              <input
                type="file"
                id="file2"
                onChange={(e => (handleChangeImages(e, setUploadedImages, setImages, uploadedImages, images)))}
                accept="image/*"
                className="inputFileCtrl"
                aria-label="File browser example"
                multiple
              />
            </label>
          }
          <div className={"d-flex"} style={{ display: "flex", alignItems: "center", marginTop: "24px" }}>
            <Label className={"side-drawer-section__typo"} style={{}}>{'Before'.toUpperCase()}</Label>
          </div>
          <Label className={"side-drawer-field__label"}>Before Rental Front Images</Label>
          <div>
            {imagesFrontPreFuel &&
              imagesFrontPreFuel.length > 0 &&
              imagesFrontPreFuel.map((img, index) => (
                <>
                  <Image
                    width={100}
                    height={100}
                    src={img}
                    alt="Main Image"
                  ></Image>
                  <Button
                    icon={<DeleteIcon />}
                    onClick={() => removeImages(index, setUploadedImagesFrontPreFuel, setImagesFrontPreFuel, uploadedImagesFrontPreFuel, imagesFrontPreFuel)}
                  />
                </>
              ))}


          </div>
          {
            <label className="fileCtrlMultiple">
              <input
                type="file"
                id="file2"
                onChange={(e) => (handleChangeImages(e, setUploadedImagesFrontPreFuel, setImagesFrontPreFuel, uploadedImagesFrontPreFuel, imagesFrontPreFuel))}
                accept="image/*"
                className="inputFileCtrl"
                aria-label="File browser example"
                multiple
              />
            </label>
          }
          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}>Before Rental Back Images</Label>
          <div>
            {imagesBackPreFuel &&
              imagesBackPreFuel.length > 0 &&
              imagesBackPreFuel.map((img, index) => (
                <>
                  <Image
                    width={100}
                    height={100}
                    src={img}
                    alt="Main Image"
                  ></Image>
                  <Button
                    icon={<DeleteIcon />}
                    onClick={() => removeImages(index, setUploadedImagesBackPreFuel, setImagesBackPreFuel, uploadedImagesBackPreFuel, imagesBackPreFuel)}
                  />
                </>
              ))}


          </div>
          {
            <label className="fileCtrlMultiple">
              <input
                type="file"
                id="file2"
                onChange={(e) => (handleChangeImages(e, setUploadedImagesBackPreFuel, setImagesBackPreFuel, uploadedImagesBackPreFuel, imagesBackPreFuel))}
                accept="image/*"
                className="inputFileCtrl"
                aria-label="File browser example"
                multiple
              />
            </label>
          }

          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}>Before Rental Right Side
            Images</Label>
          <div>
            {imagesRightSidePreFuel &&
              imagesRightSidePreFuel.length > 0 &&
              imagesRightSidePreFuel.map((img, index) => (
                <>
                  <Image
                    width={100}
                    height={100}
                    src={img}
                    alt="Main Image"
                  ></Image>
                  <Button
                    icon={<DeleteIcon />}
                    onClick={() => removeImages(index, setUploadedImagesRightSidePreFuel, setImagesRightSidePreFuel, uploadedImagesRightSidePreFuel, imagesRightSidePreFuel)}
                  />
                </>
              ))}


          </div>
          {
            <label className="fileCtrlMultiple">
              <input
                type="file"
                id="file2"
                onChange={(e) => (handleChangeImages(e, setUploadedImagesRightSidePreFuel, setImagesRightSidePreFuel, uploadedImagesRightSidePreFuel, imagesRightSidePreFuel))}
                accept="image/*"
                className="inputFileCtrl"
                aria-label="File browser example"
                multiple
              />
            </label>
          }


          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}>Before Rental Left Side Images</Label>
          <div>
            {imagesLeftSidePreFuel &&
              imagesLeftSidePreFuel.length > 0 &&
              imagesLeftSidePreFuel.map((img, index) => (
                <>
                  <Image
                    width={100}
                    height={100}
                    src={img}
                    alt="Main Image"
                  ></Image>
                  <Button
                    icon={<DeleteIcon />}
                    onClick={() => removeImages(index, setUploadedImagesLeftSidePreFuel, setImagesLeftSidePreFuel, uploadedImagesLeftSidePreFuel, imagesLeftSidePreFuel)}
                  />
                </>
              ))}


          </div>
          {
            <label className="fileCtrlMultiple">
              <input
                type="file"
                id="file2"
                onChange={(e) => (handleChangeImages(e, setUploadedImagesLeftSidePreFuel, setImagesLeftSidePreFuel, uploadedImagesLeftSidePreFuel, imagesLeftSidePreFuel))}
                accept="image/*"
                className="inputFileCtrl"
                aria-label="File browser example"
                multiple
              />
            </label>
          }


          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}>Before Rental Other Images</Label>
          <div>
            {imagesOtherPreFuel &&
              imagesOtherPreFuel.length > 0 &&
              imagesOtherPreFuel.map((img, index) => (
                <>
                  <Image
                    width={100}
                    height={100}
                    src={img}
                    alt="Main Image"
                  ></Image>
                  <Button
                    icon={<DeleteIcon />}
                    onClick={() => removeImages(index, setUploadedImagesOtherPreFuel, setImagesOtherPreFuel, uploadedImagesOtherPreFuel, imagesOtherPreFuel)}
                  />
                </>
              ))}


          </div>
          {
            <label className="fileCtrlMultiple">
              <input
                type="file"
                id="file2"
                onChange={(e) => (handleChangeImages(e, setUploadedImagesOtherPreFuel, setImagesOtherPreFuel, uploadedImagesOtherPreFuel, imagesOtherPreFuel))}
                accept="image/*"
                className="inputFileCtrl"
                aria-label="File browser example"
                multiple
              />
            </label>
          }


          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}>Before Rental Mileage Images</Label>
          <div>
            {imagesMileagePreFuel &&
              imagesMileagePreFuel.length > 0 &&
              imagesMileagePreFuel.map((img, index) => (
                <>
                  <Image
                    width={100}
                    height={100}
                    src={img}
                    alt="Main Image"
                  ></Image>
                  <Button
                    icon={<DeleteIcon />}
                    onClick={() => removeImages(index, setUploadedImagesMileagePreFuel, setImagesMileagePreFuel, uploadedImagesMileagePreFuel, imagesMileagePreFuel)}
                  />
                </>
              ))}


          </div>
          {
            <label className="fileCtrlMultiple">
              <input
                type="file"
                id="file2"
                onChange={(e) => (handleChangeImages(e, setUploadedImagesMileagePreFuel, setImagesMileagePreFuel, uploadedImagesMileagePreFuel, imagesMileagePreFuel))}
                accept="image/*"
                className="inputFileCtrl"
                aria-label="File browser example"
                multiple
              />
            </label>
          }

          <div className={"d-flex"} style={{ display: "flex", alignItems: "center", marginTop: "24px" }}>
            <Label className={"side-drawer-section__typo"} style={{}}>{'After'.toUpperCase()}</Label>
          </div>
          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}>After Rental Front Images</Label>
          <div>
            {imagesFrontPostFuel &&
              imagesFrontPostFuel.length > 0 &&
              imagesFrontPostFuel.map((img, index) => (
                <>
                  <Image
                    width={100}
                    height={100}
                    src={img}
                    alt="Main Image"
                  ></Image>
                  <Button
                    icon={<DeleteIcon />}
                    onClick={() => removeImages(index, setUploadedImagesFrontPostFuel, setImagesFrontPostFuel, uploadedImagesFrontPostFuel, imagesFrontPostFuel)}
                  />
                </>
              ))}
          </div>
          {
            <label className="fileCtrlMultiple">
              <input
                type="file"
                id="file2"
                onChange={(e) => (handleChangeImages(e, setUploadedImagesFrontPostFuel, setImagesFrontPostFuel, uploadedImagesFrontPostFuel, imagesFrontPostFuel))}
                accept="image/*"
                className="inputFileCtrl"
                aria-label="File browser example"
                multiple
              />
            </label>
          }

          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}>After Rental Back Images</Label>
          <div>
            {imagesBackPostFuel &&
              imagesBackPostFuel.length > 0 &&
              imagesBackPostFuel.map((img, index) => (
                <>
                  <Image
                    width={100}
                    height={100}
                    src={img}
                    alt="Main Image"
                  ></Image>
                  <Button
                    icon={<DeleteIcon />}
                    onClick={() => removeImages(index, setUploadedImagesBackPostFuel, setImagesBackPostFuel, uploadedImagesBackPostFuel, imagesBackPostFuel)}
                  />
                </>
              ))}


          </div>
          {
            <label className="fileCtrlMultiple">
              <input
                type="file"
                id="file2"
                onChange={(e) => (handleChangeImages(e, setUploadedImagesBackPostFuel, setImagesBackPostFuel, uploadedImagesBackPostFuel, imagesBackPostFuel))}
                accept="image/*"
                className="inputFileCtrl"
                aria-label="File browser example"
                multiple
              />
            </label>
          }

          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}>After Rental Right Side Images</Label>
          <div>
            {imagesRightSidePostFuel &&
              imagesRightSidePostFuel.length > 0 &&
              imagesRightSidePostFuel.map((img, index) => (
                <>
                  <Image
                    width={100}
                    height={100}
                    src={img}
                    alt="Main Image"
                  ></Image>
                  <Button
                    icon={<DeleteIcon />}
                    onClick={() => removeImages(index, setUploadedImagesRightSidePostFuel, setImagesRightSidePostFuel, uploadedImagesRightSidePostFuel, imagesRightSidePostFuel)}
                  />
                </>
              ))}


          </div>
          {
            <label className="fileCtrlMultiple">
              <input
                type="file"
                id="file2"
                onChange={(e) => (handleChangeImages(e, setUploadedImagesRightSidePostFuel, setImagesRightSidePostFuel, uploadedImagesRightSidePostFuel, imagesRightSidePostFuel))}
                accept="image/*"
                className="inputFileCtrl"
                aria-label="File browser example"
                multiple
              />
            </label>
          }


          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}>After Rental Left Side Images</Label>
          <div>
            {imagesLeftSidePostFuel &&
              imagesLeftSidePostFuel.length > 0 &&
              imagesLeftSidePostFuel.map((img, index) => (
                <>
                  <Image
                    width={100}
                    height={100}
                    src={img}
                    alt="Main Image"
                  ></Image>
                  <Button
                    icon={<DeleteIcon />}
                    onClick={() => removeImages(index, setUploadedImagesLeftSidePostFuel, setImagesLeftSidePostFuel, uploadedImagesLeftSidePostFuel, imagesLeftSidePostFuel)}
                  />
                </>
              ))}


          </div>
          {
            <label className="fileCtrlMultiple">
              <input
                type="file"
                id="file2"
                onChange={(e) => (handleChangeImages(e, setUploadedImagesLeftSidePostFuel, setImagesLeftSidePostFuel, uploadedImagesLeftSidePostFuel, imagesLeftSidePostFuel))}
                accept="image/*"
                className="inputFileCtrl"
                aria-label="File browser example"
                multiple
              />
            </label>
          }


          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}> After Rental Other Images</Label>
          <div>
            {imagesOtherPostFuel &&
              imagesOtherPostFuel.length > 0 &&
              imagesOtherPostFuel.map((img, index) => (
                <>
                  <Image
                    width={100}
                    height={100}
                    src={img}
                    alt="Main Image"
                  ></Image>
                  <Button
                    icon={<DeleteIcon />}
                    onClick={() => removeImages(index, setUploadedImagesOtherPostFuel, setImagesOtherPostFuel, uploadedImagesOtherPostFuel, imagesOtherPostFuel)}
                  />
                </>
              ))}


          </div>
          {
            <label className="fileCtrlMultiple">
              <input
                type="file"
                id="file2"
                onChange={(e) => (handleChangeImages(e, setUploadedImagesOtherPostFuel, setImagesOtherPostFuel, uploadedImagesOtherPostFuel, imagesOtherPostFuel))}
                accept="image/*"
                className="inputFileCtrl"
                aria-label="File browser example"
                multiple
              />
            </label>
          }


          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}>After Rental Mileage Images</Label>
          <div>
            {imagesMileagePostFuel &&
              imagesMileagePostFuel.length > 0 &&
              imagesMileagePostFuel.map((img, index) => (
                <>
                  <Image
                    width={100}
                    height={100}
                    src={img}
                    alt="Main Image"
                  ></Image>
                  <Button
                    icon={<DeleteIcon />}
                    onClick={() => removeImages(index, setUploadedImagesMileagePostFuel, setImagesMileagePostFuel, uploadedImagesMileagePostFuel, imagesMileagePostFuel)}
                  />
                </>
              ))}


          </div>
          {
            <label className="fileCtrlMultiple">
              <input
                type="file"
                id="file2"
                onChange={(e) => (handleChangeImages(e, setUploadedImagesMileagePostFuel, setImagesMileagePostFuel, uploadedImagesMileagePostFuel, imagesMileagePostFuel))}
                accept="image/*"
                className="inputFileCtrl"
                aria-label="File browser example"
                multiple
              />
            </label>
          }

          <Label> Signature Image</Label>
          <div>
            {
              rentalAgreeSignUrl && rentalAgreeSignUrl.length > 0 && (
                <>
                  <Image width={100} height={100} src={rentalAgreeSignUrl[0]} alt="Sign Image"></Image>
                  <Button icon={<DeleteIcon />} onClick={() => removeSignImages(0)} />
                </>)
            }
          </div>
          {

            <label className="fileCtrlMultiple">
              <input type="file" id="file2" onChange={handleChangeSignImages} accept="image/*" className="inputFileCtrl" aria-label="File browser example" multiple />
            </label>
          }

          <div className="btnActions">
            <button
              appearance="primary"
              type="submit"
              className="form-submit-btn"
            >
              {props.selectedRental ? "Update" : "Submit"}
            </button>
            <button
              className="form-submit-cancel"
              onClick={() => props.setIsOpen(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </DrawerBody>
    </DrawerOverlay>
  );
}

// Function to check if an object's date range falls within the given date range,
// ignoring the comparison for a specific id
function doesDateRangeOverlap(
  startDate,
  endDate,
  givenStartDate,
  givenEndDate,
  id,
  idToIgnore
) {
  return (
    startDate <= givenEndDate && endDate >= givenStartDate && idToIgnore !== id
  );
}

export default RentalForm;
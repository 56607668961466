import {
    Button,
    Image,
    Table, TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    makeStyles, shorthands
} from "@fluentui/react-components";
import { DeleteFilled, DeleteRegular, EditFilled, EditRegular, bundleIcon } from "@fluentui/react-icons";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import AppSettingForm from "./components/Forms/AppSettingForm";
import Layout from "./components/Layout";
import appSettingDataService from "./services/appSettingDataService";
import { auth, trackException } from "./services/firebase";
import { EditSVG } from "./static/svgs";

const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

function AppSettings() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [mainImage, setMainImage] = useState('');
    const [mobileData, setMobiledata] = useState(null);
    const [balckoutDates, setBlackoutDates] = useState('');
    const [balckoutDatesDisplay, setBlackoutDatesDisplay] = useState('');

    const navigate = useNavigate();


    const fetchOptions = async () => {
        try {
            const mobileData = await appSettingDataService.getAppSetting('Mobile');
            if (mobileData.data()) {
                const dataObj = mobileData.data();
                setMobiledata(dataObj)
                setMainImage(dataObj.homeMainImage)
                if (dataObj.balckoutdate) {
                    setBlackoutDates(dataObj.balckoutdate)
                    if (dataObj.balckoutdate && dataObj.balckoutdate.trim() !== '') {
                        let datesArray = dataObj.balckoutdate.split(',');
                        let formattedDates = datesArray.join(', ');
                        setBlackoutDatesDisplay(formattedDates)
                    } else {
                        console.log("No blackout dates specified.");
                    }
                } else {
                    setBlackoutDatesDisplay('');
                }
            }
        } catch (error) {
            console.error(error);
            trackException(JSON.stringify(error))
        }
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        fetchOptions();
    }, [user, loading]);

    const openForm = (selectedItem) => {
        setIsFormOpen(true);
    }

    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <Button icon={<EditSVG />} className={"primary-btn"} shape="circular" appearance="primary" onClick={() => openForm(undefined)}>{'Edit'.toUpperCase()}</Button>
                </div>
                <div>
                    <Table aria-label="Table with cell actions">
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell key={'main'}>
                                    <b>Main Image</b>
                                </TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            <TableRow key={'main'}>
                                <TableCell>
                                    <TableCellLayout>
                                        <div>
                                            {mainImage && mainImage != "" &&
                                                <Image style={{ marginTop: 10, marginBottom: 10, borderRadius: 8 }} width={130} height={150} src={mainImage} alt="Main Image"></Image>
                                            }
                                        </div>
                                    </TableCellLayout>
                                </TableCell>
                            </TableRow>
                            <TableRow key={'blackOutDateMain'}>
                                <TableCell>
                                    <TableCellLayout>
                                        <div>
                                            <b>Blackout Date: </b>
                                        </div>
                                        <div>
                                            <label>{balckoutDatesDisplay}</label>
                                        </div>
                                    </TableCellLayout>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </div>
            <AppSettingForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)} selectedItem={mobileData}
                updateGrid={fetchOptions} initialSelectedDates={balckoutDates}></AppSettingForm>
        </Layout>
    )
}

export default AppSettings;
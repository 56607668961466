import LeftNav from "./LeftNav";
import TopNav from "./TopNav";
import {
    makeStyles, shorthands
} from "@fluentui/react-components";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe("pk_test_51KcFNSFpRuy7PMmyjeIi2vz9OXskYPgFZX7EykVx17FTDwtrpQhzEpTjG13ceMcJaGU7Vc9lUk5dJf25mUWzSobm00wdAnPAz5");

const useStyles = makeStyles({
    root: {
        backgroundColor: "#fff",

        display: 'grid',
        gridTemplateAreas: `
        "header header header header header header"
        "menu main main main main main"
        `
    },
    contentcontainer: {
    },
    childLayout:{
        height: "calc(100vh - 48px)",
        overflow: "auto !important"
    }
});

function Layout({ children }) {
    const styles = useStyles();
    return (
        <div className={styles.root}>
            <TopNav></TopNav>
            <LeftNav></LeftNav>
            {/* {children} */}
            <div style={{ height: "calc(100vh - 48px)", overflow: "auto" }}>
                <Elements stripe={stripePromise}>{children}</Elements>
            </div>
        </div>)
}

export default Layout;
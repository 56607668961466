import {
    makeStyles, shorthands, Persona, tokens, Image
} from "@fluentui/react-components";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../services/firebase";
import userDataService from "../services/userDataService";
import {useDispatch, useSelector} from "react-redux";
import {toggleSidebar} from "../slices/layoutSlice";
import {HamBurgerICON} from "../static/svgs";

const useStyles = makeStyles({
    root: {
        ...shorthands.border("1px", "solid", "#D1D1D1"),
        ...shorthands.overflow("hidden"),
        backgroundColor: '#FFFFFF',
        ...shorthands.gridArea("header"),
        display:"flex",
        justifyContent:"space-between",
        borderBottom: "1px solid #D1D1D1",
        gap: '0px',
        position: "sticky",
        top: 0,
        zIndex: 1000
        // border: '0px 0px 1px 0px',
        // textAlign: "right"
    },
    header:{
        fontFamily: 'Raleway',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '28px',
        marginLeft:"8px",
        textAlign: 'left'
    },
    logo:{
      justifyContent:"start"
    },
    profile:{
        display:"flex",
        justifyContent:"end"
    }

});

function TopNav() {
    const navigate = useNavigate();
    const layoutState = useSelector(state=>state.layout)
    const dispatch = useDispatch()
    const [user, loading, error] = useAuthState(auth);
    const styles = useStyles();
    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
    }, [user, loading]);

    return (
        <div className={styles.root}>

            <div className={` vertical-center  d-flex prevent-selection ${styles['logo']}`}>
                <div className={"pointer vertical-center"} onClick={() => {
                    dispatch(toggleSidebar(!layoutState.isSidebarOpen));
                }}><HamBurgerICON/>

                </div>
                <Image width={52} height={52} src="/Alogo.png" />
                <span className={`${styles['header']} `}>Warehouse Admin Panel</span>
            </div>
            <div className={`${styles["profile"]}   vertical-center `}>
                <Persona
                    size="medium"
                    name={user?.email}
                    avatar={{ color: "colorful" }}
                    presence={{ status: "available" }}
                    className="personatexthide"
                />
            </div>
        </div>
    )
}

export default TopNav;
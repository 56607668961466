import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, getDocs, query, where, orderBy, startAfter, limit } from "firebase/firestore";
import { db } from "./firebase";

const bookingCollection = collection(db, "Booking");
const serviceCollection = collection(db, "shServiceRecords");

class BookingDataService {

    addBooking = (newBooking) => {
        return addDoc(bookingCollection, newBooking);
    }

    updateBooking = (id, updatedBooking) => {
        const bookingDoc = doc(db, "Booking", id);
        return updateDoc(bookingDoc, updatedBooking);
    }

    deleteBooking = (id) => {
        const bookingDoc = doc(db, "Booking", id);
        return deleteDoc(bookingDoc);
    }

    getAllBooking = () => {
        return getDocs(bookingCollection)
    }

    getAllVehicleService = () => {
        const q = query(collection(db, "Booking"), where("bookingType", "==", "VehicleService"));
        return getDocs(q);
    }

    getAllVehicleService2 = () => {
        //const q = query(collection(db, "shServiceRecords"), where("bookingType", "==", "VehicleService"));
        //return getDocs(q);
        return getDocs(serviceCollection)
    }
    getAllVehicleServiceWithPage = async (pageSize, startAfterdt) => {
        let q = query(serviceCollection, orderBy("createdTimestamp", 'desc')); // Initialize query
        if (startAfterdt) {
            q = query(q, startAfter(startAfterdt)); // Corrected: Use query to chain methods
        }
        if (pageSize) {
            q = query(q, limit(pageSize)); // Corrected: Use query to chain methods
        }
        const snapshot = await getDocs(q);
        const events = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        const lastVisible = snapshot.docs[snapshot.docs.length - 1];
        return { events, lastVisible };
    };

    getAllRoom = () => {
        const q = query(collection(db, "Booking"), where("bookingType", "==", "Room"));
        return getDocs(q);
    }

    getAllRoomsWithPage = async (pageSize, startAfterdt) => {
        let q = query(bookingCollection, orderBy("createdDate", 'desc')); // Initialize query
        q = query(bookingCollection, where("bookingType", "==", "Room"));
        if (startAfterdt) {
            q = query(q, startAfter(startAfterdt)); // Corrected: Use query to chain methods
        }
        if (pageSize) {
            q = query(q, limit(pageSize)); // Corrected: Use query to chain methods
        }
        const snapshot = await getDocs(q);
        const events = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        console.log(events)
        const lastVisible = snapshot.docs[snapshot.docs.length - 1];
        return { events, lastVisible };
    };

    getAllCoach = () => {
        const q = query(collection(db, "Booking"), where("bookingType", "==", "Coach"));
        return getDocs(q);
    }

    getBooking = (id) => {
        const bookingDoc = doc(db, "Booking", id);
        return getDoc(bookingDoc);
    }

}

export default new BookingDataService();
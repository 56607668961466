import {
    Button,
    Image,
    Label,
    makeStyles, shorthands
} from "@fluentui/react-components";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { CalendarCancelFilled, DeleteFilled, DeleteRegular, Dismiss24Regular, RocketRegular, bundleIcon } from "@fluentui/react-icons";
import {
    getDownloadURL,
    ref,
    uploadBytesResumable
} from "firebase/storage";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-multi-date-picker";
import appSettingDataService from "../../services/appSettingDataService";
import { storage } from "../../services/firebase";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialCoachMemberState = {
    "homeMainImage": "",
    "golfImage": "",
    "racingSimulatorImage": "",
    "vehicalCarWashPrice": "",
    "gasPrice": ""
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

function AppSettingForm(props) {
    const styles = useStyles();
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedItem ? props.selectedItem : InitialCoachMemberState
    });
    const [images, setImages] = useState(props.selectedItem ? props.selectedItem.images : []);
    const [uploadedImages, setUploadedImages] = useState([])
    const [golfImages, setGolfImages] = useState(props.selectedItem ? props.selectedItem.golfImage : []);
    const [uploadedGolfImages, setUploadedGolfImages] = useState([]);
    const [racingSimulatorImages, setRacingSimulatorImages] = useState(props.selectedItem ? props.selectedItem.racingSimulatorImage : []);
    const [uploadedRacingSimulatorImages, setUploadedRacingSimulatorImages] = useState([]);

    const [percent, setPercent] = useState(0);
    const [valueDate, setValueDate] = useState([]);

    useEffect(() => {
    }, [valueDate])

    const onSubmit = async formdata => {
        var data = formdata;
        if (images && images.length > 0) {
            data.homeMainImage = images[0]
        }
        if (golfImages && golfImages.length > 0) {
            data.golfImage = golfImages[0]
        }
        if (racingSimulatorImages && racingSimulatorImages.length > 0) {
            data.racingSimulatorImage = racingSimulatorImages[0]
        }
        if (valueDate) {
            data.balckoutdate = valueDate.toString();
        }

        if (uploadedImages.length > 0) {
            data.homeMainImage = await uploadImage(uploadedImages[0], '/mobile/headerimage.png');
        }
        if (uploadedGolfImages.length > 0) {
            data.golfImage = await uploadImage(uploadedGolfImages[0], '/mobile/golfImage.png');
        }
        if (uploadedRacingSimulatorImages.length > 0) {
            data.racingSimulatorImage = await uploadImage(uploadedRacingSimulatorImages[0], '/mobile/racingSimulatorImage.png');
        }

        if (props.selectedItem) {
            const addeddata = await appSettingDataService.updateAppSettings('Mobile', data);
        } else {
            const addeddata = await appSettingDataService.updateAppSettings('Mobile', data);
        }

        props.setIsOpen(false);
        props.updateGrid();
    }

    const uploadImage = (image, storagePath) => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, storagePath);
            const uploadTask = uploadBytesResumable(storageRef, image);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setPercent(percent);
                },
                (err) => reject(err),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        resolve(url);
                    }).catch((error) => reject(error));
                }
            );
        });
    };

    const handleChangeImages = (event) => {
        const choosenFile = Array.prototype.slice.call(event.target.files);
        const uploaded = [];
        const tempimages = [];
        choosenFile.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                tempimages.push(URL.createObjectURL(event.target.files[0]));
                uploaded.push(file);
            }
        });
        setImages(tempimages);
        setUploadedImages(uploaded);
    }

    const removeImages = (index) => {
        var tempuploadedImages = [...uploadedImages];
        tempuploadedImages.splice(index, 1);
        setUploadedImages(tempuploadedImages);
        var tempimages = [...images];
        tempimages.splice(index, 1);
        setImages(tempimages);
    }

    const handleChangeGolfImages = (event) => {
        const choosenFile = Array.prototype.slice.call(event.target.files);
        const uploaded = [];
        const tempImages = [];
        choosenFile.forEach((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                tempImages.push(URL.createObjectURL(file));
                uploaded.push(file);
            }
        });
        setGolfImages(tempImages);
        setUploadedGolfImages(uploaded);
    }

    const removeGolfImages = (index) => {
        const tempUploadedImages = [...uploadedGolfImages];
        tempUploadedImages.splice(index, 1);
        setUploadedGolfImages(tempUploadedImages);
        const tempImages = [...golfImages];
        tempImages.splice(index, 1);
        setGolfImages(tempImages);
    }

    const handleChangeRacingSimulatorImages = (event) => {
        const choosenFile = Array.prototype.slice.call(event.target.files);
        const uploaded = [];
        const tempImages = [];
        choosenFile.forEach((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                tempImages.push(URL.createObjectURL(file));
                uploaded.push(file);
            }
        });
        setRacingSimulatorImages(tempImages);
        setUploadedRacingSimulatorImages(uploaded);
    }

    const removeRacingSimulatorImages = (index) => {
        const tempUploadedImages = [...uploadedRacingSimulatorImages];
        tempUploadedImages.splice(index, 1);
        setUploadedRacingSimulatorImages(tempUploadedImages);
        const tempImages = [...racingSimulatorImages];
        tempImages.splice(index, 1);
        setRacingSimulatorImages(tempImages);
    }

    useEffect(() => {
        const fields = ['homeMainImage', 'vehicalCarWashPrice', 'gasPrice'];
        if (props.selectedItem) {
            setImages([props.selectedItem.homeMainImage]);
            setGolfImages([props.selectedItem.golfImage]);
            setRacingSimulatorImages([props.selectedItem.racingSimulatorImage]);
            setValueDate(convertStringToDateArray(props.selectedItem.balckoutdate));
            fields.forEach((field) =>
                setValue(field,
                    props.selectedItem[field] ? props.selectedItem[field] : InitialCoachMemberState[field]
                )
            );

        } else {
            fields.forEach(field => setValue(field, InitialCoachMemberState[field]));
            setImages([]);
        }
    }, [props.selectedItem, props.isOpen])

    useEffect(() => {
    }, [])

    function convertStringToDateArray(dateString) {
        // Handle potential errors (e.g., invalid date formats)
        try {
            return dateString.split(",").map(date => date.trim());
        } catch (error) {
            console.error("Error converting string to date array:", error);
            return []; // Return an empty array on error
        }
    }

    return (
        <DrawerOverlay
            size={"medium"}
            position={"right"}
            open={props.isOpen}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    {props.selectedItem ? "Edit" : "New"}
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <form onSubmit={handleSubmit(onSubmit)}
                    className={styles.root} onReset={reset} id={"New"}>
                    <Label className={"side-drawer-field__label"}>Car Wash Price </Label>
                    <Controller
                        name="vehicalCarWashPrice"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input placeholder={"Enter Wash Price"} type="number"
                            onWheel={(e) => e.target.blur()}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
                    />
                    <Label className={"side-drawer-field__label"}>Gas Price </Label>
                    <Controller
                        name="gasPrice"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input placeholder={"Empty gas price"} type="number"
                            onWheel={(e) => e.target.blur()}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
                    />

                    <Label> Main Image (504*428)</Label>
                    <div>
                        {
                            images && images.length > 0 && (
                                <>
                                    <Image width={100} height={110} src={images[0]} alt="Main Image"></Image>
                                    <Button icon={<DeleteIcon />} onClick={() => removeImages(0)} />
                                </>)
                        }
                    </div>
                    {

                        <label className="fileCtrlMultiple">
                            <input type="file" id="file2" onChange={handleChangeImages} accept="image/*" className="inputFileCtrl" aria-label="File browser example" multiple />
                        </label>
                    }
                    <Label> Golf Image</Label>
                    <div>
                        {
                            golfImages && golfImages.length > 0 && (
                                <>
                                    <Image width={100} height={100} src={golfImages[0]} alt="Golf Image"></Image>
                                    <Button icon={<DeleteIcon />} onClick={() => removeGolfImages(0)} />
                                </>)
                        }
                    </div>
                    {

                        <label className="fileCtrlMultiple">
                            <input type="file" id="file2" onChange={handleChangeGolfImages} accept="image/*" className="inputFileCtrl" aria-label="File browser example" multiple />
                        </label>
                    }

                    <Label> Racing Simulator Image</Label>
                    <div>
                        {
                            racingSimulatorImages && racingSimulatorImages.length > 0 && (
                                <>
                                    <Image width={100} height={100} src={racingSimulatorImages[0]} alt="Golf Image"></Image>
                                    <Button icon={<DeleteIcon />} onClick={() => removeRacingSimulatorImages(0)} />
                                </>)
                        }
                    </div>
                    {

                        <label className="fileCtrlMultiple">
                            <input type="file" id="file2" onChange={handleChangeRacingSimulatorImages} accept="image/*" className="inputFileCtrl" aria-label="File browser example" multiple />
                        </label>
                    }

                    <div>
                        <label className={styles.root}>BlackOut Days</label>
                        <DatePicker
                            multiple
                            initialSelectedDates={valueDate}
                            value={valueDate}
                            onChange={setValueDate}
                        />
                    </div>
                    <div className="btnActions">
                        <Button appearance="primary" type="submit" className="btnSubmit" icon={<RocketRegular />}>
                            {props.selectedItem ? 'Update' : 'Submit'}</Button>
                        <Button className="btnCancel" icon={<CalendarCancelFilled />}
                            onClick={() => props.setIsOpen(false)}>Cancel</Button>
                    </div>
                </form>
            </DrawerBody>
        </DrawerOverlay>
    )
}

export default AppSettingForm
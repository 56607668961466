import React, {useEffect, useState} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom";
import {auth, logInWithEmailAndPassword, sendPasswordReset} from "./services/firebase";
import {
    sendPasswordResetEmail
} from "firebase/auth"
import "./Reset.css";
import {EyeViewDisableSVG, EyeViewSVG, LockSVG, LoginSvgs} from "./static/svgs";
import {Button, Input} from "@fluentui/react-components";

function Reset() {
    const [email, setEmail] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (loading) return;
        if (user) navigate("/dashboard");
    }, [user, loading]);

    async function resetPassword() {
        try {
            let res = await sendPasswordReset(email)
            if (res) {
                alert("Reset password link sent to registered email.")
                navigate("/")
            }
        } catch (e) {
            console.log("e->", e)
            alert(e)
        }
    }

    return (
        <div className="login">
            <div className={"d-flex margin-padding-none"}>
                <img src={'/images/loginLogo.png'}/>
                <div className={'login_logo__label '}>Warehouse Admin Panel</div>
            </div>
            <div className={"login__card"}>
            <div className="login__container">
                <div className={"login_row__container d-flex justify-center"}>
                    <LockSVG/>
                </div>
                <label className={"forgot-password__header vertical-center"}>FORGOT PASSWORD</label>
                <span className={"forgot_note"}>Enter your email and we will send you password reset email</span>

                <label className={"login_form__label login-fields__container"}>Email</label>
                <Input
                    type="text"
                    className="login_field"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="E-mail Address"
                />


                <Button
                    className="login__btn"
                    onClick={() => resetPassword()}
                >
                    SEND EMAIL
                </Button>
                <div>
                    <Link to="/" className={"forgot_password_label vertical-center"}>

                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M10.5 12.5L5.66518 8.14866C5.5769 8.06921 5.5769 7.93079 5.66518 7.85134L10.5 3.5"
                                  stroke="#565656" stroke-width="1.5" stroke-linecap="round"/>
                        </svg>

                        <span >Back to Login</span>
                        </Link>

                </div>
                {/*<Button*/}
                {/*    className="login__btn "*/}

                {/*    // onClick={() => logInWithEmailAndPassword(email, password)}*/}
                {/*>*/}
                {/*    Login*/}
                {/*    /!*<Spinner label="Loading..." />*!/*/}
                {/*</Button>*/}

            </div>
            </div>


        </div>
    );
}

export default Reset;


// <div className="login__card">
//     <input
//         type="text"
//         className="reset__textBox"
//         value={email}
//         onChange={(e) => setEmail(e.target.value)}
//         placeholder="E-mail Address"
//     />
//     <button
//         className="reset__btn"
//         onClick={() => resetPassword()}
//     >
//         Send password reset email
//     </button>
// </div>
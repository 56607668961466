import {
    Button,
    Dropdown,
    Image, Input, Label,
    Option,
    Textarea,
    makeStyles, shorthands
} from "@fluentui/react-components";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { CalendarCancelFilled, DeleteFilled, DeleteRegular, Dismiss24Regular, RocketRegular, bundleIcon } from "@fluentui/react-icons";
import {
    getDownloadURL,
    ref,
    uploadBytesResumable
} from "firebase/storage";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CoachDataService from "../../services/coachDataService";
import { storage } from "../../services/firebase";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialCoachMemberState = {
    "memberId": "",
    "firstName": "",
    "lastName": "",
    "bio": "",
    "coachType": "",
    "id": "",
    "coachImage": "",
    "images": [],
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

const coachTypes = [
    { "id": "Golf coaching", "name": "Golf coaching" },
    { "id": "Racing Simulator coaching", "name": "Racing Simulator coaching" }
]

function AddCoachForm(props) {
    const styles = useStyles();
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedCoach ? props.selectedCoach : InitialCoachMemberState
    });
    const [images, setImages] = useState(props.selectedCoach ? props.selectedCoach.images : []);
    const [uploadedImages, setUploadedImages] = useState([])
    const [percent, setPercent] = useState(0);
    const [selectedMemberPlaceholder, setSeletedMemberPlaceholder] = useState('');

    const onSubmit = async formdata => {
        var data = formdata;
        if (images && images.length > 0) {
            data.images = images
        }
        var id = "";
        if (props.selectedCoach) {
            id = props.selectedCoach.id;
            await CoachDataService.updateCoach(props.selectedCoach.id, data);
        } else {
            const addeddata = await CoachDataService.addCoach(data);
            id = addeddata.id;
        }
        if (uploadedImages.length > 0) {
            uploadedImages.map((image) => {
                const storageRef = ref(storage, `/coach/${Date.now()}_${image.name}`);
                const uploadTask = uploadBytesResumable(storageRef, image);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                        // update progress
                        setPercent(percent);
                    },
                    (err) => console.log(err),
                    () => {
                        // download url
                        getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
                            data.images[0] = url;
                            data.id = id;
                            data.coachImage = url;
                            await CoachDataService.updateCoach(id, data);
                            props.setIsOpen(false);
                        });
                    }
                );
            })
        }
        props.setIsOpen(false);
        props.updateGrid();
    }

    const handleChangeImages = (event) => {
        const choosenFile = Array.prototype.slice.call(event.target.files);
        const uploaded = [...uploadedImages];
        const tempimages = [...images];
        choosenFile.some((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                tempimages.push(URL.createObjectURL(event.target.files[0]));
                uploaded.push(file);
            }
        });
        setImages(tempimages);
        setUploadedImages(uploaded);
    }

    const removeImages = (index) => {
        var tempuploadedImages = [...uploadedImages];
        tempuploadedImages.splice(index, 1);
        setUploadedImages(tempuploadedImages);
        var tempimages = [...images];
        tempimages.splice(index, 1);
        setImages(tempimages);
    }

    useEffect(() => {
        const fields = ['id', 'firstName', 'lastName', 'coachType', 'bio'];
        if (props.selectedCoach) {
            fields.forEach(field => setValue(field, props.selectedCoach[field]));
            setImages(props.selectedCoach.images);
            let selectedMember = props.users.filter(obj => obj.id == props.selectedCoach.memberId);
            if (selectedMember[0] && selectedMember[0].firstName) {
                let str = `${selectedMember[0].firstName} ${selectedMember[0].lastName}`
                setSeletedMemberPlaceholder(str);
            } else {
                setSeletedMemberPlaceholder('');
            }
        } else {
            fields.forEach(field => setValue(field, InitialCoachMemberState[field]));
            setSeletedMemberPlaceholder('');
            setImages([]);
        }
    }, [props.selectedCoach])

    function updateSelectedMember(selobj) {
        let selectedMember = props.users.filter(obj => obj.id == selobj[0]);
        setImages([selectedMember[0]?.profilePic]);
    }

    return (
        <DrawerOverlay
            size={"medium"}
            position={"right"}
            style={{ width: "504px" }}
            open={props.isOpen}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader   style={{
            background: "#F7F7F7",
            padding: "26px 32px"
        }}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    + {props.selectedCoach ? "Edit" : "New"} Coach
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <form onSubmit={handleSubmit(onSubmit)}
                      className={styles.root} onReset={reset} id={props.selectedCoach ? props.selectedCoach.id : "New"}>
                    <Label className={"side-drawer-field__label"} style={{marginTop: 32}}>
                        First Name*
                    </Label>
                    <Controller
                        name="firstName"
                        control={control}
                        rules={{required: true}}
                        render={({field}) => <input
                            placeholder={"Enter First Name"}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                            {...field} />}
                    />
                    <Label className={"side-drawer-field__label"}>
                        Last Name*
                    </Label>
                    <Controller
                        name="lastName"
                        control={control}
                        rules={{required: true}}
                        render={({field}) => <input
                            placeholder={"Enter First Name"}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                            {...field} />}
                    />
                    {/* <Label>
                        Member
                    </Label>
                    <Controller
                        name="memberId"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <Dropdown multiselect={false}
                            onOptionSelect={(ev, data) => {
                                field.onChange(data.selectedOptions[0])
                                updateSelectedMember(data.selectedOptions)
                            }}
                            selectedOptions={[field.value]}
                            placeholder={selectedMemberPlaceholder}
                        >
                            {props.users.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {`${option.firstName} ${option.lastName}`}
                                </Option>
                            ))}
                        </Dropdown>}
                    /> */}
                    <Label className={"side-drawer-field__label"}>
                        Coach Type*
                    </Label>
                    <Controller
                        name="coachType"
                        control={control}
                        rules={{required: true}}
                        render={({field}) => <Dropdown multiselect={false}
                                                       className={"side-drawer-field-dropdown  side-drawer-field__gap"}
                                                       placeholder={"Select Coach Type"}
                                                       onOptionSelect={(ev, data) => field.onChange(data.selectedOptions[0])}
                                                       selectedOptions={[field.value]}>
                            {coachTypes.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {option.name}
                                </Option>
                            ))}
                        </Dropdown>}
                    />
                    <Label className={"side-drawer-field__label"} style={{marginBottom:"8px"}}>
                        Bio*
                    </Label>
                    <Controller
                        name="bio"
                        control={control}
                        rules={{required: true}}
                        render={({field}) => <Textarea
                            placeholder={'Enter Bio'}
                            className={" side-drawer-field-Textarea side-drawer-field__gap side-drawer-field__gap"}
                            style={{borderRadius: "16px !important"}}
                            {...field} resize="vertical"/>}
                    />

                    <Label className={"side-drawer-field__label"}
                           style={{marginTop: 24, marginBottom: 8}}> Headshot</Label>
                    <div>
                        {
                            images && images.length > 0 && (
                                <>
                                    <Image width={100} height={100} src={images[0]} alt="Main Image"></Image>
                                    <Button icon={<DeleteIcon/>} onClick={() => removeImages(0)}/>
                                </>)
                        }
                    </div>
                    {

                        <label className="fileCtrlMultiple">
                            <input type="file" id="file2" onChange={handleChangeImages} accept="image/*"
                                   className="inputFileCtrl" aria-label="File browser example" multiple/>
                        </label>
                    }
                    <div style={{position: "absolute", bottom: 0, width: "90%"}}>
                        <div className="btnActions">
                            <button appearance="primary" type="submit" className="form-submit-btn pointer">
                                {props.selectedCoach ? 'Update' : 'Submit'}</button>
                            <button className="form-submit-cancel pointer"
                                    onClick={() => props.setIsOpen(false)}>Cancel
                            </button>
                        </div>
                    </div>
                </form>
            </DrawerBody>
        </DrawerOverlay>
)
}

export default AddCoachForm
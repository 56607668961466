import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "./firebase";

const vehiclesRequestCollection = collection(db, "VehiclesRequest");

class VehiclesRequestDataService {
    addVehiclesRequest = (newRental) => {
        return addDoc(vehiclesRequestCollection, newRental);
    }

    updateVehiclesRequest = (id, updateVehiclesRequest) => {
        const rentalDoc = doc(db, "VehiclesRequest", id);
        return updateDoc(rentalDoc, updateVehiclesRequest);
    }

    deleteVehiclesRequest = (id) => {
        const rentalDoc = doc(db, "VehiclesRequest", id);
        return deleteDoc(rentalDoc);
    }

    getAllVehiclesRequest = () => {
        const q = query(vehiclesRequestCollection, orderBy("createdAt", 'desc'));
        return getDocs(q);
    }

    getVehiclesRequest = (id) => {
        const rentalDoc = doc(db, "VehiclesRequest", id);
        return getDoc(rentalDoc);
    }
}

export default new VehiclesRequestDataService();
import { useEffect, useState } from "react";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay
} from "@fluentui/react-components/unstable";
import { Dismiss24Regular } from "@fluentui/react-icons";
import {
    Button, Image, Input, Label, makeStyles, shorthands, Textarea, Dropdown, Option
} from "@fluentui/react-components";
import { useForm, Controller } from "react-hook-form";
import { RocketRegular, CalendarCancelFilled } from "@fluentui/react-icons";
import { bundleIcon, DeleteFilled, DeleteRegular } from "@fluentui/react-icons";
import { DatePicker } from "@fluentui/react-datepicker-compat";
import GroupDataService from "../../services/groupDataService";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialGroupState = {
    "description": "",
    "title": "",
    "id": "",
    "valueMin": "",
    "valueMax": "",
    "pointsPerDay": 0,
    "pointsWeekend": 0,
    "mileageAllowed": 0,
    "overageMile": 0,
    "pricePerDay": 0,
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

function GroupForm(props) {
    const styles = useStyles();
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedGroup && typeof (props.selectedGroup) == "object" ? props.selectedGroup : InitialGroupState
    });

    const onSubmit = async formdata => {
        var data = formdata;
        var id = "";
        if (props.selectedGroup && typeof (props.selectedGroup) == "object") {
            id = props.selectedGroup.id;
            await GroupDataService.updateGroup(props.selectedGroup.id, data);
        } else {
            const addeddata = await GroupDataService.addGroup(data);
            id = addeddata.id;
            data.id = id
            await GroupDataService.updateGroup(id, data);
        }
        props.setIsOpen(false);
        props.updateGrid();
    }

    useEffect(() => {
        const fields = ['description', 'title', 'id', "valueMin", "valueMax", "pointsPerDay", "mileageAllowed", "overageMile", "pricePerDay", "pointsWeekend"];
        if (props.selectedGroup && typeof (props.selectedGroup) == "object") {
            fields.forEach(field => setValue(field, props.selectedGroup[field] ? props.selectedGroup[field] : InitialGroupState[field]));
        } else {
            fields.forEach(field => setValue(field, InitialGroupState[field]));
        }
    }, [props.selectedGroup])

    return (
        <DrawerOverlay
            size={"medium"}
            style={{ width: "504px" }}

            position={"right"}
            open={props.isOpen}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader  style={{
            background: "#F7F7F7",
            padding: "26px 32px"
        }}>
                <DrawerHeaderTitle
                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    + {props.selectedGroup ? "Edit" : "New"} Group
                </DrawerHeaderTitle>
            </DrawerHeader>
            <DrawerBody>
                <form onSubmit={handleSubmit(onSubmit)}
                    className={styles.root} onReset={reset} id={props.selectedGroup ? props.selectedGroup.id : "New"}>
                    <Label className={"side-drawer-field__label"} style={{ marginTop: "32px" }}>
                        Group Name*
                    </Label>
                    <Controller
                        name="title"

                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <input
                            placeholder={"Enter Group Name"}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                            {...field} />}
                    />
                    <div className={"d-flex justify-space-between"}>
                        <div style={{ display: "grid" ,width:"49%" }}>
                            <Label className={"side-drawer-field__label"}>
                                Value Min
                            </Label>
                            <Controller
                                name="valueMin"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input

                                    placeholder={"Enter Number"}
                                    className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                                    {...field} />}
                            />
                        </div>
                        <div style={{ display: "grid",width:"49%"  }}>
                            <Label className={"side-drawer-field__label"}>
                                Value Max
                            </Label>
                            <Controller
                                name="valueMax"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter Number"}
                                    className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                                    {...field} />}
                            />
                        </div>
                    </div>

                    <div className={"d-flex justify-space-between"}>
                        <div style={{display: "grid", width: "49%"}}>
                            <Label className={"side-drawer-field__label"}>
                                Points Per Day
                            </Label>
                            <Controller
                                name="pointsPerDay"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input


                                    placeholder={"Enter Number"}
                                    className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                                    {...field} />}
                            />
                        </div>
                        <div style={{display: "grid", width: "49%"}}>
                            <Label className={"side-drawer-field__label"}>
                                Points Weekend
                            </Label>
                            <Controller
                                name="pointsWeekend"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter Number"}
                                    className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                                    {...field} />}
                            />
                        </div>
                    </div>




                    <Label className={"side-drawer-field__label"}>
                        Overage Mile
                    </Label>
                    <Controller
                        name="overageMile"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input
                            placeholder={"Enter Overage Mile"}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                            {...field} />}
                    />
                    <Label className={"side-drawer-field__label"}>
                        Mileage Allowed
                    </Label>
                    <Controller
                        name="mileageAllowed"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input
                            placeholder={"Enter Mileage Allowed"}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                            {...field} />}
                    />
                    <Label className={"side-drawer-field__label"}>
                        Price Per Day $
                    </Label>
                    <Controller
                        name="pricePerDay"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input
                            placeholder={"Enter Price Per Day"}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                            {...field} />}
                    />
                    <Label className={"side-drawer-field__label"} style={{marginBottom:8}}>
                        Description
                    </Label>
                    <Controller
                        name="description"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <Textarea

                            placeholder={"Enter Description"}
                            className={"side-drawer-field-Textarea  side-drawer-field__gap side-drawer-field__gap"}
                            {...field} resize="vertical" />}
                    />
                    <div className="btnActions">
                        <button appearance="primary" type="submit" className="form-submit-btn">
                            {props.selectedGroup ? 'Update' : 'Submit'}</button>
                        <button className="form-submit-cancel"
                            onClick={() => props.setIsOpen(false)}>Cancel</button>
                    </div>
                </form>
            </DrawerBody>
        </DrawerOverlay>
    )
}

export default GroupForm
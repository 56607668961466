import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  userData: null,
  accessPermission: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
      if (action.payload.accessPermission) {
        state.accessPermission = action.payload.accessPermission
      }
    },
  },
});

export const getToken = (state) => state.auth.token;
export const getUserData = (state) => state.auth.userData;
export const getUserAccessPermission = (state) => state.auth.accessPermission;
export const { setToken, setUserData } = authSlice.actions;

export default authSlice.reducer;

import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase";

const restaurantCollection = collection(db, "Restaurants");

class RestaurantDataService {
    addRestaurant = (newRestaurant) => {
        return addDoc(restaurantCollection, newRestaurant);
    }

    updateRestaurant = (id, updatedRestaurant) => {
        const restaurantDoc = doc(db, "Restaurants", id);
        return updateDoc(restaurantDoc, updatedRestaurant);
    }

    deleteRestaurant = (id) => {
        const restaurantDoc = doc(db, "Restaurants", id);
        return deleteDoc(restaurantDoc);
    }

    getAllRestaurant = () => {
        return getDocs(restaurantCollection)
    }

    getRestaurant = (id) => {
        const restaurantDoc = doc(db, "Restaurants", id);
        return getDoc(restaurantDoc);
    }
}

export default new RestaurantDataService();
export class JSONManipulator {
    constructor(jsonData) {
        this.jsonData = jsonData;
    }

    setNestedValue(path, value) {
        let keys = path.split('.');
        let current = this.jsonData;
        for (let i = 0; i < keys.length - 1; i++) {
            if (!current[keys[i]]) {
                // If the current key doesn't exist, create an object or an array based on the next key
                current[keys[i]] = isNaN(keys[i + 1]) ? {} : [];
            }
            current = current[keys[i]];
        }
        current[keys[keys.length - 1]] = value;
    }

    returnModifiedJSON() {
        return this.jsonData;
    }
}
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "./firebase";

const vehicleCollection = collection(db, "Vehicle");

class VehicleDataService {
    addVehicle = (newVehicle) => {
        return addDoc(vehicleCollection, newVehicle);
    }

    updateVehicle = (id, updatedVehicle) => {
        const vehicleDoc = doc(db, "Vehicle", id);
        return updateDoc(vehicleDoc, updatedVehicle);
    }

    deleteVehicle = (id) => {
        const vehicleDoc = doc(db, "Vehicle", id);
        return deleteDoc(vehicleDoc);
    }

    getAllVehicle = () => {
        return getDocs(vehicleCollection)
    }

    getAllVehicleByUserId = (userId) => {
        const q = query(vehicleCollection, where("owner", "==", userId));
        return getDocs(q);
    }

    getRentalFleetVehicle = () => {
        const q = query(vehicleCollection, where("isPartOfWarehouseRentalFleet", "==", true));
        return getDocs(q);
    }

    getVehicle = (id) => {
        const vehicleDoc = doc(db, "Vehicle", id);
        return getDoc(vehicleDoc);
    }
}

export default new VehicleDataService();
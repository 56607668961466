import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase";

const coachCollection = collection(db, "CoachList");

class CoachDataService {
    addCoach = (newCoach) => {
        return addDoc(coachCollection, newCoach);
    }

    updateCoach = (id, updatedCoach) => {
        const coachDoc = doc(db, "CoachList", id);
        return updateDoc(coachDoc, updatedCoach);
    }

    deleteCoach = (id) => {
        const coachDoc = doc(db, "CoachList", id);
        return deleteDoc(coachDoc);
    }

    getAllCoachMember = () => {
        return getDocs(coachCollection)
    }

    getCoach = (id) => {
        const coachDoc = doc(db, "CoachList", id);
        return getDoc(coachDoc);
    }
}

export default new CoachDataService();
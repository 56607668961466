import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase";

const appSettingCollection = collection(db, "AppSettings");

class AppSettingDataService {

    updateAppSettings = (id, updateAppSetting) => {
        const restaurantDoc = doc(db, "AppSettings", id);
        return updateDoc(restaurantDoc, updateAppSetting);
    }
    
    getAllAppSettings = () => {
        return getDocs(appSettingCollection)
    }

    getAppSetting = (id) => {
        const settingDoc = doc(db, "AppSettings", id);
        return getDoc(settingDoc);
    }
}

export default new AppSettingDataService();
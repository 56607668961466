import {
    Button,
    Divider, Dropdown,
    Image, Label,
    Option,
    Switch,
    makeStyles, shorthands, Spinner,
    Combobox, Input
} from "@fluentui/react-components";
import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    DrawerOverlay,
} from "@fluentui/react-components/unstable";
import { CalendarCancelFilled, DeleteFilled, DeleteRegular, Dismiss24Regular, RocketRegular, bundleIcon, DocumentRegular, DocumentFilled } from "@fluentui/react-icons";
import {
    getDownloadURL,
    ref,
    uploadBytesResumable
} from "firebase/storage";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import ServiceProviderDataService from "../../services/ServiceProviderDataService";
import {
    Base_Url,
    apiRegisterWithEmailAndPassword2,
    registerWithEmailAndPassword2,
    storage,
    auth,
    createCustomerSubscriptionPOSTRequest, createOneTimePaymentWithProductIdRequest, trackException
} from "../../services/firebase";
import UserDataService from "../../services/userDataService";
import { useAuthState } from "react-firebase-hooks/auth";

import axios from "axios";
import "./UserForm.css"
import {
    AddressSVG,
    ConnectAccountSVG,
    DrivingLicenceSVG, EyeViewDisableSVG, EyeViewSVG,
    InsuranceSVG,
    PhoneSVG,
    UserFormAddSVG
} from "../../static/svgs";

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

export const InitialUserState = {
    "email": "",
    "firstName": "",
    "lastName": "",
    "phoneNumbers": [{ isPrimary: false, number: "", type: "" }],
    "isAdmin": false,
    "isHonorary": false,
    "memberNumber": "",
    "memberships": [],
    "addOn": [],
    "genderValue": "",
    "address": { city: "", country: "", state: "", street1: "", street2: "", zip: "" },
    "groupIds": [],
    'insuranceImage': '',
    'dlImage': '',
    insuranceInfo: {
        policyHolderName: '',
        insuranceCompany: '',
        policyNumber: '',
        state: ''
    },
    "dLNumber": '',
    "linkedUserIds": [],
    "accountHolderId": '',
    "accessPermission": [],
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        // Use 2px gap below the label (per the design system)
        ...shorthands.gap("2px")
    },
    control: {
        maxWidth: "300px",
    },
});

// const membershipsoptions = [
//     { "id": "Lift", "name": "Lift" },
//     { "id": "Ground", "name": "Ground" },
//     { "id": "Premium", "name": "Premium" }
// ]

const genderoptions = [
    { "id": "Male", "name": "Male" },
    { "id": "Female", "name": "Female" },
    { "id": "prefer_not_to_say", "name": "prefer not to say" }
]

const memberAccessPermission = [
    { "id": "Admin", "name": "Admin" },
    { "id": "Community", "name": "Community" },
    { "id": "Reservations", "name": "Reservations" },
    { "id": "Locations", "name": "Locations" },
    { "id": "People", "name": "People" },
    { "id": "AppSetting", "name": "AppSetting" },
    { "id": "POS", "name": "POS" }
]

function UserForm(props) {
    const styles = useStyles();
    const { handleSubmit, control, reset, setValue, formState: { isDirty, dirtyFields } } = useForm({
        defaultValues: props.selectedUser && typeof (props.selectedUser) == "object" ? props.selectedUser : InitialUserState
    });
    const [password, setPassword] = useState("");
    const [profilePic, setProfilePic] = useState(null);
    const [fileMainImage, setFileMainImage] = useState("");
    const [percent, setPercent] = useState(0);
    const [insuranceImage, setInsuranceImage] = useState(null);
    const [insuranceUploadImage, setInsuranceUploadImage] = useState(null);
    const [dlImage, setDlImage] = useState(null);
    const [dlUploadImage, setUploadDlImage] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [fileType, setFileType] = useState('');
    const [linkUserPlaceholder, setLinkUserPlaceholder] = useState('');
    const [matchingOptions, setMatchingOptions] = useState([]);
    const [isAccountHolder, setIsAccountHolder] = useState(false);
    const [parentAccountUser, setParentAccountUser] = useState(null);
    const [hasBankAndCard, setHasBankAndCard] = useState(false);
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [subscriptionAddOnsList, setSubscriptionAddOnsList] = useState([]);
    const [user, loading, error] = useAuthState(auth);
    const [selectedMembershipPlaceholder, setSeletedMembershipPlaceholder] = useState("");
    const [selectedGenderPlaceholder, setSelectedGenderPlaceholder] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(true);

    const { fields, append, remove } = useFieldArray({
        name: "phoneNumbers",
        control
    });


    const getStripeSubscriptionList = async () => {
        let config = {
            method: "get",
            url: `${Base_Url}/getStripeAllSubsriptionList`,
            headers: {
                "Content-Type": "application/json",
                "Acess-Control-Allow-Origin": "*",
                Authorization: `${user.accessToken}`,
                Accept: "application/json",
            },
        };
        axios
            .request(config)
            .then((response) => {
                if (response && response.data && response.data.data) {
                    // console.log(response.data);
                    //  setReaderList(response.data.data.data);
                    setSubscriptionList(response?.data?.data?.filter((data) => data?.metadata?.SubCategory !== "Addon"));
                    setSubscriptionAddOnsList(response?.data?.data?.filter((data) => data?.metadata?.SubCategory === "Addon"));

                }
            })
            .catch((error) => {
                console.log(error);
                trackException(JSON.stringify(error))
            });
    };

    const onSubmit = async formdata => {

        var data = formdata;
        const stripeCustomerId = props?.selectedUser?.stripeCustomerId

        /**As Discuss we will upload it later */
        // if (data && Array.isArray(data?.memberships) && data?.memberships.length > 0 && stripeCustomerId) {
        //     let filterIdsList = data?.memberships?.filter(value => !props?.selectedUser?.memberships?.includes(value));

        //     filterIdsList?.forEach((id) => {
        //         createCustomerSubscriptionPOSTRequest(id, stripeCustomerId)
        //     })
        // }

        if (data && Array.isArray(data.addOn) && data.addOn.length > 0 && stripeCustomerId) {
            try {
                for (const id of data.addOn) {
                    await createOneTimePaymentWithProductIdRequest(id, stripeCustomerId);
                }
            } catch (err) {
                trackException(JSON.stringify(err))
                // Handle errors here, such as logging, notifying the user, or taking other actions
                console.error('Error creating one-time payment:', err);
            }
        }

        data.email = data.email.toLowerCase()
        var id = "";
        setShowLoader(true)
        if (props.selectedUser && typeof (props.selectedUser) == "object" && props.selectedUser.id && props.selectedUser.id != "") {
            id = props.selectedUser.id;
            await UserDataService.updateUser(props.selectedUser.id, data);
            removePreviouseUserAccountHolder(data)
            uploadImagesAndUpdate(data)
        } else {
            data.id = "";
            try {
                const addeduser = await apiRegisterWithEmailAndPassword2(data.email, password)
                data.id = addeduser.uid;
                const addeddata = await UserDataService.addUser(data, addeduser.uid);
                uploadImagesAndUpdate(data)
                addUserAccountHolder(data)
            } catch (error) {
                if (error.code === 'auth/email-already-in-use' || error.code == 'auth/email-already-exists') {
                    //Email alredy use by member
                    const userInfo = await UserDataService.getUserByEmail(data.email);
                    if (userInfo.docs.length > 0) {
                        alert(error?.message)
                    } else {
                        const userInfoProvider = await ServiceProviderDataService.getServiceProviderByEmail(data.email);
                        if (userInfoProvider.docs.length > 0) {
                            let memberData = userInfoProvider.docs[0].data();
                            data.id = memberData.id
                            const addeddata = await UserDataService.addUser(data, memberData.id);
                        } else {
                            alert(error?.message)
                        }
                    }
                } else if (error.message) {
                    alert(error.message)
                }
                setShowLoader(false)
                resetAndClose()
            }
        }
    }

    function resetAndClose() {
        setShowLoader(false)
        reset()
        setPassword("")
        props.setIsOpen(false);
        props.updateGrid();
    }
    async function removePreviouseUserAccountHolder(data) {
        if (props.selectedUser.linkedUserIds && isAccountHolder) {
            const linkedUserIds = props.selectedUser.linkedUserIds;
            // Use map to create an array of promises
            const updatePromises = linkedUserIds.map(async relativeUserId => {
                await UserDataService.updateUserField(relativeUserId, { accountHolderId: "", linkedUserIds: [] });
            });
            // Use Promise.all to wait for all promises to resolve
            await Promise.all(updatePromises);
            addUserAccountHolder(data)
        }
    }
    async function addUserAccountHolder(data) {
        if (data.linkedUserIds && isAccountHolder) {
            const linkedUserIds = data.linkedUserIds;
            // Use map to create an array of promises
            const updatePromises = linkedUserIds.map(async relativeUserId => {
                await UserDataService.updateUserField(relativeUserId, { accountHolderId: data.id, linkedUserIds: data.linkedUserIds });
            });
            // Use Promise.all to wait for all promises to resolve
            await Promise.all(updatePromises);
        }
    }

    async function uploadImagesAndUpdate(data) {
        try {
            const dataObj = data;
            let profilePicUrl = ''
            let dlImageUrl = ''
            let insuranceImageUrl = ''
            if (profilePic && fileMainImage) {
                profilePicUrl = await uploadImage2(data, 'ProfilePic', fileMainImage);
                dataObj.profilePic = profilePicUrl;
            } else {
                if (profilePic && profilePic != "") { } else {
                    dataObj.profilePic = "";
                }
            }
            if (dlUploadImage && dlUploadImage != "") {
                dlImageUrl = await uploadImage2(data, 'Drivinglicence', dlUploadImage);
                dataObj.dlImage = dlImageUrl;
            } else {
                if (dlImage && dlImage != "") { } else {
                    dataObj.dlImage = "";
                }
            }
            if (insuranceUploadImage && insuranceUploadImage != "") {
                insuranceImageUrl = await uploadImage2(data, 'InsuranceDoc', insuranceUploadImage);
                dataObj.insuranceImage = insuranceImageUrl;
            } else {
                if (insuranceImage && insuranceImage != "") { } else {
                    dataObj.insuranceImage = "";
                }
            }
            //console.log("dataObj=>", dataObj)
            // Update the user in the database with the updated URLs
            await UserDataService.updateUser(data.id, dataObj);
            resetAndClose()
        } catch (error) {
            console.error('Error uploading images and updating user:', error);
            resetAndClose()
        }
    }

    async function uploadImage2(data, folder, image) {
        if (image && image !== "") {
            const storageRef = ref(storage, `/${folder}/${data.id}.png`);
            const uploadTask = uploadBytesResumable(storageRef, image);
            return new Promise(async (resolve, reject) => {
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        setPercent(percent);
                    },
                    reject, // Reject on error
                    async () => {
                        try {
                            // Resolve with the download URL
                            const url = await getDownloadURL(uploadTask.snapshot.ref);
                            //data[`${folder.toLowerCase()}Image`] = url;
                            resolve(url);
                        } catch (error) {
                            reject(error);
                        }
                    }
                );
            });
        } else {
            // Return a resolved promise if no image is provided
            return Promise.resolve();
        }
    }

    const handleChangeMainImage = (event) => {
        setProfilePic(URL.createObjectURL(event.target.files[0]));
        setFileMainImage(event.target.files[0]);
    }
    const handleChangeDLImage = (event) => {
        setDlImage(URL.createObjectURL(event.target.files[0]));
        setUploadDlImage(event.target.files[0]);
    }
    const handleChangeInsImage = (event) => {
        setInsuranceImage(URL.createObjectURL(event.target.files[0]));
        setInsuranceUploadImage(event.target.files[0]);
    }

    const removeMainImage = () => {
        setProfilePic("");
        setFileMainImage("");
    }

    const removeDlImage = () => {
        setDlImage("")
        setUploadDlImage("")
    }
    const removeInsImage = () => {
        setInsuranceImage("")
        setInsuranceUploadImage("")
    }

    async function getUserDetails() {
        if (props.selectedUser && typeof (props.selectedUser) == "object" && props.selectedUser.id) {
            setIsLoading(true)
            try {
                const userDoc = await UserDataService.getUser(props.selectedUser.id);
                let userDocObj = userDoc.data();
                if (userDocObj) {
                    if (userDocObj.profilePic) {
                        setProfilePic(userDocObj.profilePic)
                    } else {
                        setProfilePic(null)
                    }
                    if (userDocObj.insuranceImage) {
                        setInsuranceImage(userDocObj.insuranceImage)
                    } else {
                        setInsuranceImage(null)
                    }
                    if (userDocObj.dlImage) {
                        setDlImage(userDocObj.dlImage)
                    } else {
                        setDlImage(null)
                    }

                    if (userDocObj.insuranceInfo) {
                        let insuranceI = userDocObj.insuranceInfo
                        setValue('insuranceInfo.policyHolderName', insuranceI['policyHolderName']);
                        setValue('insuranceInfo.insuranceCompany', insuranceI['insuranceCompany']);
                        setValue('insuranceInfo.policyNumber', insuranceI['policyNumber']);
                        setValue('insuranceInfo.state', insuranceI['state']);
                    }
                }
                setIsLoading(false)
            } catch (error) {
                alert(error)
                setIsLoading(false)
            }
        }
    }

    async function getStripCustomerId() {
        if (props.selectedUser && props.selectedUser.email) {
            let config = {
                method: 'get',
                url: `${Base_Url}/search-stripeuser?emailId=${props.selectedUser.email}`,
            };
            axios.request(config)
                .then((response) => {


                    if (response && response.data && response.data.data) {
                        let customer = response.data.data[0];

                        getPaymentMethodByCustomerId(customer.id)
                    } else {
                    }
                })
                .catch((error) => { });
        }
    }

    async function getPaymentMethodByCustomerId(stripeCustomerId) {
        if (props.selectedUser) {
            let config = {
                method: 'get',
                url: `${Base_Url}/getListPaymentMethods?customerId=${stripeCustomerId}`,
            };
            axios.request(config)
                .then((response) => {
                    if (response && response.data) {
                        const resArr = response.data.data
                        let hasBankAccount = false;
                        let hasCard = false;
                        let bankList = resArr.filter(data => data.type === "bank_account").map(data => ({
                            id: data.id,
                        }));
                        let bankList2 = resArr.filter(data => data.type === "us_bank_account").map(data => ({
                            id: data.id,
                        }));
                        let finalBankList = [...bankList, ...bankList2];
                        let cardList = resArr.filter(data => data.type == "card");
                        if (finalBankList && finalBankList.length > 0) {
                            hasBankAccount = true
                        }
                        if (cardList && cardList.length > 0) {
                            hasCard = true
                        }

                        if (hasBankAccount && hasCard) {
                            setHasBankAndCard(true)
                        }
                    }
                })
                .catch((error) => {
                });
        }
    }

    const checkHasStripeAccount = () => {
        if (props.selectedUser && typeof (props.selectedUser) == "object" && props.selectedUser.stripeCustomerId && props.selectedUser.stripeCustomerId != undefined && props.selectedUser.stripeCustomerId != "") {
            return true
        } else {
            return false
        }
    }


    useEffect(() => {
        if (insuranceImage && insuranceImage != "") {
            const getFileType = async () => {
                let data = JSON.stringify({
                    "imageUrl": insuranceImage
                });
                let config = {
                    method: 'post',
                    url: `${Base_Url}/api/imageContent`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                axios.request(config)
                    .then((response) => {
                        if (response.data && response.data && response.data.data != "") {
                            setFileType(response.data.data)
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            };
            getFileType();
        }
    }, [insuranceImage]);


    useEffect(() => {
        setProfilePic(null);
        setUploadDlImage("");
        setInsuranceUploadImage("");
        setInsuranceImage(null);
        setDlImage(null);
        setSubscriptionList([]);
        getStripeSubscriptionList();
        const formfields = ['email', 'firstName', 'lastName', 'groupIds',
            'isAdmin', 'isHonorary', 'memberNumber', 'memberships', 'genderValue', 'phoneNumbers', 'id', 'insuranceImage', 'dlImage', 'dLNumber', 'linkedUserIds', 'accountHolderId', 'accessPermission'];
        if (props.selectedUser && typeof (props.selectedUser) == "object") {
            formfields.forEach(field => setValue(field, props.selectedUser[field] ? props.selectedUser[field] : InitialUserState[field]));
            setValue('addOn', []);
            setValue('address.city', props.selectedUser.address?.city);
            setValue('address.country', props.selectedUser.address?.country);
            setValue('address.state', props.selectedUser.address?.state);
            setValue('address.street1', props.selectedUser.address?.street1);
            setValue('address.street2', props.selectedUser.address?.street2);
            setValue('address.zip', props.selectedUser.address?.zip);
            setProfilePic(props.selectedUser.profilePic);
            setHasBankAndCard(false)
            getUserDetails();

            //TODO: Get all list linkedUserIds from allusers, then filterd this all users
            const filteredUserData = filterData(props.allusers, props.selectedUser?.linkedUserIds);
            setMatchingOptions([...filteredUserData])

            const filteredAllurer = props.allusers.filter(person => props.selectedUser?.linkedUserIds?.includes(person.id));
            if (filteredAllurer && filteredAllurer.length > 0) {
                const resultstr = filteredAllurer.map(person => ` ${person.firstName} ${person.lastName} `);
                setLinkUserPlaceholder(resultstr)
            } else {
                setLinkUserPlaceholder('')
            }
            if (props.selectedUser.accountHolderId && props.selectedUser.accountHolderId != "" && props.selectedUser.accountHolderId != props.selectedUser.id) {
                setIsAccountHolder(false)
            } else {
                setIsAccountHolder(true)
            }

            if (props.selectedUser.accountHolderId) {
                let parentAc = props.allusers.filter(obj => obj.id == props.selectedUser.accountHolderId);
                if (parentAc && parentAc.length > 0) {
                    setParentAccountUser(parentAc[0])
                } else {
                    setParentAccountUser(null)
                }
            } else {
                setParentAccountUser(null)
            }
            getStripCustomerId()

            if (
                props.selectedUser.memberships &&
                props.selectedUser.memberships.length > 0
            ) {
                let filteredSubscrption = subscriptionList
                    .filter(data => props.selectedUser.memberships.includes(data.id))
                    .map(data => data.description);
                setSeletedMembershipPlaceholder(filteredSubscrption.join(', '));
            } else {
                setSeletedMembershipPlaceholder("");
            }

            if (
                props.selectedUser.genderValue
            ) {
                setSelectedGenderPlaceholder(props.selectedUser.genderValue);
            } else {
                setSelectedGenderPlaceholder("");
            }




        } else {
            formfields.forEach(field => setValue(field, InitialUserState[field]));
            setValue('addOn', []);
            setValue('address.city', InitialUserState.address['city']);
            setValue('address.country', InitialUserState.address['country']);
            setValue('address.state', InitialUserState.address['state']);
            setValue('address.street1', InitialUserState.address['street1']);
            setValue('address.street2', InitialUserState.address['street2']);
            setValue('address.zip', InitialUserState.address['zip']);
            setInsuranceImage(null)
            setDlImage(null)
            setSeletedMembershipPlaceholder("");
            setSelectedGenderPlaceholder("");
            setValue('insuranceInfo.policyHolderName', '');
            setValue('insuranceInfo.insuranceCompany', '');
            setValue('insuranceInfo.policyNumber', '');
            setValue('insuranceInfo.state', '');
            setLinkUserPlaceholder('');
            setIsAccountHolder(true)
            setParentAccountUser(null)
            setHasBankAndCard(false)
        }
    }, [props.selectedUser])

    function setConnectedAccountStr(data) {
        const filteredAllurer = props.allusers.filter(person => data.selectedOptions.includes(person.id));
        if (filteredAllurer && filteredAllurer.length > 0) {
            const resultstr = filteredAllurer.map(person => ` ${person.firstName} ${person.lastName}`);
            setLinkUserPlaceholder(resultstr)
        } else {
            setLinkUserPlaceholder('')
        }
    }

    const onChange = (event) => {
        const value = event.target.value.trim();
        const filteredUserData = filterData(props.allusers, props.selectedUser?.linkedUserIds);
        const matches = filteredUserData.filter(
            (option) => option.firstName.toLowerCase().indexOf(value.toLowerCase()) === 0
        );
        setMatchingOptions(matches);
    };

    return (
        <DrawerOverlay
            size={"medium"}
            position={"right"}
            open={props.isOpen}
            style={{ width: "504px" }}
            onOpenChange={(_, { open }) => props.setIsOpen(open)}
        ><DrawerHeader style={{
            background: "#F7F7F7",
            padding: "26px 32px"
        }}>
                <DrawerHeaderTitle

                    action={
                        <Button
                            appearance="subtle"
                            aria-label="Close"
                            icon={<Dismiss24Regular />}
                            onClick={() => props.setIsOpen(false)}
                        />
                    }
                >
                    <div className={"vertical-center"}
                        style={{ display: "flex", alignItems: "center", height: "100%", paddingLeft: "6px" }}>
                        <div><UserFormAddSVG /></div>
                        <span
                            className={"user-form__header "}>{props.selectedUser && typeof (props.selectedUser) == "object" ? "Edit" : "New"} User</span>
                    </div>

                    {
                        isLoading ? <Spinner label="Loading..." /> : <div />
                    }
                </DrawerHeaderTitle>
            </DrawerHeader>

            <DrawerBody>
                <form onSubmit={handleSubmit(onSubmit)}
                    className={styles.root} onReset={reset}
                    id={props.selectedUser && typeof (props.selectedUser) == "object" ? props.selectedUser.id : "New"}>

                    {
                        props.selectedUser && typeof (props.selectedUser) == "object" && props.selectedUser.totalPoint && (
                            <Label className={"side-drawer-field__label"} style={{ marginTop: 32 }}>
                                <div className={"d-flex justify-space-between"}>
                                    <div>Points balance:</div>
                                    <div style={{
                                        color: '#000',
                                        fontFamily: 'Nunito Sans',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        lineHeight: '28px' // You can specify this directly as a string
                                    }}>{props.selectedUser.totalPoint.toFixed(2)}</div>
                                </div>
                            </Label>
                        )
                    }

                    <Label className={"side-drawer-field__label"} style={{ marginTop: "12px" }}>Email*</Label>
                    <Controller
                        name="email"
                        key="email"

                        control={control}
                        rules={{ required: true }}

                        render={({ field }) => <input
                            placeholder={"Enter Email"}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field}
                            disabled={(props.selectedUser && typeof (props.selectedUser) == "object") ? true : false} />}
                    />

                    <Label className={"side-drawer-field__label"}>Password*</Label>
                    <Input placeholder={"Enter Password"} contentAfter={<span className={"pointer"} onClick={() => {
                        setPasswordVisible((prevState) => (!prevState))
                    }}> {passwordVisible ? <EyeViewSVG aria-label="Enter by voice" /> : <EyeViewDisableSVG />}</span>}
                        className={"side-drawer-field-date  side-drawer-field__gap side-drawer-field__gap"}
                        value={password} onChange={(ev) => setPassword(ev.target.value)}
                        disabled={(props.selectedUser && typeof (props.selectedUser) == "object") ? true : false}
                        type={passwordVisible ? "password" : "text"} />

                    <Label className={"side-drawer-field__label"} style={{ marginTop: "8px" }}>
                        First Name*
                    </Label>
                    <Controller
                        name="firstName"

                        key="firstName"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <input
                            placeholder={"Enter First Name"}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
                    />
                    <Label className={"side-drawer-field__label"}>
                        Last Name*
                    </Label>
                    <Controller
                        name="lastName"
                        key="lastName"
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => <input
                            className={"side-drawer-field  side-drawer-field__gap"} {...field}
                            placeholder={"Enter Last Name"} />}
                    />
                    <Label className={"side-drawer-field__label"} style={{ marginTop: "8px" }}>
                        Admin
                    </Label>
                    <Controller
                        name="isAdmin"
                        key="isAdmin"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                            <div onClick={() => field.onChange(!field.value)}>
                                <Switch checked={field.value} {...field} />
                            </div>
                        )}
                    />
                    <Label className={"side-drawer-field__label"}>
                        Honorary Member
                    </Label>
                    <Controller
                        name="isHonorary"
                        key="isHonorary"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => (
                            <div onClick={() => field.onChange(!field.value)}>
                                <Switch checked={field.value} {...field} />
                            </div>
                        )}
                    />

                    <Divider className={"side-drawer-divider-gap"} />
                    {
                        isAccountHolder &&
                        <Label className={"side-drawer-section__typo"}
                            style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                            <ConnectAccountSVG /> <span style={{ marginLeft: "8px" }}>CONNECT ACCOUNT</span>
                        </Label>

                    }
                    {
                        isAccountHolder ?
                            hasBankAndCard ?
                                <Controller
                                    name="linkedUserIds"
                                    key="linkedUserIds"
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field }) =>
                                        <Combobox multiselect={true}
                                            className={"side-drawer-field  side-drawer-field__gap"}
                                            //placeholder={linkUserPlaceholder}
                                            onChange={onChange}
                                            onOptionSelect={(ev, data) => {
                                                if (data.selectedOptions.length < 6) {
                                                    setConnectedAccountStr(data)
                                                    field.onChange(data.selectedOptions)
                                                }
                                            }}
                                            selectedOptions={field.value}
                                        >
                                            {matchingOptions.map((option) => (
                                                <Option key={option.id} value={option.id}>
                                                    {`${option.firstName} ${option.lastName}`}
                                                </Option>
                                            ))}
                                            {matchingOptions.length === 0 ? (
                                                <Option key="no-results" text="">
                                                    No results found
                                                </Option>
                                            ) : null}
                                        </Combobox>}
                                /> :
                                <Label className={"side-drawer-grey-help"} style={{ marginBottom: 24 }}>
                                    Primary account has no bank account and a credit card on file.
                                </Label> :
                            <div></div>
                    }
                    {
                        parentAccountUser && (
                            <Label>
                                Parent Account: {parentAccountUser.firstName} {parentAccountUser.lastName}
                            </Label>
                        )
                    }
                    <Label>
                        {linkUserPlaceholder && linkUserPlaceholder != "" ? `Connected Account:${linkUserPlaceholder}` : ''}
                    </Label>
                    <Label className={"side-drawer-field__label"}>
                        Member Number
                    </Label>
                    <Controller
                        name="memberNumber"
                        key="memberNumber"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input
                            className={"side-drawer-field  side-drawer-field__gap"}
                            placeholder={"Enter Member Number"} {...field} />}
                    />
                    <Label className={"side-drawer-field__label"}>
                        Memberships
                    </Label>
                    <Controller
                        name="memberships"
                        key="memberships"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <Dropdown multiselect={true}

                            className={"side-drawer-field-dropdown  side-drawer-field__gap"}
                            onOptionSelect={(ev, data) => {
                                let isadd = checkHasStripeAccount()
                                if (isadd) {
                                    field.onChange(data.selectedOptions)
                                } else {
                                    alert("Nee to Add card/bank before select membership")
                                }
                            }}
                            placeholder={selectedMembershipPlaceholder ? selectedMembershipPlaceholder : "Select Memberships"}
                            selectedOptions={field.value}>
                            {subscriptionList && subscriptionList.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {option.name}
                                </Option>
                            ))}
                        </Dropdown>}
                    />
                    {/* <Label className={"side-drawer-field__label"}>
                        Add on
                    </Label>
                    <Controller
                        name="addOn"
                        key="addOn"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <Dropdown multiselect={false}
                            className={"side-drawer-field-dropdown  side-drawer-field__gap"}
                            onOptionSelect={(ev, data) => field.onChange(data.selectedOptions)}
                            placeholder={"Select Add-On"}
                            selectedOptions={field.value}>
                            {subscriptionAddOnsList && subscriptionAddOnsList?.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {option.name}
                                </Option>
                            ))}
                        </Dropdown>}
                    /> */}

                    {/* <Label className={"side-drawer-field__label"}>
                        Access Permission
                    </Label>
                    <Controller
                        name="accessPermission"
                        key="accessPermission"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <Dropdown multiselect={true}
                            className={"side-drawer-field-default  side-drawer-field__gap"}
                            onOptionSelect={(ev, data) => field.onChange(data.selectedOptions)}
                            selectedOptions={field.value}>
                            {memberAccessPermission.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {option.name}
                                </Option>
                            ))}
                        </Dropdown>}
                    /> */}

                    <Label className={"side-drawer-field__label"}>
                        Gender
                    </Label>

                    <Controller
                        name="genderValue"
                        key="genderValue"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <Dropdown multiselect={false}
                            className={"side-drawer-field-dropdown  side-drawer-field__gap"}
                            onOptionSelect={(ev, data) => field.onChange(data.selectedOptions[0])}
                            placeholder={selectedGenderPlaceholder ? selectedGenderPlaceholder : "Select Gender"}
                            selectedOptions={[field.value]}>
                            {genderoptions.map((option) => (
                                <Option key={option.id} value={option.id}>
                                    {option.name}
                                </Option>
                            ))}
                        </Dropdown>}
                    />

                    <Divider className={"side-drawer-divider-gap"} />

                    <div className={"d-flex"} style={{ display: "flex", alignItems: "center", marginBottom: "24px" }}>
                        <div><AddressSVG /></div>
                        <Label className={"side-drawer-section__typo"} style={{ marginLeft: "8px" }}>ADDRESS</Label>
                    </div>

                    <Label className={"side-drawer-field__label"}>
                        Street 1
                    </Label>
                    <Controller
                        name="address.street1"
                        key="address.street1"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input
                            placeholder={"Enter Street 1"}
                            className={"side-drawer-field  side-drawer-field__gap"} {...field} />}
                    />
                    <Label className={"side-drawer-field__label"}>
                        Street 2
                    </Label>
                    <Controller
                        name="address.street2"
                        key="address.street2"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input
                            placeholder={"Enter Street 2"}
                            className={"side-drawer-field  side-drawer-field__gap"} {...field} />}
                    />

                    <div className={"d-flex justify-space-between"}>
                        <div style={{ display: "grid" }}>
                            <Label className={"side-drawer-field__label"}>City</Label>
                            <Controller
                                name="address.city"
                                key="address.city"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter City"}
                                    className={"side-drawer-field side-drawer-field__gap"} {...field} />}
                            />
                        </div>
                        <div style={{ display: "grid" }}>
                            <Label className={"side-drawer-field__label"}>State</Label>
                            <Controller
                                name="address.state"
                                key="address.state"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter State"}
                                    className={"side-drawer-field side-drawer-field__gap"} {...field} />}
                            />
                        </div>
                    </div>
                    <div className={"d-flex justify-space-between"}>
                        <div style={{ display: "grid" }}>
                            <Label className={"side-drawer-field__label"}>Country</Label>
                            <Controller
                                name="address.country"
                                key="address.country"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter Country"}
                                    className={"side-drawer-field side-drawer-field__gap"} {...field} />}
                            />
                        </div>
                        <div style={{ display: "grid" }}>
                            <Label className={"side-drawer-field__label"}>Zip</Label>
                            <Controller
                                name="address.zip"
                                key="address.zip"
                                control={control}
                                rules={{ required: false }}
                                render={({ field }) => <input
                                    placeholder={"Enter Zip"}
                                    className={"side-drawer-field side-drawer-field__gap"} {...field} />}
                            />
                        </div>
                    </div>

                    <Divider className={"side-drawer-divider-gap"} />
                    <Label className={"side-drawer-section__typo"} style={{ display: "flex", alignItems: "center" }}>
                        <PhoneSVG /> <span style={{ marginLeft: "8px" }}>Phone Numbers</span>
                    </Label>

                    {fields.map((field, index) => {
                        return (
                            <>
                                <Label className={"side-drawer-field__label"} style={{ marginTop: "24px" }}>
                                    Number
                                </Label>
                                <Controller
                                    name={`phoneNumbers.${index}.number`}
                                    key={`phoneNumbers.${index}.number`}
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field }) => <input
                                        placeholder={"Enter Nember"}
                                        className={"side-drawer-field  side-drawer-field__gap"} {...field} />}
                                />
                                <Label className={"side-drawer-field__label"}>
                                    Type
                                </Label>
                                <Controller
                                    name={`phoneNumbers.${index}.type`}
                                    key={`phoneNumbers.${index}.type`}
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field }) => <input
                                        placeholder={"Enter Type"}
                                        className={"side-drawer-field  side-drawer-field__gap"} {...field} />}
                                />
                                <Label className={"side-drawer-field__label"}>
                                    Is Primary
                                </Label>
                                <Controller
                                    name={`phoneNumbers.${index}.isPrimary`}
                                    key={`phoneNumbers.${index}.isPrimary`}
                                    control={control}
                                    rules={{ required: false }}
                                    render={({ field }) => (
                                        <div onClick={() => field.onChange(!field.value)}>
                                            <Switch checked={field.value} {...field} />
                                        </div>
                                    )} />
                                {fields.length > 1 && fields.length - 1 !== index && (
                                    <>
                                        <div className={"d-flex justify-end"}>

                                            <DeleteIcon onClick={() => remove(index)}>Remove</DeleteIcon>
                                        </div>
                                        <div>
                                            <Divider />
                                        </div>

                                    </>)}
                                {/*<Divider appearance="strong" className={"side-drawer-divider-gap"}></Divider>*/}
                            </>
                        )
                    })}
                    <div className={"sidebar-drawer-Addnew-lable"} onClick={() => append({ isPrimary: false, number: "", type: "" })} style={{ marginTop: "24px" }}>+ One More Number</div>

                    <Label> </Label>
                    <Divider className={"side-drawer-divider-gap"} />
                    <Label className={"side-drawer-section__typo"} style={{ display: "flex", alignItems: "center" }}>
                        <InsuranceSVG /> <span style={{ marginLeft: "8px" }}>INSURANCE</span>
                    </Label>

                    <Label className={"side-drawer-field__label"} style={{ marginTop: 27 }}>
                        Policy Holder Name
                    </Label>
                    <Controller
                        name="insuranceInfo.policyHolderName"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input
                            placeholder={"Enter Policy Holder Name"}
                            className={"side-drawer-field  side-drawer-field__gap"} {...field} />}
                    />
                    <Label className={"side-drawer-field__label"}>
                        Insurance Company
                    </Label>
                    <Controller
                        name="insuranceInfo.insuranceCompany"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input
                            placeholder={"Enter Insurance Company"}
                            className={"side-drawer-field  side-drawer-field__gap"} {...field} />}
                    />
                    <Label className={"side-drawer-field__label"}>
                        Policy Number
                    </Label>
                    <Controller
                        name="insuranceInfo.policyNumber"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input
                            placeholder={"Enter Policy Number"}
                            className={"side-drawer-field  side-drawer-field__gap"} {...field} />}
                    />
                    <Label className={"side-drawer-field__label"}>
                        State
                    </Label>
                    <Controller
                        name="insuranceInfo.state"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input
                            placeholder={"Enter State"}
                            className={"side-drawer-field  side-drawer-field__gap"} {...field} />}
                    />
                    <Label className={"side-drawer-field__label side-drawer-field__gap"}>Copy of Insurance</Label>
                    {!insuranceImage &&
                        <label className="sidebar-drawer-file-upload">
                            <input type="file" id="file1" onChange={handleChangeInsImage} accept="image/*"
                                className="   side-drawer-field__gap "
                                aria-label="File browser example" placeholder="Browse" />
                        </label>
                    }
                    {insuranceImage &&
                        <div>
                            {
                                fileType == "application/pdf" ?
                                    <div className={{ width: 100, height: 100 }}>
                                        <DocumentRegular style={{ width: 100, height: 100 }} />
                                    </div>
                                    :
                                    <Image width={130} height={100} src={insuranceImage} alt="ImageIns"></Image>
                            }
                            <Button icon={<DeleteIcon />} onClick={removeInsImage} />
                        </div>
                    }
                    {
                        insuranceImage && fileType && fileType == "application/pdf" ?
                            <a href={insuranceImage} target="_blank" rel="noopener noreferrer"><Label>Insurance
                                PDF</Label> </a> : <div />
                    }

                    <Divider className={"side-drawer-divider-gap"} />
                    <Label className={"side-drawer-section__typo"} style={{ display: "flex", alignItems: "center" }}>
                        <DrivingLicenceSVG /> <span style={{ marginLeft: "8px" }}> DRIVING LICENSE </span>
                    </Label>

                    <Label className={"side-drawer-field__label"} style={{ marginTop: "24px" }}>
                        Driving License #
                    </Label>
                    <Controller
                        name="dLNumber"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input
                            className={"side-drawer-field  side-drawer-field__gap"} {...field} />}
                    />
                    <Label> </Label>
                    <div styles={{ marginTop: 20 }}></div>
                    <Label className={"side-drawer-field__label side-drawer-field__gap"}>Copy of Driving license</Label>
                    {!dlImage &&
                        <label className="sidebar-drawer-file-upload">
                            <input type="file" id="file1" onChange={handleChangeDLImage} accept="image/*"
                                className="side-drawer-field__gap"
                                aria-label="File browser example" placeholder="Browse" />
                        </label>
                    }
                    {dlImage && dlImage != "" &&
                        <div>
                            <Image width={130} height={100} src={dlImage} alt="ImageDl"></Image>
                            <Button icon={<DeleteIcon />} onClick={removeDlImage} />
                        </div>
                    }

                    <Divider style={{ marginTop: 24, marginBottom: 24 }} />

                    <Label className={"side-drawer-field__label side-drawer-field__gap"}>
                        Profile Picture
                    </Label>
                    {profilePic &&
                        <div>
                            <Image width={100} height={100} src={profilePic} alt="Main Image"></Image>
                            <Button icon={<DeleteIcon />} onClick={removeMainImage} />
                        </div>}
                    {!profilePic &&
                        <label className="sidebar-drawer-file-upload">
                            <input type="file" id="file1" onChange={handleChangeMainImage} accept="image/*"
                                className="side-drawer-field__gap" aria-label="File browser example" placeholder="Browse" />
                        </label>
                    }
                    {
                        showLoader ?
                            <Spinner label="Loading..." /> :
                            <div className="btnActions">
                                <button appearance="primary" type="submit" className="form-submit-btn"
                                >
                                    {props.selectedUser && typeof (props.selectedUser) == "object" ? 'Update' : 'Submit'}</button>
                                <button className="form-submit-cancel"
                                    onClick={() => props.setIsOpen(false)}>Cancel</button>
                            </div>
                    }
                </form>
            </DrawerBody>
        </DrawerOverlay>
    )
}

export default UserForm

function filterData(data, selectedUserLinkedUserIds) {
    // Check if data or selectedUserLinkedUserIds is null or empty
    if (!Array.isArray(data) || data.length === 0 || !Array.isArray(selectedUserLinkedUserIds)) {
        return data;
    }

    const selectedUserLinkedUserIdSet = new Set(selectedUserLinkedUserIds);
    const allLinkedUserIds = new Set();

    // Collect all linked user IDs from the data and directly remove the selected user's linked user IDs
    data.forEach(user => {
        if (user && Array.isArray(user.linkedUserIds)) {
            user.linkedUserIds.forEach(linkedId => {
                if (!selectedUserLinkedUserIdSet.has(linkedId)) {
                    allLinkedUserIds.add(linkedId);
                }
            });
        }
    });

    // Filter the data to remove objects with IDs in allLinkedUserIds
    return data.filter(user => user && !allLinkedUserIds.has(user.id));
}

import {

    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface, DialogTitle,
    DialogTrigger,
    Image, makeStyles
} from "@fluentui/react-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import {CloseSVG} from "../static/svgs";


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    className: "myCustomCarousel"
};

const useStyles = makeStyles({
    bodyWith: {
        height: "400px",
    },
});

function ImageDialog(props) {
    const styles = useStyles();


    return (
        <Dialog
            style={{
                height:"732px",
                width:"466px"
            }}
            open={props.open}
            onOpenChange={(event, data) => {
                props.setOpen(data.open);
            }}
        >
            <DialogSurface>
                <DialogBody className={styles.bodyWith}>
                    <DialogTitle className={"d-flex justify-space-between"}>Images
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <div className={"pointer"}>
                                  <CloseSVG/>
                                </div>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogTitle>
                    <DialogContent  style={{height:"calc(100% - 27px)",overflow:"hidden"}} className={"example"}>
                        <div style={{padding:"25px",height:"90%"}}>


                            <Slider {...settings}>
                                {props.selectedEvent &&props.selectedEvent?.images&&Array.isArray(props.selectedEvent?.images)&&props.selectedEvent?.images?.length !==0 ? props.selectedEvent.images.map((image,index) =>
                                    <Image
                                        alt="Images"
                                        src={image}
                                        height={310}
                                        width={620}
                                        key={`images${index}`}
                                    />
                                )   :  <Image
                                    alt="Images"
                                    src={props?.selectedEvent?.mainImage||""}
                                    height={310}
                                    width={620}

                                />}
                            </Slider>
                        </div>
                    </DialogContent>

                    {/*<DialogActions>*/}
                    {/*    <DialogTrigger disableButtonEnhancement>*/}
                    {/*        <Button appearance="secondary">Close</Button>*/}
                    {/*    </DialogTrigger>*/}
                    {/*</DialogActions>*/}
                </DialogBody>
            </DialogSurface>
        </Dialog >
    )
}

export default ImageDialog;
import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, getDocs, orderBy, startAfter, query, limit} from "firebase/firestore";
import { db } from "./firebase";

const roomsCollection = collection(db, "Rooms");

class RoomsDataService {
    addRooms = (newRooms) => {
        return addDoc(roomsCollection, newRooms);
    }

    updateRooms = (id, updatedRooms) => {
        const roomsDoc = doc(db, "Rooms", id);
        return updateDoc(roomsDoc, updatedRooms);
    }

    deleteRooms = (id) => {
        const roomsDoc = doc(db, "Rooms", id);
        return deleteDoc(roomsDoc);
    }

    getAllRooms = () => {
        return getDocs(roomsCollection)
    }

    getRooms = (id) => {
        const roomsDoc = doc(db, "Rooms", id);
        return getDoc(roomsDoc);
    }
}

export default new RoomsDataService();
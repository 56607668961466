import React, { useEffect, useState } from "react";
import Layout from "./components/Layout";
import { useAuthState } from "react-firebase-hooks/auth";
import { Base_Url, auth, db, trackException } from "./services/firebase";
import { useNavigate } from "react-router-dom";
import {
    makeStyles, shorthands, Persona, Button, Table, TableBody,
    TableHeader, TableRow, TableHeaderCell, TableCell,
    TableCellLayout, Image, TableCellActions, Label
} from "@fluentui/react-components";
import { query, collection, getDocs, where } from "firebase/firestore";
import { EditFilled, EditRegular, bundleIcon, DeleteFilled, DeleteRegular } from "@fluentui/react-icons";
import ServiceProviderDataService from "./services/ServiceProviderDataService";
import DeleteEventDialog from "./components/DeleteEventDialog";
import ServiceProviderForm from "./components/Forms/ServiceProviderForm";
import VehicleServiceTypeDataService from "./services/VehicleServiceTypeDataService";
import axios from "axios";
import CustomSearchInput from "./components/CustomSearchInput";
import debounce from 'lodash/debounce';
import { PlusSVG } from "./static/svgs";

const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "providerName", label: "Provider Name" },
    { columnKey: "providerDescription", label: "Provider Description" },
    { columnKey: "address.city", label: "Address" }
];

function ServiceProvider() {
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedServiceProvider, setSelectedServiceProvider] = useState();
    const [allserviceProviders, setAllServiceProvider] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredServiceProvider, setFilteredServiceProvider] = useState([]);

    const [open, setOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [options, setOptions] = useState([]);
    const navigate = useNavigate();
    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedServiceProvider(selectedItem);
    }

    const fetchUserNameAndServiceProvider = async () => {
        try {
            if (!!user) {
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                if (userdoc.docs.length > 0) {
                    const serviceProvidersQuery = await ServiceProviderDataService.getAllServiceProvider();
                    let serviceProviderArr = serviceProvidersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                    setAllServiceProvider(serviceProviderArr);
                    setFilteredServiceProvider(serviceProviderArr)
                    //console.log(allevents);
                } else {
                    console.log('no access');
                }
            }
        } catch (err) {
            trackException(JSON.stringify(err))
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    const fetchOptions = async () => {
        try {
            const vehicleServiceTypesQuery = await VehicleServiceTypeDataService.getAllVehicleServiceType();
            setOptions(vehicleServiceTypesQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        } catch (error) {
            trackException(JSON.stringify(error))
            console.error(error);
        }
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        fetchUserNameAndServiceProvider();
        fetchOptions();
    }, [user, loading]);

    const openDeleteForm = (selectedItem) => {
        setIsDeleteDialogOpen(true);
        setSelectedServiceProvider(selectedItem);
        // fetchUserNameAndEvents();
    }

    const DeleteServiceProvider = async () => {

        let config = {
            method: 'delete',
            url: `${Base_Url}/api/deleteUserAuth?uuid=${selectedServiceProvider.id}`,
        };
        axios.request(config)
            .then((response) => { })
            .catch((error) => { console.log(error); });

        ServiceProviderDataService.deleteServiceProvider(selectedServiceProvider.id);
        setIsDeleteDialogOpen(false);
        fetchUserNameAndServiceProvider();
    };

    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allserviceProviders.filter(event =>
            event.providerName.toLowerCase().includes(lowerSearch)
        );
        setFilteredServiceProvider(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };

    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <Button icon={<PlusSVG />} className={"primary-btn"} shape="circular" appearance="primary" onClick={() => openForm(undefined)}>{'New Service Provider'.toUpperCase()}</Button>
                    <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    />
                </div>
                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredServiceProvider.length > 0 && filteredServiceProvider.map((item) =>
                                <TableRow key={item.id}>
                                    <TableCell className={"table-row-fonts__primary"}>
                                        <TableCellLayout>
                                            {item.providerName}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label title={item.providerDescription}>{item.providerDescription}</Label>
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label title={item.address.city}>{item.address.city}</Label>
                                            <TableCellActions>
                                                <Button
                                                    icon={<EditIcon />}
                                                    appearance="subtle"
                                                    aria-label="Edit"
                                                    onClick={() => openForm(item)}
                                                />
                                                <Button
                                                    icon={<DeleteIcon />}
                                                    appearance="subtle"
                                                    aria-label="Delte"
                                                    onClick={() => openDeleteForm(item)}
                                                />
                                            </TableCellActions>
                                        </TableCellLayout>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <ServiceProviderForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)}
                selectedServiceProvider={selectedServiceProvider} options={options}
                updateGrid={fetchUserNameAndServiceProvider}></ServiceProviderForm>
            {/*<ImageDialog open={open} setOpen={setOpen} selectedEvent={selectedServiceProvider}></ImageDialog> */}
            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeleteServiceProvider} text={'ServiceProvider'} />
        </Layout>
    )
}

export default ServiceProvider;
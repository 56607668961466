import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, updateDoc, startAfter, limit, query, orderBy } from "firebase/firestore";
import { db } from "./firebase";

const productCartCollection = collection(db, "POSCart");

class ProductCartDataService {
    addPOSCart = (newPOSCart) => {
        return addDoc(productCartCollection, newPOSCart);
    }

    updatePOSCart = (id, updatedPOSCart) => {
        const productCartDoc = doc(db, "POSCart", id);
        return updateDoc(productCartDoc, updatedPOSCart);
    }

    deletePOSCart = (id) => {
        const productCartDoc = doc(db, "POSCart", id);
        return deleteDoc(productCartDoc);
    }

    getAllPOSCart = () => {
        return getDocs(productCartCollection)
    }

    getPOSCart = (id) => {
        const productCartDoc = doc(db, "POSCart", id);
        return getDoc(productCartDoc);
    }
}

export default new ProductCartDataService();
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "./firebase";

const userCollection = collection(db, "Users");
const transactionHistoryCollection = collection(db, "TransactionHistory");

class UserDataService {
    addUser = (newUser, uuid) => {
        return setDoc(doc(db, "Users", uuid), newUser);
    }

    updateUser = (id, updatedUser) => {
        const userDoc = doc(db, "Users", id);
        return updateDoc(userDoc, updatedUser);
    }

    updateUserField = (id, updatedUser) => {
        const userDoc = doc(db, "Users", id);
        return updateDoc(userDoc, updatedUser);
    }

    deleteUser = (id) => {
        const userDoc = doc(db, "Users", id);
        return deleteDoc(userDoc);
    }

    getAllUser = () => {
        return getDocs(userCollection)
    }

    getUserByEmail = (email) => {
        const q = query(userCollection, where("email", "==", email));
        return getDocs(q);
    }
    getUserByGender = (genderValue) => {
        const q = query(userCollection, where("genderValue", "==", genderValue));
        return getDocs(q);
    }

    getUser = (id) => {
        const userDoc = doc(db, "Users", id);
        return getDoc(userDoc);
    }
    getUserByMemberId = (id) => {
        const q = query(userCollection, where("memberNumber", "==", id));
        return getDocs(q);
    }
    getUserByMemberName = async (name) => {

        const q = query(userCollection,
            where('lastName', '>=', name),
            where('lastName', '<=', name + '\uf8ff')
        );
        const querySnapshot = await getDocs(q);
        const users = [];
        querySnapshot.forEach((doc) => {
            users.push(doc.data());
        });
        return users;
    }

    addTransaction = (newObj) => {
        return addDoc(transactionHistoryCollection, newObj);
    }
}

export default new UserDataService();
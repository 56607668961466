import {
    Button,
    Dropdown,
    Input,
    Label,
    Option,

    Text,
    makeStyles, shorthands, Divider, Combobox, SkeletonItem, Spinner
} from "@fluentui/react-components";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Layout from "./components/Layout";
import ProductList from "./components/ProductList";
import { Base_Url, auth, trackException } from "./services/firebase";
import OrderHistoryDataService from './services/orderHistoryDataService';
import UserDataService from "./services/userDataService";
import {
    DownArowSVG,
    ErrorWithWarningSVG,
    InfoSVG,
    InstructionsSVG, PaymentIntentIDSVG, PaymentIntentStatusSVG, PaymentSummrySVG, ReaderStatusSVG,
    SearchSVG,
    SelectProductFilterAll, SelectProductFilterDrinks, SelectProductFilterFood,
    SelectProductSVG, SendTerminalSVG, UPArrowSVG
} from "./static/svgs";
import "./TerminalReader.css"
import { useSelector } from "react-redux";
import { Timestamp } from "firebase/firestore";
const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },
    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },
    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    },

});

function TerminalReader() {
    const isSidebarOpen = useSelector(state => state.layout?.isSidebarOpen)
    const placeholderImageUrl = 'https://placehold.co/800?text=No+Image&font=roboto';
    const styles = useStyles();
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [searchMember, setSearchMember] = useState("")
    const [isShowInstruction, setIsShowInstruction] = useState(true)

    const [categoryList, setCategoryList] = useState([]);
    const [readerList, setReaderList] = useState([]);
    const [filterState, setFilterState] = useState({ selectedFilter: "All" });
    const [selectedReaderId, setSelectedReaderId] = useState(null);

    const [productListArr, setProductList] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);

    const [paymentIntentObj, setPaymentIntentObj] = useState(null);
    const [readerObj, setReaderObj] = useState(null);
    const [msgText, setMsgText] = useState('');

    const [additionalTip, setAdditionalTip] = useState(0);
    const [selectedProductIds, setSelectedProductIds] = useState([]);
    const [memberNumber, setMemberNumber] = useState('');
    const [memberDetails, setMemberDetails] = useState(null);

    const [userListByLastName, setUserListByLastName] = useState([])

    const [isLoading, setIsLoading] = useState(false);
    const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        getReaderList()
        getProductsListAPI()
    }, [user, loading]);

    // async function getAllUsers() {
    //     const usersQuery = await UserDataService.getAllUser();
    //     setAllUsers(usersQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    // }

    //Firebase functions
    const toggleProductSelection = (productId) => {
        const isSelected = selectedProductIds.includes(productId);
        if (isSelected) {
            setSelectedProductIds(selectedProductIds.filter((id) => id !== productId));
        } else {
            setSelectedProductIds([...selectedProductIds, productId]);
        }
    };
    async function getUserDetails() {
        try {
            const userInfo = await UserDataService.getUserByMemberId(memberNumber);
            if (userInfo.docs.length > 0) {
                let memberData = userInfo.docs[0].data();
                setMemberDetails(memberData)
            } else {
                setMemberDetails(null)
                alert("No member found.")
            }

        } catch (error) {
            trackException(JSON.stringify(error))
            setMemberDetails(null)
            console.log(error)
            alert(error)
        }
    }
    async function getUserDetailByLastName(memberName) {
        try {
            const userInfo = await UserDataService.getUserByMemberName(memberName);
            setUserListByLastName(userInfo)

            if (userInfo?.length === 1) setMemberNumber(userInfo[0]?.memberNumber)

            // if (userInfo.docs.length > 0) {
            //     let memberData = userInfo.docs[0].data();
            //     debugger
            //     // setMemberDetails(memberData)
            // } else {
            //     setMemberDetails(null)
            //     alert("No member found.")
            // }

        } catch (error) {
            setMemberDetails(null)
            trackException(JSON.stringify(error))
            console.log(error)
            alert(error)
        }
    }

    async function addOrderHistoryFIR(intentRes, email) {
        //console.log("intentRes->", intentRes)

        const nowTimestamp = Timestamp.fromDate(new Date());

        let data = {
            "id": "",
            "email": email,
            "intentId": intentRes.id,
            "amount": intentRes.amount,
            "metadata": intentRes.metadata,
            "currency": intentRes.currency,
            "customer": intentRes.customer ? intentRes.customer : "",
            "description": intentRes.description ? intentRes.description : "",
            "receipt_email": intentRes.receipt_email,
            "status": intentRes.status,
            "intentCreatedAt": intentRes.created,
            "createdDate": new Date().toISOString(),
            "createdAt": nowTimestamp,
            "createdBy": user.uid,
            "receipt_url": intentRes.receipt_url
        }
        const addeddata = await OrderHistoryDataService.addOrderHistory(data);
        let id = addeddata.id;
        data.id = id;
        await OrderHistoryDataService.updateOrderHistory(id, data);
    }

    //API
    const getReaderList = async () => {
        let config = {
            method: 'get',
            url: `${Base_Url}/getStripeTerminalReaders`,
            headers: {
                'Content-Type': 'application/json',
                'Acess-Control-Allow-Origin': '*',
                'Authorization': `${user.accessToken}`,
                'Accept': "application/json"
            },
        };
        axios.request(config)
            .then((response) => {
                if (response && response.data && response.data.data) {
                    setReaderList(response.data.data.data)
                }
            })
            .catch((error) => { console.log(error); });
    }

    const getSelectedProductsMetadata = () => {
        return selectedProducts.map(({ id, productName, price, quantity, images }) => ({
            id,
            price,
            quantity,
        }));
    };

    const processPaymentIntentsAPI = async (readerId) => {
        if (selectedProducts && selectedProducts.length > 0) {
            setIsLoading(true)
            setIsPaymentProcessing(true)
            let amount = calculateTotalPriceWithGratuity()
            let otherData = {
                additionalTip: additionalTip,
                gratuity: calculateGratuity(),
                memberEmail: memberDetails.email
            }
            let metadata = { productInfo: JSON.stringify(getSelectedProductsMetadata()), otherInfo: JSON.stringify(otherData) }
            let data = JSON.stringify({
                "readerId": readerId,
                "amount": amount,
                "metadata": metadata,
                "customerId": memberDetails.stripeCustomerId ? memberDetails.stripeCustomerId : "",
                "receiptEmail": memberDetails.email
            });
            let config = {
                method: 'post',
                url: `${Base_Url}/terminalReadersProcessPaymentIntents`,
                headers: {
                    'Content-Type': 'application/json',
                    'Acess-Control-Allow-Origin': '*',
                    'Authorization': `${user.accessToken}`,
                    'Accept': "application/json"
                },
                data: data
            };
            axios.request(config).then((response) => {
                setIsLoading(false)
                setIsPaymentProcessing(false)
                if (response && response.data) {
                    setPaymentIntentObj(response.data.paymentIntent)
                    setReaderObj(response.data.reader)
                    setMsgText(`Processing payment for ${amount} on reader ${JSON.stringify(response.data.reader.id)}`)
                }
            }).catch((error) => {
                setIsLoading(false)
                setIsPaymentProcessing(false)
                console.log(error);
                setPaymentIntentObj(null)
                setReaderObj(null)
            });
        }
    }

    const simulatePresentingPaymentAPI = async (readerId) => {
        let data = JSON.stringify({
            "readerId": readerId
        });
        let config = {
            method: 'post',
            url: `${Base_Url}/simulatePresentingPayment`,
            headers: {
                'Content-Type': 'application/json',
                'Acess-Control-Allow-Origin': '*',
                'Authorization': `${user.accessToken}`,
                'Accept': "application/json"
            },
            data: data
        };
        axios.request(config).then((response) => {
            if (response && response.data) {
                let msg = msgText.toString() + `\n\nSimulating a customer tapping their card on simulated reader ${readerObj.id} for payment ${paymentIntentObj.id}`
                setMsgText(msg)
                setReaderObj(response.data.reader)
            }
        }).catch((error) => {
            console.log(error);
            setPaymentIntentObj(null)
            setReaderObj(null)
        });
    }

    const capturePaymentAPI = async (paymentIntentId) => {
        setIsLoading(true)
        let data = JSON.stringify({
            "paymentIntentId": paymentIntentId
        });
        let config = {
            method: 'post',
            url: `${Base_Url}/capturePayment`,
            headers: {
                'Content-Type': 'application/json',
                'Acess-Control-Allow-Origin': '*',
                'Authorization': `${user.accessToken}`,
                'Accept': "application/json"
            },
            data: data
        };
        axios.request(config).then((response) => {
            setIsLoading(false)
            if (response && response.data) {
                setPaymentIntentObj(response.data.paymentIntent)
                let msg = msgText.toString() + `\nCaptured payment for ${paymentIntentId}`
                setMsgText(msg)
            }
        }).catch((error) => {
            setIsLoading(false)
            console.log(error);
        });
    }

    const readersCancelActionAPI = async (readerId) => {
        let data = JSON.stringify({
            "readerId": readerId
        });
        let config = {
            method: 'post',
            url: `${Base_Url}/readersCancelAction`,
            headers: {
                'Content-Type': 'application/json',
                'Acess-Control-Allow-Origin': '*',
                'Authorization': `${user.accessToken}`,
                'Accept': "application/json"
            },
            data: data
        };
        axios.request(config).then((response) => {
            if (response && response.data) {
                setPaymentIntentObj(null)
                setReaderObj(null)
                setMsgText('')
            }
        }).catch((error) => {
            console.log(error)
            setPaymentIntentObj(null)
            setReaderObj(null)
            setMsgText('')
        });
    }

    const getProductsListAPI = async () => {
        let config = {
            method: 'get',
            url: `${Base_Url}/getStripeProductsPrice`,
            headers: {
                'Content-Type': 'application/json',
                'Acess-Control-Allow-Origin': '*',
                'Authorization': `${user.accessToken}`,
                'Accept': "application/json"
            },
        };
        setIsLoading(true)
        axios.request(config)
            .then((response) => {
                setIsLoading(false)
                if (response && response.data && response.data.data) {
                    // response.data.data?.
                    let filteredProducts = response?.data?.data?.filter(product => product.metadata && product.metadata.Category !== "Subscription");
                    let categoriesArr = [];
                    let allProductArr = [];
                    categoriesArr.push("All");
                    filteredProducts.forEach(product => {
                        if (product.metadata && product.metadata.C && product.metadata.C != "") {
                            categoriesArr.push(product.metadata.C)
                            allProductArr.push(product)
                        }
                    })
                    let uniqueCategories = Array.from(new Set(categoriesArr));
                    setCategoryList(uniqueCategories)
                    setProductList(allProductArr)
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error);
            });
    }

    const checkIntentStatusAPI = async (paymentIntentId) => {
        setIsLoading(true)
        let data = JSON.stringify({
            "paymentIntentId": paymentIntentId
        });
        let config = {
            method: 'post',
            url: `${Base_Url}/getPaymentIntentById`,
            headers: {
                'Content-Type': 'application/json',
                'Acess-Control-Allow-Origin': '*',
                'Authorization': `${user.accessToken}`,
                'Accept': "application/json"
            },
            data: data
        };
        axios.request(config).then((response) => {
            setIsLoading(false)
            if (response && response.data) {
                console.log("response.data->", response.data)
                setPaymentIntentObj(response.data.data)
                if (response.data.data?.status === 'succeeded') {
                    //TODO: what to do
                    addOrderHistoryFIR(response.data.data, memberDetails?.email)
                }
            }
        }).catch((error) => {
            setIsLoading(false)
            console.log(error);
        });
    }

    const chargeOnFilePaymentIntentsAPI = async () => {
        if (selectedProducts && selectedProducts.length > 0) {
            setIsLoading(true)
            let amount = calculateTotalPriceWithGratuity()
            let otherData = {
                additionalTip: additionalTip,
                gratuity: calculateGratuity(),
                memberEmail: memberDetails.email
            }
            let metadata = { isPaymentFrom: "Purchase_Product", productInfo: JSON.stringify(getSelectedProductsMetadata()), otherInfo: JSON.stringify(otherData) }
            let data = JSON.stringify({
                "amount": amount,
                "metadata": metadata,
                "customerId": memberDetails.stripeCustomerId ? memberDetails.stripeCustomerId : "",
                "receiptEmail": memberDetails.email
            });
            
            let config = {
                method: 'post',
                url: `${Base_Url}/chargeCardOnFile`,
                headers: {
                    'Content-Type': 'application/json',
                    'Acess-Control-Allow-Origin': '*',
                    'Authorization': `${user.accessToken}`,
                    'Accept': "application/json"
                },
                data: data
            };
            setIsPaymentProcessing(true)
            axios.request(config).then((response) => {
                setIsPaymentProcessing(false)
                if (response && response.data) {
                    let aobj = {
                        id: response.data.paymentIntentId,
                        status: response.data.status
                    }
                    checkIntentStatusAPI(response.data.paymentIntentId)
                    setPaymentIntentObj(aobj)
                    setMsgText(`Payment Collected ${amount}. Reset & Collect New Payment`)
                }
                setIsLoading(false)
            }).catch((error) => {
                setIsLoading(false)
                setIsPaymentProcessing(false)
                alert(error)
                setPaymentIntentObj(null)
                setReaderObj(null)
            });
        }
    }

    async function chargeCardOnFile() {
        if (memberDetails && memberDetails.stripeCustomerId) {
            //console.log("memberDetails->", memberDetails)
            //TODO: Call API that take chage from file and add order history same as terminal device.
            chargeOnFilePaymentIntentsAPI()
        }
    }

    const filterDataByC = (selectedFilter) => {
        if (selectedFilter === "All") {
            // If selected filter is "All", return the original list
            return productListArr;
        } else {
            // Filter the list based on the selected filter
            return productListArr.filter(product => product.metadata.C === selectedFilter);
        }
    };

    //Actions
    const handleFilterClick = (category) => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
        setFilterState({ selectedFilter: category });
    };

    const handleProductClick = (product) => {
        const isAlreadySelected = selectedProducts.some((selectedProduct) => selectedProduct.id === product.id);

        if (isAlreadySelected) {
            // Product is already selected, remove it from the selectedProducts array
            const updatedProducts = selectedProducts.filter((selectedProduct) => selectedProduct.id !== product.id);
            setSelectedProducts(updatedProducts);
        } else {
            // Product is not selected, add it to the selectedProducts array with default quantity 1
            setSelectedProducts([...selectedProducts, { ...product, quantity: 1 }]);
        }
    };

    // const handleProductClick = (product) => {
    //     setSelectedProduct(product);
    // };

    function resetAndCollectNew() {
        setPaymentIntentObj(null)
        setReaderObj(null)
        setMsgText('')
        setSelectedReaderId(null)
        setSelectedProducts([])
        setSelectedProductIds([]);
        setMemberDetails(null)
        setAdditionalTip(0)

    }
    const isSimulateable = () => {
        if (readerObj) {
            return readerObj?.device_type?.includes("simulated") && paymentIntentObj?.id
                ? paymentIntentObj?.status !== 'succeeded' ? true : false
                : false;
        }
        return false;
    };

    const isCheckStausView = () => {
        if (readerObj) {
            return paymentIntentObj?.id ?
                paymentIntentObj?.status !== 'succeeded' ?
                    true :
                    false :
                false;
        }
        return false;
    };

    const isProcessable = () => {
        if (memberDetails && memberDetails.firstName) {
            return selectedReaderId ? paymentIntentObj && paymentIntentObj.id !== "" ? false : true : false;
        } else {
            return false
        }
    };

    const isCapturable = () => {
        return paymentIntentObj?.id ? true : false;
    };

    const calculateTotalPrice = () => {
        return selectedProducts.reduce((total, product) => total + (product.price * product.quantity), 0);
        //return selectedProducts.reduce((total, product) => total + product.price, 0);
    };

    const calculateGratuity = () => {
        const totalPrice = calculateTotalPrice();
        const gratuity = totalPrice * 0.2; // Calculate 20% gratuity
        return gratuity;
    };

    const calculateTotalPriceWithGratuity = () => {
        const totalPrice = calculateTotalPrice();
        const gratuity = totalPrice * 0.2; // Calculate 20% gratuity
        const tips = additionalTip * 100;
        return totalPrice + gratuity + tips;
    };
    useEffect(() => {
        if (selectedReaderId) {
            setSelectedProductIds([]);
        }
    }, [selectedReaderId]);

    return (
        <Layout>
            <div className={styles.content} style={!isSidebarOpen ? { width: "97vw" } : { width: "calc(100vw - 320px)" }}>
                <h2 className={"pos_header"}>Collecting Payments with Stripe Terminal</h2>
                <div className={"pos-instructions__card-layout"}>
                    <div className="pointer" style={{ width: '100%' }} onClick={() => { setIsShowInstruction(!isShowInstruction) }}>
                        <div className={"d-flex"} style={{ justifyContent: 'space-between' }}>
                            <div className={"d-flex"}>
                                <InstructionsSVG />
                                <span className={"pos-instructions-card__header"}>INSTRUCTIONS</span>
                            </div>
                            {
                                isShowInstruction ? <UPArrowSVG /> : <DownArowSVG />
                            }
                        </div>
                    </div>
                    {
                        isShowInstruction &&
                        <>
                            <Divider className={"pos-instructions-card__hr"} />
                            <div>
                                <div className={"d-flex "}>
                                    <div className={'pos-instructions-card__index vertical-center'}>1</div>
                                    <div className={"pos-instructions-card_steps"}>Select a reader and amount for the
                                        transaction.
                                    </div>
                                </div>
                                <div className={"d-flex vertical-center"} style={{ marginTop: "16px" }}>
                                    <div className={'pos-instructions-card__index vertical-center'}>2</div>
                                    <div className={"pos-instructions-card_steps"}>Click Process - Check POS and Swipe Card
                                    </div>
                                </div>
                                <div className={"d-flex vertical-center"} style={{ marginTop: "16px" }}>
                                    <div className={'pos-instructions-card__index vertical-center'}>3</div>
                                    <div className={"pos-instructions-card_steps"}>Once POS show success then Click on Check
                                        status
                                    </div>
                                </div>
                            </div>
                            <Divider className={''} style={{ marginTop: "6px", marginBottom: "7px" }} />
                            <div className={""} style={{ display: "flex" }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><InfoSVG /></div></div>
                                <div className={"pos-instructions-card_info"}>Reader Status & Payment Intent status success mean
                                    Payment collected
                                </div>
                            </div>
                            <Divider className={''} style={{ marginTop: "4px", marginBottom: "5px" }} />
                            <div className={" "} style={{ display: "flex" }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ErrorWithWarningSVG /></div></div>
                                <div className={"pos-instructions-card_Warning"}>Please don’t reset until Payment status update
                                    to fail or success
                                </div>
                            </div>
                        </>
                    }

                </div>

                {/*<p>Step1. Select a reader and amount for the transaction.</p>*/}
                {/*<p>Step2. Click Process - Check POS and Swipe Card</p>*/}
                {/*<p>Step3. Once POS show success then Click on Check status</p>*/}
                {/*/!* <p>Step4. Reader Status success then Click Capture</p> *!/*/}
                {/*<p>Reader Status & Payment Intent status success mean Payment collected</p>*/}
                {/*<p>Don't reset untill Payment status update to fail or success</p>*/}
                <div className={"find-user__container"}>
                    <label className={"pos-field__label"}> Device</label>
                    <Dropdown
                        placeholder={"Select Device"}
                        className={"pos-dropdown__field"}
                        aria-labelledby={`1-outline`}
                        style={{ marginTop: "8px" }}
                        onOptionSelect={(ev, data) => {
                            setSelectedReaderId(data.selectedOptions[0])
                        }}
                    >
                        {readerList?.map((option) => (
                            <Option key={option.id} value={option.id}>
                                {`${option.label}`}
                            </Option>
                        ))}
                    </Dropdown>
                </div>

                <div className={"d-flex"} style={{ alignItems: "center", marginTop: "20px" }}>
                    <div>
                        <label className={"pos-field__label"}> Member Number</label>

                        <Input onbl value={memberNumber} className={"pos-input__field"}
                            style={{ marginTop: "8px" }}
                            placeholder={"Enter Number"}
                            onChange={(ev, newVal) => setMemberNumber(newVal.value)} />
                    </div>
                    <div >
                        <label className={"pos-field__label"} style={{ visibility: "hidden" }}> .</label>
                        <button className={" pos-verify__btn pointer primaryHover"} shape="circular" style={{ marginTop: "8px" }} onClick={() => {
                            getUserDetails()
                        }}>Verify
                        </button>
                    </div>
                    <div className={"pos-search-member_card"}>
                        <label className={"pos-field__label"}> Search Member </label>
                        <Input onbl
                            value={searchMember}
                            className={"pos-input__field"}
                            style={{ borderRadius: "12px !important", marginTop: "8px" }}
                            placeholder={"Search by Last Name"}
                            contentBefore={<SearchSVG />}
                            onChange={(ev, newVal) => setSearchMember(newVal.value)} />
                    </div>
                    <div>
                        <label className={"pos-field__label"} style={{ visibility: "hidden" }}> .</label>
                        <button className={"pos-find-number__btn pointer"} shape="circular" onClick={() => {
                            getUserDetailByLastName(searchMember)
                        }}>FIND NUMBER
                        </button>
                    </div>
                    {userListByLastName?.length >= 2 &&
                        <div>
                            <Combobox multiselect={false}
                                className={"pos-input__field"}
                                style={{ height: 50, marginTop: 24, marginLeft: "16px" }}
                                //placeholder={linkUserPlaceholder}
                                onOptionSelect={(e, data) => {

                                    setMemberNumber(data?.optionValue
                                    )
                                }}

                            // selectedOptions={field.value}
                            >
                                {userListByLastName?.map((option) => (
                                    <Option key={option.id} value={option.memberNumber
                                    }>
                                        {`${option.firstName} ${option.lastName}`}
                                    </Option>
                                ))}
                                {/*{matchingOptions.length === 0 ? (*/}
                                {/*    <Option key="no-results" text="">*/}
                                {/*        No results found*/}
                                {/*    </Option>*/}
                                {/*) : null}*/}
                            </Combobox>
                        </div>}
                </div>
                {memberDetails && memberDetails?.firstName && (
                    <div style={{ marginTop: 10, width: '50%' }}>
                        <Label style={{ marginRight: 20, }}>Email: {memberDetails?.email}</Label>
                    </div>
                )
                }
                <div className={"pos-select-product_container d-flex vertical-center"}>
                    <div><SelectProductSVG /></div>
                    <span className={"pos-select-product__header"}>
                        Select Products
                    </span>
                </div>

                <div className="d-flex">
                    {categoryList && categoryList.length > 0 && categoryList.map((category, index) => (
                        <div
                            key={index}
                            className={`pos-select-product-hover pos-select-product__filter d-flex pointer ${filterState.selectedFilter === category ? "selected-filter" : ""}`}
                            onClick={() => handleFilterClick(category)}
                        >
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                {/* Here you can render different icons based on category */}
                                {category === 'All' ? <SelectProductFilterAll /> : <SelectProductFilterAll />}
                                {/* Add other category icons as needed */}
                            </div>
                            <div>{category}</div>
                        </div>
                    ))}
                </div>

                {/*<div style={{marginTop: 20, width: '50%'}}>*/}
                {/*    <Label style={{marginRight: 30,}}> Device</Label>*/}
                {/*    <Dropdown*/}
                {/*        style={{width: '50%'}}*/}
                {/*        aria-labelledby={`1-outline`}*/}
                {/*        onOptionSelect={(ev, data) => {*/}
                {/*            setSelectedReaderId(data.selectedOptions[0])*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        {readerList.map((option) => (*/}
                {/*            <Option key={option.id} value={option.id}>*/}
                {/*                {`${option.label}`}*/}
                {/*            </Option>*/}
                {/*        ))}*/}
                {/*    </Dropdown>*/}
                {/*</div>*/}

                {/*<div style={{ marginTop: 10, width: '50%' }}>*/}
                {/*    <Label style={{ marginRight: 10, }}>Member Number</Label>*/}
                {/*    <Input onbl style={{ width: '50%' }} value={memberNumber}*/}
                {/*        onChange={(ev, newVal) => setMemberNumber(newVal.value)} />*/}
                {/*</div>*/}
                {/*<Button style={{ marginTop: 10 }} shape="circular" appearance="primary" onClick={() => {*/}
                {/*    getUserDetails()*/}
                {/*}}>Verify Member</Button>*/}
                {/*{*/}
                {/*    memberDetails && memberDetails.firstName && (*/}
                {/*        <div style={{ marginTop: 10, width: '50%' }}>*/}
                {/*            <Label style={{ marginRight: 20, }}>Email: {memberDetails.email}</Label>*/}
                {/*        </div>*/}
                {/*    )*/}
                {/*}*/}

                {!isLoading && <div style={{ marginTop: 32 }}>
                    <ProductList isReset={selectedReaderId ? false : true} data={filterDataByC(filterState.selectedFilter)}
                        onProductClick={handleProductClick} selectedProductIds={selectedProductIds} toggleProductSelection={toggleProductSelection} />
                </div>}
                {
                    isLoading && <div className={'d-flex '} style={{ marginTop: 32 }}>


                        {[...Array(6)].map(
                            (d, i) => (

                                <div className={"pos_loading_card__box"} key={i}>

                                    <SkeletonItem shape="rectangle" size={128} />
                                    <Divider />
                                    <div style={{ marginTop: "10px", width: "120px", marginLeft: "4px" }}>
                                        <SkeletonItem shape="rectangle" size={12} />
                                    </div>
                                    <div style={{ marginTop: "3px", width: "50px", marginLeft: "4px" }}>
                                        <SkeletonItem shape="rectangle" size={12} />
                                    </div>
                                    <div style={{ marginTop: "30px", width: "40px", marginLeft: "4px" }}>
                                        <SkeletonItem shape="rectangle" size={12} />
                                    </div>
                                    {/*<SkeletonItem shape="rectangle" size={120} />*/}
                                </div>
                            )
                        )

                        }
                    </div>
                }

                {selectedProducts?.length > 0 &&
                    <div className={"d-flex"} style={{ marginTop: "49px" }} >


                        <div className={"pos-selected-list-table__container "}>
                            <div className={"d-flex"}>

                                <div className={"pos-selected-list-table__header"} style={{ width: "40%", marginLeft: "16px" }}>PRODUCT</div>
                                <div className={"pos-selected-list-table__header d-flex justify-center"} style={{ width: "20%" }}>QTY</div>
                                <div className={"pos-selected-list-table__header d-flex justify-center"} style={{ width: "20%" }}>PRICE</div>
                                <div className={"pos-selected-list-table__header d-flex justify-center"} style={{ width: "20%" }}>Total</div>
                            </div>
                            <Divider />
                            {selectedProducts?.map((product) => (
                                <div className={"d-flex vertical-center"} key={product.id}>

                                    <div className={"pos-selected-list-table__header "}
                                        style={{ width: "40%", alignItems: "center", display: "flex" }}>
                                        <img src={!!product?.images[0] ? product?.images[0] : placeholderImageUrl} height={"48px"} width={"40px"} alt={"placeholderImageUrl"}
                                            className={"pos-selected-list-table__image"} />
                                        <div className={"pos-selected-list-table__item-label"}>{product?.productName}</div>
                                    </div>
                                    <div className={"pos-selected-list-table__header d-flex  justify-center vertical-center"}
                                        style={{ width: "20%" }}>
                                        <div
                                            onClick={() => {
                                                const newQuantity = parseInt(product?.quantity) - 1;
                                                if (!isNaN(newQuantity) && newQuantity > 0) {
                                                    const updatedProducts = selectedProducts.map((p) => {
                                                        if (p.id === product.id) {
                                                            return { ...p, quantity: newQuantity };
                                                        }
                                                        return p;
                                                    });
                                                    setSelectedProducts(updatedProducts);
                                                }
                                            }}
                                            className={"pos-selected-list-table__add-price d-flex justify-center vertical-center pointer pos-qnt-decrement "}
                                            style={{ background: "#BDBDBD" }}>
                                            -
                                        </div>
                                        <div className={"pos-selected-list-table__price pos-selected-list-table__counts"}>
                                            <div className={"pos-price__label"}>{product?.quantity}</div>
                                        </div>
                                        <div
                                            className={"pos-selected-list-table__add-price d-flex justify-center vertical-center pointer pos-qnt-increment"}
                                            onClick={() => {
                                                const newQuantity = parseInt(product?.quantity) + 1;
                                                if (!isNaN(newQuantity) && newQuantity > 0) {
                                                    const updatedProducts = selectedProducts.map((p) => {
                                                        if (p.id === product.id) {
                                                            return { ...p, quantity: newQuantity };
                                                        }
                                                        return p;
                                                    });
                                                    setSelectedProducts(updatedProducts);
                                                }
                                            }}
                                            style={{ background: "#0A4" }}>+
                                        </div>
                                    </div>
                                    <div className={" pos-selected-list-table__price vertical-center d-flex justify-center pos-price__label"}
                                        style={{ width: "20%" }}>${product.price / 100}
                                    </div>
                                    <div className={" pos-selected-list-table__price vertical-center d-flex justify-center pos-price__label"}
                                        style={{ width: "20%" }} >${(product.price * product.quantity) / 100}
                                    </div>
                                </div>
                            ))}

                        </div>
                        <div className={"pos-payment-summary "}>
                            <div className={"pos-payment-summary__header-tag  d-flex vertical-center"}>
                                <PaymentSummrySVG />
                                <div className={"pos-payment-summary__header"}>
                                    Payment Summary
                                </div>
                            </div>
                            <Divider />
                            <div>
                                <div className={"d-flex justify-space-between"}>
                                    <div className={"pos-payment-summary__data"}>
                                        Total:
                                    </div>
                                    <div className={" pos-price__label"} style={{ fontSize: "16px", paddingRight: 24, paddingTop: "22px" }}>
                                        ${calculateTotalPrice() / 100}
                                    </div>
                                </div>
                                <div className={"d-flex justify-space-between"}>
                                    <div className={"pos-payment-summary__data pos-price__label"}>
                                        Gratuity:
                                    </div>
                                    <div className={" pos-price__label"} style={{ fontSize: "16px", paddingRight: 24, paddingTop: "22px" }}>
                                        ${calculateGratuity() / 100}
                                    </div>
                                </div>
                                <div className={"d-flex justify-space-between"}>
                                    <div className={"pos-payment-summary__data"}>
                                        Additional Tip:
                                    </div>
                                    <div className={"pos-payment-summary__data"} style={{ marginButton: "22px" }}>
                                        <input className={"pos-payment-summary__input"}
                                            value={additionalTip}
                                            onChange={(ev) => {
                                                ev.target.value >= 0 ? setAdditionalTip(ev.target.value) : setAdditionalTip(0)
                                            }}
                                            placeholder={"Enter Additional Tip"} />
                                    </div>
                                </div>
                                <Divider style={{ paddingTop: "10px" }} />
                                <div className={"d-flex justify-space-between"}>
                                    <div className={"pos-payment-summary__data"} style={{ paddingTop: "10px" }}>
                                        Grand Total:
                                    </div>
                                    <div className={" pos-price__label"} style={{ paddingTop: "10px", fontSize: "16px", paddingRight: 24 }}>
                                        ${calculateTotalPriceWithGratuity() / 100}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    paymentIntentObj && paymentIntentObj.id && paymentIntentObj.status !== 'succeeded' ?
                        <div style={{ marginTop: 10 }}>
                            <Text size={500}>Swipe or tap card now.</Text>
                        </div> : <div />
                }
                {
                    paymentIntentObj && paymentIntentObj.id && paymentIntentObj.status === 'succeeded' ?
                        <div style={{ marginTop: 10 }}>
                            <Text size={500}>Payment Collected. Reset & Collect New Payment</Text>
                        </div> : <div />
                }
                <div className={"d-flex justify-space-between vertical-center"}>

                    <div style={{ marginTop: 48, display: "flex" }}>

                        {/* <Button disabled={!isCapturable()} style={{ marginRight: 20 }} shape="circular" appearance="primary" onClick={() => { capturePaymentAPI(paymentIntentObj.id) }}>Capture</Button> */}
                        <Button className={"pos-primary__btn primaryHover "}
                            disabled={!isProcessable()}
                            style={{ marginRight: 20, opacity: !isProcessable() ? 0.4 : 1 }}
                            shape="circular"
                            appearance="primary"
                            onClick={() => {
                                processPaymentIntentsAPI(selectedReaderId)
                            }}>
                            <span className={"d-flex vertical-center pos-button-typo"}><SendTerminalSVG />SEND TERMINAL</span>
                        </Button>

                        {/* <Button disabled={!isCapturable()} style={{ marginRight: 20 }} shape="circular" appearance="primary" onClick={() => { capturePaymentAPI(paymentIntentObj.id) }}>Capture</Button> */}
                        <Button className={"pos-primary__btn pos-button- primaryHover"}
                            disabled={!isCheckStausView()}
                            style={{ marginRight: 20, opacity: !isCheckStausView() ? 0.4 : 1 }} shape="circular"
                            appearance="primary"
                            onClick={() => {
                                checkIntentStatusAPI(paymentIntentObj?.id)
                            }}>CHECK STATUS</Button>

                        <Button className={"pos-secondary__btn pos-button-typo "}
                            disabled={!isSimulateable()}
                            style={{ marginRight: 20, opacity: !isSimulateable() ? 0.4 : 1 }} shape="circular"
                            appearance="primary"
                            onClick={() => {
                                simulatePresentingPaymentAPI(selectedReaderId)
                            }}>SIMULATE PAYMENT</Button>

                        <Button className={"pos-secondary__btn pos-button-typo"}
                            disabled={readerObj ? false : true}
                            style={{ marginRight: 20, opacity: readerObj ? 1 : 0.4 }} shape="circular"
                            appearance="primary" onClick={() => {
                                readersCancelActionAPI(readerObj?.id)
                            }}>CANCEL</Button>
                    </div>
                    <div style={{ marginTop: 48 }} className={"reset-and-collect-payment"}>
                        <Button className={"pos-secondary__btn pos-button-typo"} style={{ marginRight: 20 }} shape="circular" appearance="primary"
                            onClick={() => {
                                resetAndCollectNew()
                            }}>RESET & COLLECT NEW PAYMENT</Button>
                    </div>

                </div>
                <div className={"d-flex justify-end"}>
                    {isPaymentProcessing ? (
                        // Render loader when payment is processing
                        <div style={{marginRight: 20}}>
                            <Spinner/>
                        </div>
                    ) : (
                        <Button
                            disabled={false}
                            className={"pos-secondary__btn pos-button-typo"}
                            style={{ marginRight: 20, marginTop: "16px", opacity: 1 }}
                            shape="circular"
                            appearance="primary"
                            onClick={chargeCardOnFile}>
                            CHARGE CARD ON FILE
                        </Button>
                    )}
                </div>


                <div>
                    <div className={"pos-payment-details__typo vertical-center d-flex"} >
                        <PaymentIntentIDSVG /><p style={{ paddingLeft: 8 }}>Payment Intent ID:</p>
                        <span>{paymentIntentObj?.id}</span>
                    </div>
                    <div className={"pos-payment-details__typo vertical-center d-flex"}>
                        <PaymentIntentStatusSVG /> <p style={{ paddingLeft: 8 }}>Payment Intent status:</p>
                        <span>{paymentIntentObj?.status}</span>
                    </div>
                    <div className={"pos-payment-details__typo vertical-center d-flex"}>
                        <ReaderStatusSVG /><p style={{ paddingLeft: 8 }}>Reader Status:</p>
                        <span>{readerObj?.action?.status}</span>
                    </div>
                </div>
                <div style={{ marginTop: 20 }}>
                    <Label>{msgText}</Label>
                </div>
            </div>
        </Layout>
    )
}

export default TerminalReader;
import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase";

const drivingRoadsCollection = collection(db, "DrivingRoads");

class DrivingRoadDataService {
    addDrivingRoad = (newDrivingRoad) => {
        return addDoc(drivingRoadsCollection, newDrivingRoad);
    }

    updateDrivingRoad = (id, updatedDrivingRoad) => {
        const drivingRoadsDoc = doc(db, "DrivingRoads", id);
        return updateDoc(drivingRoadsDoc, updatedDrivingRoad);
    }

    deleteDrivingRoad = (id) => {
        const drivingRoadsDoc = doc(db, "DrivingRoads", id);
        return deleteDoc(drivingRoadsDoc);
    }

    getAllDrivingRoad = () => {
        return getDocs(drivingRoadsCollection)
    }

    getDrivingRoad = (id) => {
        const drivingRoadsDoc = doc(db, "DrivingRoads", id);
        return getDoc(drivingRoadsDoc);
    }
}

export default new DrivingRoadDataService();
export {
    sidebarStaticObject,

}


const sidebarStaticObject = {
    COMMUNITY: {
        isActive: false,
        subMenu: [
            {
                key: "Events",
                isActive: false,
                navigateTo: "/event"
            },
            {
                key: "Announcements",
                isActive: false,
                navigateTo: "/post"
            }
        ]
    },
    RESERVATIONS: {
        isActive: false,
        subMenu: [
            {
                key: "Service Types",
                isActive: false,
                navigateTo: "/vehicleservicetypes"
            },
            {
                key: "Providers",
                isActive: false,
                navigateTo: "/serviceprovider"
            },
            {
                key: "Services",
                isActive: false,
                navigateTo: "/services"
            },
            {
                key: "Room",
                isActive: false,
                navigateTo: "/room"
            },
            {
                key: "Coach",
                isActive: false,
                navigateTo: "/coach"
            },
            {
                key: "Rental",
                isActive: false,
                navigateTo: "/rental"
            },

        ]
    },
    LOCATIONS: {
        isActive: false,
        subMenu: [
            {
                key: "Restaurants",
                isActive: false,
                navigateTo: "/restaurants"
            },
            {
                key: "Driving Roads",
                isActive: false,
                navigateTo: "/drivingroads"
            },
            {
                key: "Attractions",
                isActive: false,
                navigateTo: "/attractions"
            },
        ]
    },
    PEOPLE: {
        isActive: false,
        subMenu: [
            {
                key: "Members",
                isActive: false,
                navigateTo: "/user"
            },
            {
                key: "Coach",
                isActive: false,
                navigateTo: "/CoachMember"
            },
            {
                key: "Rooms",
                isActive: false,
                navigateTo: "/addrooms"
            },
            {
                key: "Groups",
                isActive: false,
                navigateTo: "/group"
            },

            {
                key: "Vehicles",
                isActive: false,
                navigateTo: "/vehicle"
            },
            {
                key: "Vehicle Keys",
                isActive: false,
                navigateTo: "/keyCafeKeys"
            },
            {
                key: "Vehicle Requests",
                isActive: false,
                navigateTo: "/VehiclesRequest"
            },
            {
                key: "Message User",
                isActive: false,
                navigateTo: "/notifications"
            },
            {
                key: "App Settings",
                isActive: false,
                navigateTo: "/AppSettings"
            },
            {
                key: "Wallet History",
                isActive: false,
                navigateTo: "/UserWalletHistory"
            },
        ]
    },
    POS: {
        isActive: false,
        subMenu: [
            {
                key: "Orders",
                isActive: false,
                navigateTo: "/POSOrder"
            },
            {
                key: "Products",
                isActive: false,
                navigateTo: "/TerminalReader"
            },
            {
                key: "OrderHistory",
                isActive: false,
                navigateTo: "/ProductOrderHistory"
            },
        ]
    },
}
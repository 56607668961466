import { addDoc, collection, deleteDoc, doc, getDoc, updateDoc, getDocs } from "firebase/firestore";
import { db } from "./firebase";

const rentalLocationCollection = collection(db, "RentalLocation");

class RentalLocationDataService {
    addRentalLocation = (newRentalLocation) => {
        return addDoc(rentalLocationCollection, newRentalLocation);
    }

    updateRentalLocation = (id, updatedRentalLocation) => {
        const rentalLocationDoc = doc(db, "RentalLocation", id);
        return updateDoc(rentalLocationDoc, updatedRentalLocation);
    }

    deleteRentalLocation = (id) => {
        const rentalLocationDoc = doc(db, "RentalLocation", id);
        return deleteDoc(rentalLocationDoc);
    }

    getAllRentalLocation = () => {
        return getDocs(rentalLocationCollection)
    }

    getRentalLocation = (id) => {
        const rentalLocationDoc = doc(db, "RentalLocation", id);
        return getDoc(rentalLocationDoc);
    }
}

export default new RentalLocationDataService();
import React, { useEffect, useState } from "react";
import Layout from "./components/Layout";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, trackException } from "./services/firebase";
import { useNavigate } from "react-router-dom";
import {
    makeStyles, shorthands, Button, Table, TableBody,
    TableHeader, TableRow, TableHeaderCell, TableCell,
    TableCellLayout, TableCellActions, Label
} from "@fluentui/react-components";
import { query, collection, getDocs, where } from "firebase/firestore";
import { EditFilled, EditRegular, bundleIcon, DeleteFilled, DeleteRegular } from "@fluentui/react-icons";
import AttractionDataService from "./services/attractionsDataService";
import ImageDialog from "./components/ImageDialog";
import DeleteEventDialog from "./components/DeleteEventDialog";
import AttractionForm from "./components/Forms/AttractionFrom";
import rentalLocationDataService from "./services/rentalLocationDataService";
import CustomSearchInput from "./components/CustomSearchInput";
import debounce from 'lodash/debounce';
import { PlusSVG } from "./static/svgs";

const EditIcon = bundleIcon(EditFilled, EditRegular);
const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow("hidden"),
        display: "flex",
        height: "480px",
        backgroundColor: "#fff"
    },

    content: {
        ...shorthands.flex(1),
        ...shorthands.padding("16px"),
        display: "block",
        ...shorthands.gridArea("main")
    },

    breakFlex: {
        flexBasis: "100%",
        textAlign: "left"
    }
});

const columns = [
    { columnKey: "name", label: "Name" },
    { columnKey: "description", label: "Description" },
    { columnKey: "images", label: "Images" }
];

function Attractions() {
    const styles = useStyles();
    const [user, loading] = useAuthState(auth);
    const [isFormOpen, setIsFormOpen] = useState(false);
    const [selectedAttraction, setSelectedAttraction] = useState();
    const [allattractions, setAllAttractions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredAttraction, setFilteredAttraction] = useState([]);

    const [alllocations, setAllLocations] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const navigate = useNavigate();
    const openForm = (selectedItem) => {
        setIsFormOpen(true);
        setSelectedAttraction(selectedItem);
    }

    const fetchUserNameAndAttractions = async () => {
        try {
            if (!!user) {
                const userquery = query(collection(db, "Users"), where("email", "==", user?.email));
                const userdoc = await getDocs(userquery);
                if (userdoc.docs.length > 0) {
                    const attractionsQuery = await AttractionDataService.getAllAttraction();
                    let allAttractions = attractionsQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
                    setAllAttractions(allAttractions);
                    setFilteredAttraction(allAttractions)
                    //console.log(allevents);
                } else {
                    console.log('no access');
                }
            }
        } catch (err) {
            console.error(err);
            trackException(JSON.stringify(err))
            alert("An error occured while fetching user data");
        }
    };

    const fetchAllLocations = async () => {
        try {
            const vehiclesQuery = await rentalLocationDataService.getAllRentalLocation();
            setAllLocations(vehiclesQuery.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        } catch (error) {
            console.error(error);
            alert("An error occured while fetching location data");
        }
    };

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/");
        fetchUserNameAndAttractions();
        fetchAllLocations();
    }, [user, loading]);

    const openDeleteForm = (selectedItem) => {
        setIsDeleteDialogOpen(true);
        setSelectedAttraction(selectedItem);
        // fetchUserNameAndEvents();
    }

    const DeleteAttraction = async () => {
        AttractionDataService.deleteAttraction(selectedAttraction.id);
        setIsDeleteDialogOpen(false);
        fetchUserNameAndAttractions();
    };
    // Debounce the handleSearch function
    const debouncedSearch = debounce(search => {
        const lowerSearch = search.toLowerCase();
        const filtered = allattractions.filter(event =>
            event.name.toLowerCase().includes(lowerSearch)
        );
        setFilteredAttraction(filtered);
    }, 300); // Adjust the debounce delay as needed

    const handleSearch = event => {
        const value = event.target.value;
        setSearchTerm(value);
        debouncedSearch(value);
    };
    const handleClear = () => {
        setSearchTerm('');
        debouncedSearch('');
    };

    return (
        <Layout>
            <div className={styles.content}>
                <div className={"d-flex justify-space-between"}>
                    <Button icon={<PlusSVG />} className={"primary-btn"} shape="circular" appearance="primary" onClick={() => openForm(undefined)}>{'New Attraction'.toUpperCase()}</Button>
                    <CustomSearchInput
                        value={searchTerm}
                        onChange={handleSearch}
                        onClick={handleClear}
                    />
                </div>
                <div className={"table-layout"}>
                    <Table aria-label="Table with cell actions">
                        <TableHeader className={"list-row"}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableHeaderCell key={column.columnKey}>
                                        <span className={"listHeaderText"}>{column.label}</span>
                                    </TableHeaderCell>
                                ))}
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredAttraction.length > 0 && filteredAttraction.map((item) =>
                                <TableRow key={item.id}>
                                    <TableCell className={"table-row-fonts__primary"}>
                                        <TableCellLayout>
                                            {item.name}
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <TableCellLayout>
                                            <Label title={item.description}>{item.description.substring(0, 150)}</Label>
                                        </TableCellLayout>
                                    </TableCell>
                                    <TableCell className={"table-row-fonts__desc"}>
                                        <Button className={"btnBorderOnly"} shape="circular" onClick={() => {
                                            setOpen(true);
                                            setSelectedAttraction(item);
                                        }}>Images</Button>
                                        <TableCellActions>
                                            <Button
                                                icon={<EditIcon />}
                                                appearance="subtle"
                                                aria-label="Edit"
                                                onClick={() => openForm(item)}
                                            />
                                            <Button
                                                icon={<DeleteIcon />}
                                                appearance="subtle"
                                                aria-label="Delte"
                                                onClick={() => openDeleteForm(item)}
                                            />
                                        </TableCellActions>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <AttractionForm isOpen={isFormOpen} setIsOpen={(val) => setIsFormOpen(val)} selectedAttraction={selectedAttraction}
                updateGrid={fetchUserNameAndAttractions} locations={alllocations}></AttractionForm>
            <ImageDialog open={open} setOpen={setOpen} selectedEvent={selectedAttraction}></ImageDialog>
            <DeleteEventDialog isDeleteDialogOpen={isDeleteDialogOpen}
                setIsDeleteDialogOpen={setIsDeleteDialogOpen} DeleteEvent={DeleteAttraction} text={'Attraction'} />
        </Layout>
    )
}

export default Attractions;
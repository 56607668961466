import {
  Button,
  Dropdown,
  Image,
  Input,
  Label,
  Option,
  Spinner,
  Switch,
  Textarea,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  DrawerOverlay,
} from "@fluentui/react-components/unstable";
import {
  CalendarCancelFilled,
  DeleteFilled,
  DeleteRegular,
  Dismiss24Regular,
  RocketRegular,
  bundleIcon,
} from "@fluentui/react-icons";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Base_Url, storage, trackException } from "../../services/firebase";
import VehicleDataService from "../../services/vehicleDataService";
import heic2any from "heic2any";
import axios from "axios";
import { Timestamp } from "firebase/firestore";
import DatePicker from "react-multi-date-picker";

export const InitialVehicleState = {
  color: "",
  description: "",
  drivingInstructions: "",
  engineDetails: "",
  id: "",
  images: [],
  isPartOfWarehouseRentalFleet: false,
  isPartOfWarehouseSalesFleet: false,
  isStoredAtUsersHome: false,
  keyCafeFob: "",
  make: "",
  model: "",
  owner: "",
  previewImage: "",
  carCost: 0,
  groupId: "",
  speedDetails: "",
  transmissionDetails: "",
  vin: "",
  warehouseBayNumber: 0,
  year: "",
  rentalLocation: "",
  keyCafeKeyId: "",
  keyCafeName: "",
  imgLandscape: "",
  imgPortrait: "",
  pointsPerDay: 0,
  pricePerDay: 0,
  mileageAllowed: 0,
  overageMile: 0,
  rentalBaseCost: 0,
  vehicalCarWashPrice: 0,
  additionalInsuranceCost: 0,
  emptyTankFee: 0,
  currentMileage: 0,
  createdAt: null
};

const DeleteIcon = bundleIcon(DeleteFilled, DeleteRegular);

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    // Use 2px gap below the label (per the design system)
    ...shorthands.gap("2px"),
  },
  control: {
    maxWidth: "300px",
  },
});

function VehicleForm(props) {
  const styles = useStyles();
  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues:
      props.selectedVehicle && typeof props.selectedVehicle == "object"
        ? props.selectedVehicle
        : InitialVehicleState,
  });
  const [imgLandscape, setImgLandscape] = useState(
    props.selectedVehicle && typeof props.selectedVehicle == "object"
      ? props.selectedVehicle.imgLandscape
      : ""
  );
  const [fileMainImage, setFileMainImage] = useState("");

  const [imgPortrait, setImgPortrait] = useState(
    props.selectedVehicle && typeof props.selectedVehicle == "object"
      ? props.selectedVehicle.imgPortrait
      : ""
  );
  const [fileImgPortrait, setFileImgPortrait] = useState("");

  const [images, setImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);

  const [percent, setPercent] = useState(0);
  const [isShowFobList, setShowFobList] = useState(false);
  const [selectedMemberPlaceholder, setSeletedMemberPlaceholder] = useState("");
  const [seletedGroup, setSeletedGroup] = useState(null);
  const [valueDate, setValueDate] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (formdata) => {
    var data = formdata;
    data.createdDate = new Date().toISOString();
    var id = "";
    let selectedFob = props.fobKeys.filter(
      (obj) => obj.keyCafeId == data.keyCafeKeyId
    );
    if (selectedFob && selectedFob[0]?.keyCafeId != "Add Later") {
      data.keyCafeKeyId = selectedFob[0]?.keyCafeId;
      data.keyCafeFob = selectedFob[0]?.fob;
      data.keyCafeName = selectedFob[0]?.keyCafeName;
    } else {
      data.keyCafeKeyId = "";
      data.keyCafeFob = "";
      data.keyCafeName = "";
    }
    if (data.keyCafeFob && data.keyCafeFob != "" && data.keyCafeFob != "Add Later") {
      try {
        const validFobKey = await getValidFobKey(data.keyCafeFob);
        if (validFobKey) {
          data.keyCafeKeyId = validFobKey?.keyCafeId;
          data.keyCafeFob = validFobKey?.fob;
          data.keyCafeName = validFobKey?.keyCafeName;
        }
      } catch (error) {
        alert("Invalid FOB#");
        console.error("Error occurred:", error);
      }
    }
    if (valueDate) {
      data.balckoutdate = valueDate.toString();
    }
    const currentTimestamp = Timestamp.now();
    data.updatedAt = currentTimestamp
    if (props.selectedVehicle && typeof props.selectedVehicle == "object") {
      id = props.selectedVehicle.id;
      data.imgLandscape = imgLandscape ? imgLandscape : "";
      data.imgPortrait = imgPortrait ? imgPortrait : "";
      if (uploadedImages && uploadedImages.length > 0) {
      } else {
        data.images = images;
      }
      if (data.keyCafeKeyId == undefined) {
        data.keyCafeKeyId = "";
        data.keyCafeFob = "";
        data.keyCafeName = "";
      }
      setIsLoading(true)
      await VehicleDataService.updateVehicle(props.selectedVehicle.id, data);
      setIsLoading(false)
    } else {
      if (data.keyCafeKeyId == undefined) {
        data.keyCafeKeyId = "";
        data.keyCafeFob = "";
        data.keyCafeName = "";
      }
      data.createdAt = currentTimestamp
      const addeddata = await VehicleDataService.addVehicle(data);
      id = addeddata.id;
      data.id = id;
      setIsLoading(true)
      await VehicleDataService.updateVehicle(id, data);
      setIsLoading(false)
    }

    if (imgLandscape && fileMainImage) {
      const storageRef = ref(
        storage,
        `/vehicles/Landscape_${fileMainImage.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, fileMainImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            data.imgLandscape = url;
            await VehicleDataService.updateVehicle(id, data);
          });
        }
      );
    } else {
      if (imgLandscape && imgLandscape != "") {
      } else {
        data.imgLandscape = "";
        await VehicleDataService.updateVehicle(id, data);
      }
    }

    if (imgPortrait && fileImgPortrait) {
      const storageRef = ref(
        storage,
        `/vehicles/Portrait_${id}_${fileImgPortrait.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, fileImgPortrait);
      uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (err) => console.log(err),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            data.imgPortrait = url;
            await VehicleDataService.updateVehicle(id, data);
          });
        }
      );
    }

    if (uploadedImages.length > 0) {
      uploadedImages.map((image) => {
        const storageRef = ref(storage, `/vehicles/${image.name}`);
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // update progress
            setPercent(percent);
          },
          (err) => console.log(err),
          () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
              data.images.push(url);
              await VehicleDataService.updateVehicle(id, data);
            });
          }
        );
      });
    } else {
      if (images && images.length > 0) {
      } else {
        data.images = [];
        await VehicleDataService.updateVehicle(id, data);
      }
    }
    resetAndClose();
  };

  function resetAndClose() {
    props.setIsOpen(false);
    props.updateGrid();
  }

  const handleChangeMainImage = async (event) => {
    const file = event.target.files[0];
    const ext = (
      file.name ? file.name.split(".").pop() : file.path.split(".").pop()
    ).toLowerCase();
    if (ext === "heic" || ext === "heif") {
      const convertedImage = await heic2any({ blob: file });
      setImgLandscape(URL.createObjectURL(convertedImage));
      setFileMainImage(convertedImage);
    } else {
      setImgLandscape(URL.createObjectURL(file));
      setFileMainImage(file);
    }
  };
  const handleChangePortraitImage = async (event) => {
    const file = event.target.files[0];
    const ext = (
      file.name ? file.name.split(".").pop() : file.path.split(".").pop()
    ).toLowerCase();
    if (ext === "heic" || ext === "heif") {
      const convertedImage = await heic2any({ blob: file });
      setImgPortrait(URL.createObjectURL(convertedImage));
      setFileImgPortrait(convertedImage);
    } else {
      setImgPortrait(URL.createObjectURL(file));
      setFileImgPortrait(file);
    }
  };

  const handleChangeImages = async (event) => {
    const choosenFile = Array.prototype.slice.call(event.target.files);
    const uploaded = [...uploadedImages];
    const tempimages = [...images];

    await Promise.all(
      choosenFile.map(async (file) => {
        const ext = (
          file.name ? file.name.split(".").pop() : file.path.split(".").pop()
        ).toLowerCase();

        if (ext === "heic" || ext === "heif") {
          if (uploaded.findIndex((f) => f.name === file.name) === -1) {
            const convertedImage = await heic2any({ blob: file });
            tempimages.push(URL.createObjectURL(convertedImage));
            uploaded.push(convertedImage);
          }
        } else {
          if (uploaded.findIndex((f) => f.name === file.name) === -1) {
            tempimages.push(URL.createObjectURL(file));
            uploaded.push(file);
          }
        }
      })
    );

    setImages(tempimages);
    setUploadedImages(uploaded);
  };

  const getValidFobKey = async (fobkey) => {
    try {
      let config = {
        method: "get",
        url: `${Base_Url}/api/searchKeys?fobkey=${fobkey}`,
        headers: {},
      };
      const response = await axios.request(config);
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        let fobArr = response.data.data.map((item) => ({
          fob: item.serialNumber,
          keyCafeId: item.id.toString(),
          keyCafeName: item.name,
        }));
        return fobArr[0];
      } else {
        throw new Error("Invalid FOB#");
      }
    } catch (error) {
      throw error;
    }
  };

  const removeMainImage = () => {
    setImgLandscape("");
    setFileMainImage("");
  };
  const removePortraitImage = () => {
    setImgPortrait("");
    setFileImgPortrait("");
  };
  const removeImages = (index) => {
    var tempuploadedImages = [...uploadedImages];
    tempuploadedImages.splice(index, 1);
    setUploadedImages(tempuploadedImages);
    var tempimages = [...images];
    tempimages.splice(index, 1);
    setImages(tempimages);
  };

  const handleKeyCafeFobClick = () => {
    setShowFobList(true);
  };

  useEffect(() => {

    setImgLandscape("");
    setImgPortrait("");
    setFileMainImage("");
    setFileImgPortrait("");
    setUploadedImages([]);

    const fields = [
      "year",
      "make",
      "model",
      "color",
      "groupId",
      "pointsPerDay",
      "pricePerDay",
      "mileageAllowed",
      "overageMile",
      "rentalBaseCost",
      "isPartOfWarehouseRentalFleet",
      "isPartOfWarehouseSalesFleet",
      "description",
      "drivingInstructions",
      "engineDetails",
      "isStoredAtUsersHome",
      "keyCafeFob",
      "speedDetails",
      "transmissionDetails",
      "vehicalCarWashPrice",
      "additionalInsuranceCost",
      "emptyTankFee",
      "currentMileage",
      "vin",
      "warehouseBayNumber",
      "id",
      "rentalLocation",
      "owner",
      "carCost",
      "images",
      "keyCafeName",
      "createdAt"
    ];
    if (props.selectedVehicle && typeof props.selectedVehicle == "object") {
      fields.forEach((field) =>
        setValue(
          field,
          props.selectedVehicle[field]
            ? props.selectedVehicle[field]
            : InitialVehicleState[field]
        )
      );
      //setValue('date', moment(props.selectedVehicle['date']).toDate());
      setImgLandscape(props.selectedVehicle.imgLandscape);
      setImgPortrait(props.selectedVehicle?.imgPortrait);
      if (
        props.selectedVehicle.images &&
        props.selectedVehicle.images.length > 0
      ) {
        setImages(props.selectedVehicle.images);
      } else if (
        props.selectedVehicle.image &&
        props.selectedVehicle.image.length > 0
      ) {
        setImages(props.selectedVehicle.image);
      }
      getVehicleDetails();
      setValue("keyCafeKeyId", props.selectedVehicle?.keyCafeKeyId);
      setValue(
        "carCost",
        props.selectedVehicle?.carCost ? props.selectedVehicle?.carCost : 0
      );
      setShowFobList(false);

      let selectedMember = props.users.filter(
        (obj) => obj.id == props.selectedVehicle.owner
      );
      if (selectedMember[0] && selectedMember[0].firstName) {
        let str = `${selectedMember[0].firstName} ${selectedMember[0].lastName}`;
        setSeletedMemberPlaceholder(str);
      } else {
        setSeletedMemberPlaceholder("");
      }
      let selectedGroup = props.allgroups.filter(
        (obj) => obj.id == props.selectedVehicle.groupId
      );
      if (
        selectedGroup &&
        selectedGroup.length > 0 &&
        selectedGroup[0] &&
        selectedGroup[0].title
      ) {
        setSeletedGroup(selectedGroup[0]);
      } else {
        setSeletedGroup(null);
      }
      setValueDate(convertStringToDateArray(props.selectedVehicle?.balckoutdate));
    } else {
      fields.forEach((field) => setValue(field, InitialVehicleState[field]));
      setImgLandscape("");
      setImgPortrait("");
      setImages([]);
      setShowFobList(false);
      setValue("keyCafeKeyId", "");
      setSeletedMemberPlaceholder("");
      setSeletedGroup(null);
    }
  }, [props.selectedVehicle, setValue]);

  async function getVehicleDetails() {
    if (
      props.selectedVehicle &&
      typeof props.selectedVehicle == "object" &&
      props.selectedVehicle.id
    ) {
      try {
        const vehicledoc = await VehicleDataService.getVehicle(
          props.selectedVehicle.id
        );
        let vehicleObj = vehicledoc.data();
        if (vehicleObj && vehicleObj.make) {
          setImgLandscape(vehicleObj.imgLandscape);
          setImgPortrait(vehicleObj.imgPortrait);
          if (vehicleObj.images && vehicleObj.images.length > 0) {
            setImages(vehicleObj.images);
          } else if (vehicleObj.image && vehicleObj.image.length > 0) {
            setImages(vehicleObj.image);
          } else {
            setImages([]);
          }
        }
      } catch (error) {
        trackException(JSON.stringify(error))
        //console.log("error->", error)
      }
    }
  }
  return (
    <DrawerOverlay
      size={"medium"}
      position={"right"}
      open={props.isOpen}
      style={{ width: "504px" }}
      onOpenChange={(_, { open }) => props.setIsOpen(open)}
    >
      <DrawerHeader style={{
        background: "#F7F7F7",
        padding: "26px 32px"
      }}>
        <DrawerHeaderTitle
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Dismiss24Regular />}
              onClick={() => props.setIsOpen(false)}
            />
          }
        >
          + {props.selectedVehicle && typeof props.selectedVehicle == "object"
            ? "Edit"
            : "New"}{" "}
          Vehicle
        </DrawerHeaderTitle>
      </DrawerHeader>
      <DrawerBody>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={styles.root}
          onReset={reset}
          id={
            props.selectedVehicle && typeof props.selectedVehicle == "object"
              ? props.selectedVehicle.id
              : "New"
          }
        >

          <div className={"d-flex justify-space-between"} style={{ marginTop: "32px" }}>
            <div style={{ display: "grid", width: "49%" }}>
              <Label className={"side-drawer-field__label"}>Year*</Label>
              <Controller
                name="year"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <Input type="number"

                  placeholder={"Enter Year"}
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
                  {...field} />}
              />
            </div>
            <div style={{ display: "grid", width: "49%" }}>
              <Label className={"side-drawer-field__label"}>Make*</Label>
              <Controller
                name="make"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <input placeholder={"Enter Make"}
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
              />
            </div>
          </div>
          <div className={"d-flex justify-space-between"}>
            <div style={{ display: "grid", width: "49%" }}>
              <Label className={"side-drawer-field__label"}>Model*</Label>
              <Controller
                name="model"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <input placeholder={"Enter Model"}
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
              />
            </div>
            <div style={{ display: "grid", width: "49%" }}>
              <Label className={"side-drawer-field__label"}>Color*</Label>
              <Controller
                name="color"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <input placeholder={"Enter Color"}
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
              />
            </div>
          </div>

          <Label className={"side-drawer-field__label"}>Rental Fleet</Label>
          <Controller
            name="isPartOfWarehouseRentalFleet"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <div onClick={() => field.onChange(!field.value)}>
                <Switch checked={field.value} {...field} />
              </div>
            )}
          />
          <Label className={"side-drawer-field__label"}>Sales Fleet</Label>
          <Controller
            name="isPartOfWarehouseSalesFleet"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <div onClick={() => field.onChange(!field.value)}>
                <Switch checked={field.value} {...field} />
              </div>
            )}
          />
          <Label className={"side-drawer-field__label"}>Stored at Members Home</Label>
          <Controller
            name="isStoredAtUsersHome"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <div onClick={() => field.onChange(!field.value)}>
                <Switch checked={field.value} {...field} />
              </div>
            )}
          />
          <Label className={"side-drawer-field__label"}>Member</Label>
          <Controller
            name="owner"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Dropdown
                multiselect={false}
                placeholder={!!selectedMemberPlaceholder ? selectedMemberPlaceholder : "Select Member"}
                className={"side-drawer-field-dropdown  side-drawer-field__gap side-drawer-field__gap"}


                onOptionSelect={(ev, data) =>
                  field.onChange(data.selectedOptions[0])
                }
                selectedOptions={[field.value]}

              >
                {props.users.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {`${option.firstName} ${option.lastName}`}
                  </Option>
                ))}
              </Dropdown>
            )}
          />
          <Label></Label>
          <Label className={"side-drawer-field__label"}>Group</Label>
          <Controller
            name="groupId"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Dropdown
                multiselect={false}

                className={"side-drawer-field-dropdown  side-drawer-field__gap side-drawer-field__gap"}
                onOptionSelect={(ev, data) => {
                  field.onChange(data.selectedOptions[0]);
                  let selectedGroup = props.allgroups.filter(
                    (obj) => obj.id == data.selectedOptions[0]
                  );
                  setSeletedGroup(selectedGroup[0]);
                }}
                selectedOptions={[field.value]}
                placeholder={seletedGroup ? seletedGroup.title : "Enter Group"}
              >
                {props.allgroups.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.title}
                  </Option>
                ))}
              </Dropdown>
            )}
          />
          {seletedGroup && seletedGroup.id != "NA" ? (
            <div>
              <Label>
                Points Per Day: {seletedGroup.pointsPerDay} | Daily Mileage:{" "}
                {seletedGroup.mileageAllowed} | Overage Mile: $
                {seletedGroup.overageMile} | Price Per Day: $
                {seletedGroup.pricePerDay}
              </Label>
            </div>
          ) : (
            <div></div>
          )}
          {seletedGroup && seletedGroup.id == "NA" ? (
            <>
              <Label className={"side-drawer-field__label"}>Points Per Day</Label>
              <Controller
                name="pointsPerDay"
                control={control}
                rules={{ required: false }}
                render={({ field }) => <input placeholder={"Enter Email"}
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
              />
              <Label className={"side-drawer-field__label"}>Price Per Day: $</Label>
              <Controller
                name="pricePerDay"
                control={control}
                rules={{ required: false }}
                render={({ field }) => <input placeholder={"Enter Email"}
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
              />
              <Label className={"side-drawer-field__label"}>Mileage Allowed</Label>
              <Controller
                name="mileageAllowed"
                control={control}
                rules={{ required: false }}
                render={({ field }) => <input placeholder={"Enter Email"}
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
              />
              <Label className={"side-drawer-field__label"}>Overage Mile: $</Label>
              <Controller
                name="overageMile"
                control={control}
                rules={{ required: false }}
                render={({ field }) => <input placeholder={"Enter Email"}
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
              />
            </>
          ) : (
            <div></div>
          )}
          <Label className={"side-drawer-field__label"}>Cost Per Day (Credits)</Label>
          <Controller
            name="rentalBaseCost"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <input placeholder={"Enter Cost Per Day"}
              className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
          />
          <Label className={"side-drawer-field__label"}>Cost of the car</Label>
          <Controller
            name="carCost"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <input placeholder={"Enter Cost of the car"}
              className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
          />

          <Label className={"side-drawer-field__label"}>Wash Price </Label>
          <Controller
            name="vehicalCarWashPrice"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <input placeholder={"Enter Wash Price"} type="number"
              onWheel={(e) => e.target.blur()}
              className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
          />

          <Label className={"side-drawer-field__label"}>Empty tank fee </Label>
          <Controller
            name="emptyTankFee"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <input placeholder={"Empty tank fee"} type="number"
              onWheel={(e) => e.target.blur()}
              className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
          />

          <Label className={"side-drawer-field__label"}>Additional insurance cost</Label>
          <Controller
            name="additionalInsuranceCost"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <input placeholder={"Additional insurance cost"} type="number"
              onWheel={(e) => e.target.blur()}
              className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
          />


          <Label className={"side-drawer-field__label"}>Current Mileage</Label>
          <Controller
            name="currentMileage"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <input placeholder={"Enter Current Mileage"}
              className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} />}
          />

          <Label className={"side-drawer-field__label"}>Rental Location</Label>
          <Controller
            name="rentalLocation"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <Dropdown
                multiselect={false}
                onOptionSelect={(ev, data) =>
                  field.onChange(data.selectedOptions[0])
                }
                placeholder={"Enter Rental Location"}
                className={"side-drawer-field-dropdown  side-drawer-field__gap side-drawer-field__gap"}
                selectedOptions={[field.value]}
              >
                {props.locations.map((option) => (
                  <Option key={option.id} value={option.id}>
                    {option.location}
                  </Option>
                ))}
              </Dropdown>
            )}
          />
          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}>Description*</Label>
          <Controller
            name="description"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <Textarea
              placeholder={"Enter Description"}
              className={"side-drawer-field-Textarea  side-drawer-field__gap side-drawer-field__gap"}

              {...field} resize="vertical" />}
          />
          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8, marginTop: 24 }}>Driving Instructions</Label>
          <Controller
            name="drivingInstructions"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <Textarea placeholder={"Enter Driving Instructions"}
              className={"side-drawer-field-Textarea  side-drawer-field__gap side-drawer-field__gap"}{...field}
              resize="vertical" />}
          />
          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8, marginTop: 24 }}>Engine Details</Label>
          <Controller
            name="engineDetails"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <Textarea placeholder={"Enter Engine Details"}
              className={"side-drawer-field-Textarea  side-drawer-field__gap side-drawer-field__gap"} {...field}
              resize="vertical" />}
          />
          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8, marginTop: 24 }}>Speed Details</Label>
          <Controller
            name="speedDetails"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <Textarea placeholder={"Enter Speed Details"}
              className={"side-drawer-field-Textarea  side-drawer-field__gap side-drawer-field__gap"}{...field}
              resize="vertical" />}
          />
          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8, marginTop: 24 }}> Transmission Details</Label>
          <Controller
            name="transmissionDetails"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <Textarea placeholder={"Enter Transmission Details"}
              className={"side-drawer-field-Textarea  side-drawer-field__gap side-drawer-field__gap"}{...field}
              resize="vertical" />}
          />
          {/* <Label>
                        Key Cafe Fob #
                    </Label>
                    <Controller
                        name="keyCafeFob"
                        control={control}
                        rules={{ required: false }}
                        render={({ field }) => <input  placeholder={"Enter Email"}
                            className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field} /> }
                    /> */}

          <Label className={"side-drawer-field__label"} style={{ marginTop: 24 }}>Key Cafe Fob #</Label>
          {!isShowFobList && (
            <Controller
              name="keyCafeFob"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Input placeholder={"Enter Key Cafe Fob "}
                  className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"} {...field}
                  onClick={handleKeyCafeFobClick} />
              )}
            />
          )}

          {isShowFobList && (
            <Controller
              name="keyCafeKeyId"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Dropdown
                  multiselect={false}
                  defaultOpen={true}
                  onOptionSelect={(ev, data) =>
                    field.onChange(data.selectedOptions[0])
                  }
                  selectedOptions={[field.value]}
                >
                  {props.fobKeys.map((option) => (
                    <Option key={option.keyCafeId} value={option.keyCafeId}>
                      {option.fob}
                    </Option>
                  ))}
                </Dropdown>
              )}
            />
          )}

          <Label className={"side-drawer-field__label"} style={{ marginBottom: 8 }}>VIN</Label>
          <Controller
            name="vin"
            control={control}
            rules={{ required: false }}
            render={({ field }) => <Textarea placeholder={"Enter VIN"}
              className={"side-drawer-field-Textarea  side-drawer-field__gap side-drawer-field__gap"} {...field}
              resize="vertical" />}
          />
          <Label className={"side-drawer-field__label"} style={{ marginTop: 24, marginBottom: "8px" }}>Warehouse Bay Number*</Label>
          <Controller
            name="warehouseBayNumber"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <Input placeholder={"Enter Warehouse Bay Numbe"}
              className={"side-drawer-field  side-drawer-field__gap side-drawer-field__gap"}
              type="number" {...field} />}
          />
          <Label className={"side-drawer-field__label"} style={{ marginTop: 24, marginBottom: "8px" }}>BlackOut Days</Label>
          <DatePicker
            multiple
            initialSelectedDates={valueDate}
            value={valueDate}
            onChange={setValueDate}
            style={{
              width: '90%',
              display: 'flex',
              padding: '12px 18px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              alignSelf: 'stretch',
              borderRadius: '34px',
              border: '1px solid #C1C1C1'
            }}
          />

          <Label className={"side-drawer-field__label"} style={{ marginTop: 24, marginBottom: "8px" }}>Image Landscape</Label>
          {imgLandscape && (
            <div>
              <Image
                width={100}
                height={70}
                src={imgLandscape}
                alt="Main Image Landscape"
              ></Image>
              <Button icon={<DeleteIcon />} onClick={removeMainImage} />
            </div>
          )}
          {!imgLandscape && (
            <label className="fileCtrl">
              <input
                type="file"
                id="file1"
                onChange={handleChangeMainImage}
                accept="image/*, .heic"
                className="inputFileCtrl"
                aria-label="File browser example"
                placeholder="Browse"
              />
            </label>
          )}

          <Label className={"side-drawer-field__label"} style={{ marginTop: 24, marginBottom: "8px" }}>Image Portrait</Label>
          {imgPortrait && (
            <div>
              <Image
                width={70}
                height={100}
                src={imgPortrait}
                alt="Main Image Portrait"
              ></Image>
              <Button icon={<DeleteIcon />} onClick={removePortraitImage} />
            </div>
          )}
          {!imgPortrait && (
            <label className="fileCtrl">
              <input
                type="file"
                id="file2"
                onChange={handleChangePortraitImage}
                accept="image/*, .heic"
                className="inputFileCtrl"
                aria-label="File browser example"
                placeholder="Browse"
              />
            </label>
          )}

          <Label className={"side-drawer-field__label"} style={{ marginTop: 24, marginBottom: "8px" }}>Other Images</Label>
          <div>
            {images &&
              images.length > 0 &&
              images.map((img, index) => (
                <>
                  <Image
                    width={100}
                    height={100}
                    src={img}
                    alt="Main Image"
                  ></Image>
                  <Button
                    icon={<DeleteIcon />}
                    onClick={() => removeImages(index)}
                  />
                </>
              ))}
          </div>
          {
            <label className="fileCtrl">
              <input
                type="file"
                id="file3"
                onChange={handleChangeImages}
                accept="image/*, .heic"
                className="inputFileCtrl"
                aria-label="File browser example"
                multiple
              />
            </label>
          }
          <div className="btnActions">
            {
              isLoading ?
                <Spinner label="Loading..." /> :
                <button
                  appearance="primary"
                  type="submit"
                  className="form-submit-btn"
                >
                  {props.selectedVehicle && typeof props.selectedVehicle == "object"
                    ? "Update"
                    : "Submit"}
                </button>
            }
            <button
              className="form-submit-cancel"
              onClick={() => props.setIsOpen(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </DrawerBody>
    </DrawerOverlay>
  );
}

export default VehicleForm;
function convertStringToDateArray(dateString) {
  try {
    return dateString.split(",").map(date => date.trim());
  } catch (error) {
    return []; // Return an empty array on error
  }
}